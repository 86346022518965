import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { parseGraphqlErrors } from '../../common/functions';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import Master from '../../containers/Layout/Master';
import CaseDetails from '../Case/CaseDetails';
import { GET_CASE_DETAILS } from '../Case/query';
import CaseForm from './CaseForm';

function CaseFetch(props) {
  const { type, match } = props;
  const { case_slug } = match.params;
  const query = GET_CASE_DETAILS;
  const variables = { slug: case_slug };

  const { setFeedback } = useContext(FeedbackContext);
  const history = useHistory();

  const { loading, error, data } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
        history.push('/cases');
      }
    },
  });

  if (loading || error || isEmpty(data)) {
    if (error) {
      console.log('ERROR : ', parseGraphqlErrors(error));
      return error.message;
    }
    return 'Loading';
  }

  const { caseData } = data;
  if (caseData.inquiryType && caseData.inquiryType.name === undefined) {
    caseData.inquiryType = { name: caseData.inquiryType };
  }
  if (
    caseData.visibilityStatus &&
    caseData.visibilityStatus.name === undefined
  ) {
    caseData.visibilityStatus = { name: caseData.visibilityStatus };
  }
  if (caseData.race && caseData.race.name === undefined) {
    caseData.race = { name: caseData.race };
  }
  if (caseData.patientGender && caseData.patientGender.name === undefined) {
    caseData.patientGender = { name: caseData.patientGender };
  }
  if (type === 'edit' && caseData.originalCaseId && caseData.attachments) {
    caseData.attachments = caseData.attachments.filter(
      (e) => e.caseSubmitionType === 'SHARING',
    );
  }
  if (type === 'edit' && caseData.originalCaseId && caseData.links) {
    caseData.links = caseData.links.filter(
      (e) => e.caseSubmitionType === 'SHARING',
    );
  }
  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault(); // Prevent form submission or TextInput value change
      console.log('Enter key is disabled in this input!');
    }
  };
  return type === 'edit' ? (
    <CaseForm handleKeyPress={handleKeyPress} {...props} caseData={caseData} />
  ) : (
    <Master className="case-wrapper" {...props}>
      <CaseDetails {...props} data={caseData} />
    </Master>
  );
}

export default CaseFetch;
