import React from 'react';
import JournalFetch from '../../components/JournalForm/JournalFetch';
import Master from '../Layout/Master';

function JournalView(props) {
  return (
    <Master className="case-wrapper" {...props}>
      <JournalFetch {...props} />
    </Master>
  );
}

export default JournalView;
