import React, { useContext } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import { OrganizationContext } from '../../containers/Context/OrganizationContext';
import HsImage from './HsImage';

const HeaderNoAuth = (props) => {
  const { header: headerString } = strings;
  const { organization: { dark_logo } = {}, setOrganization } =
    useContext(OrganizationContext);
  const { view } = props;
  return (
    <>
      <div className="header-no-auth">
        <Navbar
          collapseOnSelect
          expand="sm"
          bg="white"
          variant="secondary"
          className="navbar-header"
        >
          <Container>
            <Navbar.Brand as={Link} to={'/'} href="/">
              <HsImage
                className="header-logo"
                src={'/api/static/cavi-logo.png' || dark_logo}
              />
            </Navbar.Brand>
            {view === 'tnc' || view === 'privacy' ? (
              <></>
            ) : (
              <>
                <Nav className="ml-auto">
                  <Nav.Item className="header-no-auth-nav-item muted-text">
                    <Link
                      className="mx-2"
                      to={'#'}
                      onClick={(e) => {
                        window.open('mailto:cavi@iqvia.com');
                        // window.location.href = 'mailto:cavi@iqvia.com';
                        e.preventDefault();
                      }}
                      style={{
                        textDecoration: 'underline',
                      }}
                    >
                      {headerString.contact_us}
                    </Link>
                  </Nav.Item>

                  <div className="ml-2 mr-2"></div>
                  {props.view !== 'signUp' && props.view !== 'resetPassword' ? (
                    <Nav.Item
                      style={{
                        cursor: 'default',
                      }}
                      className="header-no-auth-nav-item muted-text"
                    >
                      <span className="text-muted mr-1 d-none d-sm-inline">
                        {headerString.dont_have_account}
                      </span>
                      <Link
                        style={{
                          textDecoration: 'underline',
                        }}
                        to={'/sign-up'}
                      >
                        {headerString.signup_now}
                      </Link>
                    </Nav.Item>
                  ) : (
                    <Nav.Item className="header-no-auth-nav-item muted-text">
                      <span
                        style={{
                          cursor: 'default',
                        }}
                        className="text-muted mr-1 d-none d-sm-inline"
                      >
                        {headerString.already_have_account}
                      </span>
                      <Link
                        style={{
                          textDecoration: 'underline',
                        }}
                        to={'/signin'}
                      >
                        {headerString.signin_now}
                      </Link>
                    </Nav.Item>
                  )}
                </Nav>
              </>
            )}
            {/* <Nav className="ml-auto">
              {availableLanguages.length > 1 ? (
                <NavDropdown
                  alignRight
                  title={
                    <div className="nav-text-icon">
                      <label className="icon-ico-globe" />
                      <span className="langTitle">{strings._language}</span>
                    </div>
                  }
                  id="nav-locale"
                  onMouseEnter={openLocaleDropdown}
                  onMouseLeave={closeLocaleDropdown}
                  show={isLocaleOpen}
                >
                  <Nav.Item>
                    {availableLanguages.map((lang) => {
                      return (
                        <NavDropdown.Item key={lang} className={lang === ctx.locale ? "active" : ""} onClick={() => ctx.setLanuage(lang)}>
                          {lang}
                        </NavDropdown.Item>
                      );
                    })}
                  </Nav.Item>
                </NavDropdown>
              ) : (
                ""
              )}

              {props.type !== "landing" ? (
                props.view !== "signup" && props.view !== "resetPassword" ? (
                  <Nav.Item className="signup-link">
                    <span className="text-muted mr-1 d-none d-sm-inline">{headerString.dont_have_account}</span>
                    <Link to={"/signup"}>{headerString.signup_now}</Link>
                  </Nav.Item>
                ) : (
                  <Nav.Item className="signup-link">
                    <span className="text-muted mr-1 d-none d-sm-inline">{headerString.already_account}</span>
                    <Link to={"/signin"}>{headerString.signin_now}</Link>
                  </Nav.Item>
                )
              ) : (
                ""
              )}
            </Nav> */}
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default HeaderNoAuth;
