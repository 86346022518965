import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { strings } from '../../assets/strings/all';
import { useScroll } from '../../containers/Helper/useScroll';
import HsLoader from '../Common/HsLoader';
import NoData from '../Common/NoData';
import NotificationItem from './NotificationItem';
import {
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  NEW_NOTIFICATION,
} from './query';
import Button from 'react-bootstrap/Button';
import { UserContext } from '../../containers/Context/UserContext';
import { useEffect } from 'react';

export default function NotificationList() {
  const { user } = useContext(UserContext);
  const [loadingData, setLoadingData] = useState(false);
  const { loading, error, data, fetchMore, subscribeToMore } = useQuery(
    GET_NOTIFICATIONS,
    {
      fetchPolicy: 'network-only',
      variables: { limit: 40, offset: 0 },
    },
  );

  useEffect(() => {
    const subscribeToNewNotifications = () =>
      subscribeToMore({
        document: NEW_NOTIFICATION,
        variables: { uuid: user.uuid },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newNotification =
            subscriptionData.data.notification.notification;
          const newNotificationList = Object.assign({}, prev, {
            notificationList: {
              ...prev.notificationList,
              notifications: [
                newNotification,
                ...prev.notificationList.notifications,
              ],
            },
          });

          return newNotificationList;
        },
      });

    subscribeToNewNotifications();
  }, [subscribeToMore, user]);

  const [markAllNotificationsRead] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_READ,
    {
      onCompleted: (data) => {},
    },
  );

  const listRef = useRef();

  const scrollHeight = useScroll();
  const viewportHeight =
    listRef && listRef.current
      ? listRef.current.getBoundingClientRect().height +
        listRef.current.getBoundingClientRect().top -
        scrollHeight.scrollY
      : 0;

  if (loading || error || isEmpty(data)) {
    return 'Loading';
  }

  const { notificationList } = data;
  const { notifications, hasMore } = notificationList;

  const { notification: notificationString } = strings;

  const fetchMoreNotifications = (limit, offset) => {
    if (loadingData) return false;
    setLoadingData(true);
    fetchMore({
      variables: { limit, offset },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        let notificationList = {
          ...fetchMoreResult,
          notificationList: {
            ...fetchMoreResult.notificationList,
            notifications: [
              ...prev.notificationList.notifications,
              ...fetchMoreResult.notificationList.notifications,
            ],
          },
        };

        setLoadingData(false);
        return notificationList;
      },
    });
  };

  if (viewportHeight < 400 && viewportHeight > 0 && !loadingData && hasMore) {
    fetchMoreNotifications(10, notifications.length);
  }

  return (
    <>
      <Row className="notification-list" ref={listRef}>
        <Col sm={12} lg={9}>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Card.Title as="h6">
                    {notificationString.notifications}
                  </Card.Title>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    onClick={() => markAllNotificationsRead()}
                  >
                    Mark all as read
                  </Button>
                </Col>
              </Row>

              <div>
                {notifications.map((notificationData) => {
                  return (
                    <>
                      <NotificationItem notificationData={notificationData} />
                    </>
                  );
                })}
              </div>
              {loadingData && <HsLoader />}
              {notifications.length === 0 ? (
                <div>
                  <NoData
                    type="notification"
                    message={notificationString.no_data}
                  />
                </div>
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
