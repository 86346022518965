import gql from "graphql-tag";

export const PANEL_LIST = gql`
  fragment PanelListResponse on Panel {
    id
    name
    organizationId
    PanelMember {
      uuid
      fullName
      email
      username
      avatar
      speciality {
        name
      }
      about
      association
    }
  }
`;

export const GET_PANEL_USERS = gql`
  query ($panelId: Int) {
    panelData: getPanelUsers(panelId: $panelId) {
      hasMore
      totalCount
      panels {
        ...PanelListResponse
      }
    }
  }
  ${PANEL_LIST}
`;
