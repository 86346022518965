import React, { Component } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import { setTitle } from '../../common/functions';
function capFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
class HsBreadcrumb extends Component {
  render() {
    const { match, breadcrumb } = this.props;

    const match_arr = match.path.split('/');
    const match_url = match.url.split('/');
    let previousUrl = match.url;

    const showBreadcrumbLength =
      Object.keys(match.params).length > 0 || breadcrumb;

    previousUrl = previousUrl.endsWith('/')
      ? previousUrl.slice(0, -1)
      : previousUrl;

    previousUrl = previousUrl.substr(0, previousUrl.lastIndexOf('/'));

    const { header } = strings;
    setTitle(
      header[match_arr[match_arr.length - 1]] ||
        match_arr[match_arr.length - 1],
    );
    let redirectValue = '';

    return (
      <>
        {showBreadcrumbLength ? (
          <Navbar
            bg="dark"
            variant="light"
            className="breadcrumb-navbar breadcrumb-navbar123"
          >
            <Link
              to={previousUrl}
              href={previousUrl}
              className="nav-link text-white"
            >
              <label className="icon-ico-back" />
            </Link>
            <Nav className="mr-auto">
              <Nav.Item>
                <div className="nav-link">
                  {capFirst(
                    header[match_arr[match_arr.length - 1]] ||
                      match_arr[match_arr.length - 1],
                  )}
                </div>
              </Nav.Item>
            </Nav>
          </Navbar>
        ) : (
          <></>
        )}
        {breadcrumb ? (
          match_arr.length > 1 ? (
            <Breadcrumb>
              {match_arr.map((match, index) => {
                if (match) {
                  redirectValue += `/${match_url[index]}`;
                }
                return match === '' ? (
                  <li className="breadcrumb-item" key={index}>
                    <Link to={`/`}>{header.home}</Link>
                  </li>
                ) : (
                  <li
                    key={index}
                    className={`breadcrumb-item ${
                      index === match_arr.length - 1 ? 'active' : ''
                    }`}
                  >
                    <Link to={redirectValue}>
                      {capFirst(header[match] || match)}
                    </Link>
                  </li>
                );
              })}
            </Breadcrumb>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default HsBreadcrumb;
