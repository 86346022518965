import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';
import { strings } from '../../assets/strings/all';

function CaseFormBottomAction(props) {
  const {
    saveOrPublishCase,
    caseData: {
      // isModerationEnabled = false,
      slug,
      caseStatus = { slug: '' },
    },
    errors,
    loading,
  } = props;
  const { cases: caseString } = strings;

  const [isDisabled, setIsDisabled] = useState(!isEmpty(errors) || loading);
  // const [state, setState] = useState({
  //   open: false,
  //   type: '',
  // });
  useEffect(() => {
    setIsDisabled(!isEmpty(errors) || loading);
  }, [errors, loading]);

  // const closeDisclaimer = () => {
  //   setState({
  //     open: false,
  //     type: '',
  //   });
  // };

  // const openDisclaimer = (type) => {
  //   setState({
  //     open: true,
  //     type: type,
  //   });
  // };

  return (
    <>
      <div className="case-fixed-footer">
        <div className="case-fixed-footer-wrapper">
          <Button
            variant="primary"
            as={Link}
            to={slug ? `/cases/${slug}` : `/cases`}
            className="back-btn no-br"
          >
            <span className="icon-ico-back" />
          </Button>
          <div className="case-fixed-footer-inner">
            <Row>
              <Col>
                <Row className="justify-end">
                  <Col xs="auto">
                    <Button
                      variant="outline-primary"
                      disabled={isDisabled}
                      onClick={() =>
                        saveOrPublishCase(
                          caseStatus.slug === 'returned'
                            ? 'screening'
                            : 'draft',
                        )
                      }
                    >
                      {caseStatus.slug === 'returned'
                        ? 'Submit for review'
                        : caseString.save_as_draft}
                      {}
                    </Button>
                  </Col>
                  {/* {isModerationEnabled ? (
                    <Col xs="auto">
                      <Button
                        disabled={isDisabled}
                        variant="primary"
                        onClick={() => openDisclaimer('in-review')}
                      >
                        {caseString.request_to_publish}
                      </Button>
                    </Col>
                  ) : (
                    <Col xs="auto">
                      <Button
                        disabled={isDisabled}
                        variant="primary"
                        onClick={() => openDisclaimer('publish')}
                      >
                        {caseString.submit}
                      </Button>
                    </Col>
                  )} */}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* <CaseDisclaimerModal
        open={state.open}
        type={state.type}
        close={closeDisclaimer}
        saveOrPublishCase={saveOrPublishCase}
      /> */}
    </>
  );
}

export default CaseFormBottomAction;
