export const strings = {
  signin_page_title: 'Sign In',
  signup_page_title: 'Sign Up',
  forgot_password_page_title: 'Forgot Password',

  signin: 'Sign In',
  signingin: 'Signing In',
  forgot_password_link: 'Forgot Password?',
  password: 'Password',
  username: 'Email/Username',
  enter_password: 'Enter your password',
  enter_username: 'Enter Email/Username',

  send_link: 'Send Link',
  back_to_signin: 'Back to Sign in',
  sending_link: 'Sending Link',
  enter_email: 'Enter Email',
  email: 'Email',
  will_send_recovery:
    'We will send a recovery email with a link to reset your password.',

  reset_password: 'Reset Password',
  set_password: 'Set Password',
  join_group: 'Join Group',
  new_password: 'New Password',
  new_password_placeholder: 'Enter New Password',
  retype_new_password: 'Re-type New Password',
  cancel: 'Cancel',
  save: 'Save',
  saving: 'Saving',

  signup: 'Sign Up',
  signup_help_text:
    'Register with your practicing license and get a verified account with complete access.',
  signingup: 'Signin Up',
  email_address: 'Email Address',
  email_address_placeholder: 'Enter email address',
  country: 'Country',
  country_placeholder: 'Select Country',
  phone_number_optional: 'Phone Number (Optional)',
  phone_number_optional_placeholder: 'Enter Number',
  profession: 'Profession',
  speciality: 'Speciality',
  speciality_placeholder: 'Choose Speciality',
  license_no: 'License Id',
  license_no_placeholder: 'e.g. DK7654321IN',
  email_placeholder: 'e.g. johnsmith@email.com',
  license_files: 'License Document',
  license_files_placeholder: 'Upload an attachment',
  password_placeholder: 'Enter more than 8 characters',
  phone_number: 'Phone Number',
  phone_number_placeholder: 'Enter Phone Number',
  password_help_text:
    'Use 8 or more characters with a mix of letters, numbers & symbols',
  iqvia_hcp_space: 'IQVIA HCP Space',
  iqvia_hcp_space_subtext: 'The fastest growing network for healthcare',
  free_register: 'Registration is free. Sign up today',
  connect_with_peers: 'Connect with peers and share updates',
  webinar_with_kol: 'Webinar conducted by KOLs',
  sign_up_cme: 'Sign up for CMEs',
  share_update: 'Discuss medical cases in a secure environment',
  confirm_password: 'Confirm Password',
  errors: {
    first_name_required: 'First name is required',
    last_name_required: 'Last name is required',
    designation_required: 'Designation is required',
    about_required: 'About me is required',
    interests_required: 'You need to select at least one interest',
    username_required: 'Username/Email is required',
    email_required: 'Email is required',
    password_required: 'Password is required',
    incorrect_user: 'Email format is not correct',
    password_mismatch: 'Both password should be same',
    password_length: 'Password should have more than 8 characters',
    license_no_required: 'Licence ID is required',
    license_files_required: 'License File is required',
    country_id_required: 'Country is required',
    tnc_required: 'You should accept Terms and Conditions',
    phone_required: 'Phone number is required',
    verification_document_required: 'License document is required',
    profession_id_required: 'Profession is required',
    speciality_id_required: 'Speciality is required',
    phone_code_required: 'Phone code is required',
    title_required: 'Title is required',
  },
  connect_people: 'Connect with People',
  build_network: 'Build Your Network',
  join_groups: 'Join Groups',
  next: 'Next',
  finish: 'Finish',
  skip: 'Skip',
  back: 'Back',
  personal: 'Personal',
  work: 'Work',
  optional: 'Optional',
  license: 'License',
  profile_details: 'Profile Details',
  setup_profile: 'Setup Your Profile',
  tnc: 'Terms and Conditions',
  i_agree: 'I agree to the',
  retype_your_password: 'Re-enter your new password',
  enter_password_more_than: 'Enter more than 8 characters',
  set_new_password: 'Set new password',
  phone_tooltip: 'Should be positive Integer',
  enter_phone: 'Enter number',
  password_lowercase_auth:
    'Your password must contain at least one lowercase letter',
  password_uppercase_auth:
    'Your password must contain at least one uppercase letter',
  password_number_auth: 'Your password must contain at least one digit',
  password_character_auth: 'Your password must have one of *,@,_,#',
  password_limit_auth: 'Your password must be at least 8 characters',
  password_success_status: 'Password has been changed successfully',
  current_password: 'Current Password',
  current_password_place: 'Enter current password',
  primary: 'Primary',
  secondary_email: 'Secondary email address',
  send_code: 'Send Verification Code',
  selected: 'Selected',
  help_provide_great_experience: 'Help us provide you great experience',
  provide_work_details:
    'Provide your work details so that we can connect you to the right peers',
  enrich_your_experience:
    'Enrich your experience on HCP Space by connecting with peers',
  become_part_specialised_group:
    'Become part of specialised groups to discuss and enhance your knowledge',
  ots_title: 'One Time Sign In',
  ots_label:
    'We’ll email you a one-time sign in link for a password-free sign in. If you don’t find the email in your inbox, please check your spam or junk folder.',
  ots_success:
    'We have sent an email to {email} containing a link which you can use to sign in to CAVI',
  one_time_sign_in_link: 'Send me a one-time sign in link',
  sign_in_using_pwd: 'Sign in with password instead',
  sign_in_using_otl: 'Sign In Using One-Time Sign In Link',
  have_sent_recovery:
    "We have sent an email containing a link to the provided address {email}, If you have an account with this email you'll receive an email with a link to reset your password. Click on the link in the email to reset your password",
  password_reset_success:
    'Your password is changed successfully. Use this new password to sign in to your account',
  update_password: 'Update Password',
  updating: 'updating',
  creating_account: 'Processing',
  verify_email: 'Email Verified',
  verify_email_success:
    'You have successfully verified your email, click on the button below to go to the main page',
  back_to_home: 'Back to home',
  sign_up_success: 'Sign Up Successful',
  check_email_post_signup:
    'Please check your email and follow the instructions provided to activate your access to CAVi. If you do not see the email in a few minutes, please check your “junk mail” folder or “spam” folder',
  terms_condition_signup: 'I agree to the {tnc} and {privacy_policy}',
  privacy_policy: 'Privacy Policy',
  close: 'Close',
  email_verification_sent:
    'A verification link has been sent to your email {email}',
  email_verification_sent_2:
    'Please verify your email, If you have not received the email',
  click_here: 'Click Here',
  send_mail: 'send the mail',
  session_title: 'Session about to end',
  session_body:
    'You have been inactive for some time, your current session is about to end you will be logged out automatically',
};
