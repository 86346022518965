import React from 'react';
import Master from '../Layout/Master';
import JournalForm from '../../components/JournalForm/JournalForm';

function CreateJournal(props) {
  return (
    <Master className="journal-wrapper" {...props}>
      <JournalForm {...props} caseData={{ attachments: [] }} />
    </Master>
  );
}

export default CreateJournal;
