import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Spinner,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CommentReportModal } from '../../components/CaseForm/components/CommentReportModal';
import {
  GET_APP_COMMENTS,
  SCREEN_COMMENT,
  DOWNLOAD_COMMENT_ANALYTICS,
} from '../../components/Comment/query';
import { strings } from '../../assets/strings/all';
import CustomDateTime from '../../components/Common/CustomDateTime';
import { formatToCustomUTCString } from '../../common/functions';

const CommentView = (props) => {
  const { id, content, isScreened, User, caseCountry } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(isScreened);
  const [screenComment] = useMutation(SCREEN_COMMENT);
  const screenMutation = async () => {
    const value = await screenComment({
      variables: {
        commentId: id,
        hasBeenScreened: !active,
      },
    });
    try {
      if (value.data.screenComment.success) {
        setActive(!active);
      }
    } catch (e) {
      alert('Something went wrong');
    }
  };
  return (
    <>
      <Card>
        <Card.Body>
          <Row className="my-2 py-2 px-3" md={{ span: 12 }}>
            <Col
              style={{
                flex: 4,
              }}
            >
              <Col
                style={{
                  cursor: 'pointer',
                }}
                sm={8}
                onClick={() => {
                  history.push('/cases/' + props.originalCase.slug);
                }}
              >
                {content}
              </Col>
              <Col
                className="mt-2"
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                }}
              >
                {`Commenter: ${User.firstName} ${User.lastName}, Country:[${caseCountry?.name}], Case: ${props.originalCase.slug}, Comment Id: ${props.id} `}
              </Col>
            </Col>
            <Col style={{ display: 'flex', gap: '25px' }}>
              <span
                style={{
                  fontSize: '12px',
                }}
              >
                Mark as screened
              </span>
              <input
                type="checkbox"
                checked={active}
                onChange={screenMutation}
              />
              <Button
                variant="outline-danger"
                onClick={(v) => {
                  setOpen(true);
                }}
              >
                Report
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <CommentReportModal
        open={open}
        id={id}
        onFinish={(v) => {
          if (v === true) {
            setActive(true);
          }
          setOpen(false);
        }}
      />
    </>
  );
};

const ViewCommentsChunk = (props) => {
  const { skip, onLoad, pageSize } = props;
  const { data, loading } = useQuery(GET_APP_COMMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: skip * pageSize,
      pageSize,
      isScreened: props.isScreened,
    },
  });
  useEffect(() => {
    try {
      if (data.getAppComments.comments.length === 0) {
        onLoad(true);
      } else {
        onLoad(false);
      }
    } catch (err) {
      onLoad(loading);
    }
  }, [loading, data]);
  useEffect(() => {}, [props.isScreened]);
  if (loading) {
    return <div>Loading ...</div>;
  }
  if (!data) return <div></div>;
  const { getAppComments } = data;
  return (
    <>
      {getAppComments &&
        getAppComments.comments &&
        getAppComments.comments.map((comment, idx) => {
          return (
            <>
              {comment.originalCase && (
                <CommentView key={idx} {...comment} setModal={props.setModal} />
              )}
            </>
          );
        })}
    </>
  );
};

const AppComments = (props) => {
  const { users: userString, analytics } = strings;
  const [downloadUrl, setDownloadUrl] = useState('');
  const [radioValue, setRadioValue] = useState('PENDING');
  const radios = [
    { name: 'Pending', value: 'PENDING' },
    { name: 'Screened', value: 'SCREENED' },
  ];
  const pageSize = 10;
  const [skip, setSkip] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [dateState, setDateState] = useState({
    startDate: null,
    endDate: null,
  });
  const elemHeight = 300;
  const onScroll = (ev) => {
    const limit = ev.target.scrollHeight - elemHeight;
    if (limit - 30 < ev.target.scrollTop && !isLoading) {
      setSkip(skip + 1);
    }
  };
  const onLoad = (loadingStatus) => {
    setLoading(loadingStatus);
  };
  useEffect(() => {
    if (skip === -1) {
      setSkip(0);
    }
  }, [skip]);

  const onDateChange = (date, type) => {
    const customDate = date ? new Date(date) : null;

    setDateState((prevState) => ({
      ...prevState,
      [type]: customDate,
    }));
  };

  const [downloadCommentAnalytics, { loading: caseLoading }] = useMutation(
    DOWNLOAD_COMMENT_ANALYTICS,
    {
      onCompleted: (data) => {
        try {
          const { getCommentsAnalyticsDownload } = data;
          if (getCommentsAnalyticsDownload) {
            const { url } = getCommentsAnalyticsDownload;
            // console.log('-------url-----', url);
            window.open(url, '_blank').focus();
          }
        } catch (error) {
          setDownloadUrl(data?.getCommentsAnalyticsDownload?.url);
          // setShowModal(true);
        }
      },
    },
  );

  return (
    <>
      <Container>
        <div className="analytics-overview-admin">
          <div className="analytics-overview-container">
            <div className="top-container">
              <div className="custom-date-box">
                <div className="custom-button-column">
                  <span className="custom-button-label">{userString.from}</span>
                  <div className="custom-time-column">
                    <CustomDateTime
                      data={
                        !dateState.startDate
                          ? null
                          : new Date(dateState.startDate)
                      }
                      onChange={(e) => {
                        onDateChange(e, 'startDate');
                      }}
                      value={dateState ? dateState['startDate'] : null}
                      name={'startDate'}
                      maxDate={
                        dateState.endDate ? dateState.endDate : new Date()
                      }
                      placeholder={'Select Date'}
                      required={true}
                      isClearable={true}
                      dateFormat="dd-MMM-yyyy"
                    />
                  </div>
                </div>

                <div className="custom-button-column">
                  <span className="custom-button-label">{userString.to}</span>

                  <div className="custom-time-column">
                    <CustomDateTime
                      data={
                        !dateState.endDate ? null : new Date(dateState.endDate)
                      }
                      onChange={(e) => {
                        onDateChange(e, 'endDate');
                      }}
                      value={dateState ? dateState['endDate'] : null}
                      name={'endDate'}
                      minDate={dateState.startDate ? dateState.startDate : null}
                      maxDate={new Date()}
                      placeholder={'Select Date'}
                      required={true}
                      isClearable={true}
                      dateFormat="dd-MMM-yyyy"
                    />
                  </div>
                </div>
              </div>

              <div style={{ justifySelf: 'flex-end' }}>
                <Button
                  className="export-button"
                  variant="primary"
                  disabled={
                    (dateState.startDate === null && dateState.endDate) ||
                    (dateState.startDate && dateState.endDate === null) ||
                    caseLoading
                  }
                  onClick={() => {
                    let variables = {};
                    if (dateState.startDate && dateState.endDate) {
                      let to = new Date(dateState.endDate);
                      to.setHours(23, 59, 59, 999);

                      variables = {
                        // ...dateState,
                        startDate: formatToCustomUTCString(dateState.startDate),
                        endDate: formatToCustomUTCString(to),
                      };
                    }

                    downloadCommentAnalytics({
                      variables,
                    });
                  }}
                >
                  {caseLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {analytics.export_comments}
                    </>
                  ) : (
                    analytics.export_comments
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Col>
          <h4 className="my-3">Case/Question Comments</h4>
        </Col>
        <Col className="mb-3">
          <ToggleButtonGroup type="radio" name="radio">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={
                  radioValue === radio.value ? 'primary' : 'outline-dark'
                }
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => {
                  setRadioValue(e.currentTarget.value);
                  setSkip(-1);
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Col>
        <Col
          style={{
            overflowY: 'auto',
            maxHeight: `${elemHeight}px`,
          }}
          onScroll={onScroll}
          className="py-2"
        >
          {Array(skip + 1)
            .fill(0)
            .map((chunk, indx) => (
              <ViewCommentsChunk
                isScreened={radioValue === 'SCREENED'}
                key={indx}
                {...props}
                pageSize={pageSize}
                skip={indx}
                onLoad={onLoad}
              />
            ))}
        </Col>
      </Container>
    </>
  );
};

export default AppComments;
