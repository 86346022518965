import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import HsStarRating from '../../Common/HsStarRating';
import { strings } from '../../../assets/strings/all';

const RateCase = (props) => {
  const { open, closeCase, notes } = props;
  const { cases: caseString } = strings;

  const GRADES = [1, 2, 3, 4, 5];

  const [state, setState] = useState({
    rating: null,
    recommend: true,
    improvementNote: '',
  });

  const handleRecommend = () => {
    setState({
      ...state,
      recommend: !state.recommend,
    });
  };

  const handleRating = (rate) => {
    setState({
      ...state,
      rating: rate,
    });
  };

  const handleImprovement = (value) => {
    setState({
      ...state,
      improvementNote: value,
    });
  };

  const submitRating = () => {
    closeCase({
      rating: state.rating,
      isRecommended: state.recommend,
      improvementNote: state.improvementNote,
    });
  };

  return (
    <Modal
      show={open}
      size="md"
      className="delete-draft-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <h6>{caseString.rate_case}</h6>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="rating-closure-note">
          <div className="muted-text muted-text-big">
            {caseString.closure_reason}
          </div>
          <div>{notes}</div>
        </div>

        <Form.Group>
          <Form.Label className="muted-text">
            {caseString.rate_case_description}
          </Form.Label>
          <HsStarRating handleRating={handleRating} grades={GRADES} />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label className="muted-text">
            {caseString.recommend_pfizer}
          </Form.Label>

          <div className="recommend-radio-container muted-text">
            <div className="item">
              <input
                type="radio"
                id="yes"
                name="radio-group"
                checked={state.recommend}
                onClick={handleRecommend}
              />
              <label for="yes">{caseString.yes}</label>
            </div>
            <div className="item">
              <input
                type="radio"
                id="no"
                name="radio-group"
                checked={!state.recommend}
                onClick={handleRecommend}
              />
              <label for="no">{caseString.no}</label>
            </div>
          </div>
        </Form.Group>

        {/* <Form.Group controlId="formBasicEmail">
          <Form.Label className="muted-text">
            {caseString.improvement_recommendations}
          </Form.Label>

          <Form.Control
            maxLength={255}
            as="textarea"
            rows={3}
            value={state.improvementNote}
            onChange={(e) => handleImprovement(e.target.value)}
          ></Form.Control>
        </Form.Group> */}
      </Modal.Body>

      <Modal.Footer>
        <Row className="row-small">
          {/* <Col>
            <Button>{caseString.cancel}</Button>
          </Col> */}
          <Col>
            <Button
              disabled={state.rating ? false : true}
              onClick={submitRating}
            >
              {caseString.submit}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default RateCase;
