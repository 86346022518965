export const strings = {
  rejected: {
    title: 'Rejection Note',
    subtitle: 'Reject this Case/Question with a note.',
    action: 'Reject',
    button_title: 'Reject',
    status_slug: 'rejected',
  },
  approved: {
    title: 'Approval Note',
    subtitle: 'Approve this Case/Question with a note.',
    action: 'Approve',
    button_title: 'Approve',
    status_slug: 'approved',
  },
  archive: {
    title: 'Archive Note : ',
    subtitle: 'Archive this Case/Question with a note.',
    action: 'Archive',
    button_title: 'Archive',
    status_slug: 'archive',
  },
  unarchive: {
    title: 'Unarchive Note',
    subtitle: 'Unarchive this Case/Question with a note.',
    action: 'Unarchive',
    button_title: 'Unarchive',
    status_slug: 'close',
  },

  close: {
    title: 'Resolution Note :',
    subtitle: 'Close this Case/Question with a note.',
    action: 'Close',
    button_title: 'Close Case/Question',
    status_slug: 'close',
  },
  reopen: {
    title: 'Reopen Note',
    subtitle: 'Reopen this Case/Question with a note.',
    action: 'Reopen',
    button_title: 'Re-Open',
    status_slug: 'open',
  },
  action_cannot_undone: 'This action cannot be undone',
  cancel: 'Cancel',
  note_placeholder: 'Write here...',
  note_helper: 'Max. characters 1000',
};
