import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { strings } from '../../../assets/strings/all';
import AddCaseUserButton from '../AddCaseUserButton';
import CaseUserListItem from '../components/CaseUserListItem';
import permissionList from '../../../common/permissions';
import { hasCasePermission } from '../../../common/functions';
import { Form, FormGroup } from 'react-bootstrap';
import { UserContext } from '../../../containers/Context/UserContext';

function CoownerCard(props) {
  const { cases } = strings;
  const { data } = props;
  const { uuid, secondaryConsultants } = data;

  const { INVITE_CASE_CO_OWNER, ADD_NEW_USER } = permissionList;

  const canAddCoowner = hasCasePermission(data, INVITE_CASE_CO_OWNER);
  const canAddNewUser = hasCasePermission(data, ADD_NEW_USER);

  const { user } = useContext(UserContext);
  const shouldAddTeam = data.createdBy.uuid === user.uuid;
  return canAddCoowner || secondaryConsultants.length > 0 ? (
    <Card className="case-detail-side-card text-center">
      <Card.Body>
        {shouldAddTeam ? (
          <>
            {secondaryConsultants.length === 0 ? (
              <div className="muted-text muted-text-small text-center mb-2">
                You can add other HCPs to contribute to this case
              </div>
            ) : (
              <></>
            )}

            <AddCaseUserButton
              uuid={uuid}
              secondaryConsultants={secondaryConsultants}
              canAddNewUser={canAddNewUser}
            />
          </>
        ) : (
          <></>
        )}

        {secondaryConsultants.length > 0 ? (
          <div className="case-side-user-wrapper">
            <div className="case-side-user-wrapper-title">
              {cases.case_member}
            </div>
            <ListGroup variant="flush">
              {secondaryConsultants.map((user, idx) => (
                <CaseUserListItem
                  key={idx}
                  {...{ user }}
                  type="secondaryConsultant"
                />
              ))}
            </ListGroup>
          </div>
        ) : (
          <></>
        )}
      </Card.Body>
    </Card>
  ) : (
    <></>
  );
}

export default CoownerCard;
