import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { strings } from '../../../assets/strings/all';
import { FeedbackContext } from '../../../containers/Context/FeedbackContext';
import ActionModal from '../../Common/ActionButton/ActionModal';
import HsSelect from '../../Form/HsSelect';
import { UPDATE_ENTITY_FEEDBACK_STATUS } from '../query';

const CaseResolutionModal = (props) => {
  const { show, onClose, type, uuid, openReview, entityType } = props;
  const [selected, setSelected] = useState();
  const [openModal, setOpenModal] = useState(false);
  const { action, cases: caseString } = strings;
  const statusSlug = action[type].status_slug;

  const { setFeedback } = useContext(FeedbackContext);
  const closureTypes = [
    {
      name: 'Case/Question Resolved',
      note: '',
      value: 3,
    },
    {
      name: 'Dummy Case/Question',
      note: 'dummy',
      value: 1,
    },
    {
      name: 'Incorrect Details',
      note: 'rejected',
      value: 2,
    },
  ];

  const [updateFeedback] = useMutation(UPDATE_ENTITY_FEEDBACK_STATUS, {
    onCompleted: (data) => {
      setFeedback({ message: data.feedback.message, variant: 'success' });
      onClose();
    },
  });

  const onChange = (value) => {
    setSelected(value);
  };

  const onSubmit = () => {
    if (selected.value === 3) {
      setOpenModal(true);
    } else {
      updateFeedback({
        variables: {
          entityType,
          entityUuid: uuid,
          notes: selected.note,
          statusSlug,
        },
      });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        size="md"
        className="delete-draft-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>{caseString.resolve_case}</h6>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <HsSelect
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            placeholder={caseString.select_reason}
            options={closureTypes}
            {...props}
            value={selected}
            onChange={onChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row className="row-small">
            <Col>
              <Button variant="light" onClick={() => onClose()}>
                {caseString.cancel}
              </Button>
            </Col>
            <Col>
              <Button
                onClick={onSubmit}
                disabled={selected ? false : true}
                variant="primary"
              >
                {selected && selected.value === 3 ? (
                  <>{caseString.next}</>
                ) : (
                  <>{caseString.close}</>
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <>
        <ActionModal
          type={type}
          uuid={uuid}
          showNotes={true}
          show={openModal}
          entityType={props.entityType}
          onClose={() => setOpenModal(false)}
          openReview={openReview}
        />
      </>
    </>
  );
};

export default CaseResolutionModal;
