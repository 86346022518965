import React from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import HsHtmlRenderer from '../Common/HsHtmlRenderer';
import HsImage from '../Common/HsImage';
import { forceRelative } from '../../common/functions';

const ViewProfile = (props) => {
  const { user = {} } = props;
  const { profile, common } = strings;

  return (
    <>
      <div className="profile-top-action">
        <Col>
          <Link variant="primary" to={`/cases`} className="back-btn">
            <span className="icon-ico-back back-btn" />
          </Link>

          <span className="title">{profile.my_account}</span>
        </Col>
        <Col xs="auto">
          <Button variant="primary" as={Link} to={`/profile/edit-profile`}>
            {profile.edit}
          </Button>
        </Col>
      </div>
      <Row className="profile-container">
        <Col md={3}>
          <Image
            style={{ width: '100%', textAlign: 'center' }}
            src={user.avatar}
          />
        </Col>
        <Col md={9}>
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.email_address}
                </Form.Label>
                <div className="contact-number-container">
                  {user.email}
                  {user.isEmailVerified ? (
                    <>
                      <span className="icon-ico-info icon verified" />
                      <p className="verified">Verified</p>
                    </>
                  ) : (
                    <>
                      <span className="icon-ico-info icon not-verified" />
                      <p className="not-verified">Not Verified</p>
                    </>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>

          {user.userTitle && (
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="formLabel">{profile.title}</Form.Label>
                  <div>{user.userTitle.name}</div>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">Country</Form.Label>
                <div>{user.country.name}</div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.first_name}
                </Form.Label>
                <div>{user.firstName}</div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.last_name}
                </Form.Label>
                <div>{user.lastName}</div>
              </Form.Group>
            </Col>
          </Row>

          {user.association && (
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="formLabel">
                    {profile.association}
                  </Form.Label>
                  <div>{user.association}</div>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={8}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.phone_number}
                </Form.Label>
                <div className="contact-number-container">
                  {user.phoneCode}-{user.phoneNumber}
                  {user.isPhoneVerified ? (
                    <>
                      <span className="icon-ico-info icon verified" />
                      <p className="verified">Verified</p>
                    </>
                  ) : (
                    <>
                      <span className="icon-ico-info icon not-verified" />
                      <p className="not-verified">Not Verified</p>
                    </>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.license_status}
                </Form.Label>
                <div>
                  {user.isLicenseVerified ? (
                    <>
                      <p className="verified">Verified</p>
                    </>
                  ) : (
                    <>
                      <span className="not-verified">Not Verified</span>
                    </>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.license_id}
                </Form.Label>
                <div>{user.licenseId}</div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{profile.license_file}</Form.Label>
                {user.licenseFile ? (
                  user.licenseFile.map((license) => (
                    <div className="license-border-profile">
                      <Row style={{ margin: 0 }} className="license-file">
                        <Col md={3}>
                          <div className="left extension-wrapper">
                            <>
                              <HsImage
                                style={{ height: '70px' }}
                                src={license.thumbnail}
                              />
                            </>
                          </div>
                        </Col>

                        <Col md={9}>
                          <div className="right">
                            <div
                              style={{ maxWidth: '9rem' }}
                              className="muted-text oneLineDes"
                            >
                              {license.fileName}
                            </div>
                            <div>
                              <a
                                className="muted-text"
                                href={license.url}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {common.view}
                              </a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))
                ) : (
                  <> </>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.speciality}
                </Form.Label>
                {user.speciality && <div>{user.speciality.name}</div>}
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.profession}
                </Form.Label>
                <div>{user.profession.name}</div>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">{profile.about}</Form.Label>
                {/* <div className="justify-about">{user.about}</div> */}
                <div className="justify-about">
                  <HsHtmlRenderer content={user.about} />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ViewProfile;
