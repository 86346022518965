import React, { Component } from 'react';
import { seconds2time } from '../../common/functions';

class HsVideoPayer extends Component {
  state = {
    controls: false,
  };

  playPauseVideo = () => {
    if (this.props.type !== 'thumbnail') {
      const { postVideo } = this.refs;
      postVideo.paused ? postVideo.play() : postVideo.pause();
    }
  };

  showHideControls = () => {
    this.setState((prevState) => ({
      controls: !prevState.controls,
    }));
  };

  metaDataLoaded = () => {
    this.setState({
      duration: seconds2time(this.refs.postVideo.duration),
    });
  };

  render() {
    const { src } = this.props;
    return (
      <div className="videoWrapper">
        <video
          ref="postVideo"
          width="100%"
          controls={this.state.controls}
          onLoadedMetadata={this.metaDataLoaded}
          onPlay={this.showHideControls}
          onPause={this.showHideControls}
          onended={this.showHideControls}
        >
          <source src={src} />
        </video>
        {!this.state.controls && (
          <div className="videoInfoWrapper" onClick={this.playPauseVideo}>
            {this.state.duration && (
              <div className="durartionBox">{this.state.duration}</div>
            )}
            <div className="playButton">
              <label className="icon-ico-play-button" />
            </div>
          </div>
        )}

        {/* <video
        
        // preload="auto"
        style={{ width: "100%", height: "100%" }}
      /> */}
      </div>
    );
  }
}

export default HsVideoPayer;
