import React, { useState } from 'react';
import { Row, Card, Col, Button } from 'react-bootstrap';
import LicenseItem from './LicenseItem';
import LicenseVerificationModal from './LicenseVerificationModal';
import { strings } from '../../assets/strings/all';

const LicenseList = (props) => {
  const { user } = props;
  const { licenseFile } = user;
  const { users: userString } = strings;
  const [modalState, setModalState] = useState({
    open: false,
    type: '',
  });

  const closeLicenseVerificationModal = () => {
    setModalState({
      open: false,
      type: '',
    });
  };

  return (
    <>
      <Row>
        <Col className="user-license-title-container">
          <div className="user-license-title-flex">
            <Card.Text className="twoLineTitle card-title-padding p-0 card-license-label">
              {userString.license_id}
            </Card.Text>
            <Card.Text>
              <span className="card-license-value">{user.licenseId}</span>
            </Card.Text>
          </div>
        </Col>
        <Col>
          <Row>
            <span className="card-license-label">
              {userString.license_status}
            </span>
          </Row>

          <Row>
            <span className="card-license-value">
              {user.isLicenseVerified === true ? 'Verified' : 'Unverified'}
            </span>
          </Row>
        </Col>
        <Col xs={'auto'}>
          <Button
            onClick={() => {
              setModalState({
                open: true,
                type: user.isLicenseVerified ? 'unverify' : 'verify',
              });
            }}
          >
            {user.isLicenseVerified ? 'Unverify' : 'Verify'}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <span className="card-license-label">{userString.files}</span>
        </Col>
      </Row>
      <Row>
        {licenseFile.length && licenseFile.length > 0 ? (
          licenseFile.map((license, index) => (
            <>
              <Col md={3} sm={3} xs={12} key={license.url}>
                <LicenseItem user={user} license={license} index={index} />
              </Col>
            </>
          ))
        ) : (
          <>
            <Col>
              <span className="card-license-value">{userString.no_files}</span>
            </Col>
          </>
        )}
      </Row>
      <LicenseVerificationModal
        open={modalState.open}
        close={closeLicenseVerificationModal}
        type={modalState.type}
        uuid={user.uuid}
      />
    </>
  );
};

export default LicenseList;
