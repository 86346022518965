import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
const MostActiveBarChart = (props) => {
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Most active cases',
      },
    },
  };

  const { mostActiveCases } = props;
  const data = {
    labels: mostActiveCases.map((e) => 'CAVI-' + e.caseId),
    datasets: [
      {
        label: 'Number of views',
        data: mostActiveCases.map((e) => e.count),
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Bar color
      },
    ],
  };

  return <Bar width={'100%'} options={options} data={data} />;
};

export default MostActiveBarChart;
