import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const ShouldDeleteTeamModal = (props) => {
  const { open, close, removeTeam } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  return (
    <Modal size="md" show={open} onHide={close} centered>
      <Modal.Header>
        {' '}
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
          }}
        >
          Deleting team
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '14px' }}>
          Delete your created team from the created list
        </div>
        {loading && <div>loading...</div>}
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Button
          disabled={loading}
          variant="danger"
          onClick={async () => {
            setLoading(true);
            await removeTeam();
            close();
            setLoading(false);
          }}
        >
          Delete
        </Button>
        <Button disabled={loading} variant="outline-dark" onClick={close}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShouldDeleteTeamModal;
