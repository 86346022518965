export const getFileTypeFromUrl = (url) => {
  const extention = getExtentionFromUrl(url);
  if (checkIfImageExtention(extention)) {
    return 'image';
  } else if (checkIfVideoExtention(extention)) {
    return 'video';
  } else if (checkIfPDFExtention(extention)) {
    return 'pdf';
  }
  return 'file';
};

export const getExtentionFromUrl = (url) => {
  const re = /(?:\.([^.]+))?$/;
  const extentionType = re.exec(url)[1];

  return extentionType;
};

export const checkIfImageExtention = (ext) => {
  const isImage =
    ['png', 'jpg', 'jpeg', 'bmp', 'svg', 'svg', 'png', 'DCM'].indexOf(ext) > -1;

  return isImage;
};
export const checkIfVideoExtention = (ext) => {
  const isVideo = ['mp4'].indexOf(ext) > -1;

  return isVideo;
};
export const checkIfPDFExtention = (ext) => {
  const isPDF = ['pdf'].indexOf(ext) > -1;

  return isPDF;
};
