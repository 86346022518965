import gql from 'graphql-tag';

// #FRAGMENTS
export const NOTIFICATION_LIST_DETAILS = gql`
  fragment NotificationListDetails on Notification {
    uuid
    createdAt
    text
    data
    image
    description
    isRead
  }
`;

export const NOTIFICATION_LIST = gql`
  fragment NotificationList on NotificationResponse {
    hasMore
    totalCount
    notifications {
      ...NotificationListDetails
    }
  }
  ${NOTIFICATION_LIST_DETAILS}
`;

export const GET_NOTIFICATIONS = gql`
  query ($offset: Int, $limit: Int) {
    notificationList: getNotifications(offset: $offset, limit: $limit) {
      ...NotificationList
    }
  }
  ${NOTIFICATION_LIST}
`;

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation {
    notificationList: markAllNotificationsRead
  }
`;

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation ($uuid: String!) {
    notification: markNotificationAsRead(uuid: $uuid) {
      uuid
      isRead
    }
  }
`;

export const NEW_NOTIFICATION = gql`
  subscription ($uuid: String!) {
    notification: notificationReceived(uuid: $uuid) {
      notification {
        ...NotificationListDetails
      }
      notificationsCount
      unreadNotificationsCount
    }
  }
  ${NOTIFICATION_LIST_DETAILS}
`;

export const UREAD_NOTIFICATION_COUNT = gql`
  subscription ($uuid: String!) {
    count: unreadNotificationsCount(uuid: $uuid) {
      unreadNotificationsCount
    }
  }
`;
