import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { hasPermission } from '../../../common/functions';
import permissionList from '../../../common/permissions';
import ActionButton from '../../Common/ActionButton/ActionButton';
import CallButton from './CallButton';
import { strings } from '../../../assets/strings/all';
import { RoleContext } from '../../../containers/Context/RoleContext';

const CommentPopup = (props) => {
  const { uuid, caseStatusSlug, permissionArray, scrollToComment } = props;
  const { role } = useContext(RoleContext);

  const { action } = strings;

  const { CLOSE_MY_CASE, CALL } = permissionList;

  const hasClosePermission = hasPermission(permissionArray, CLOSE_MY_CASE);
  const hasCallPermission = hasPermission(permissionArray, CALL);
  return <></>;
  return (
    <>
      {(role !== null && role.name === 'Screener') || true ? (
        <></>
      ) : (
        <Col sm={12} className={`popup-container`}>
          <>
            <Row className="">
              <Button
                className="popup-button mb-2"
                onClick={() => scrollToComment()}
              >
                Add an Update
              </Button>
            </Row>
            <Row className="">
              {caseStatusSlug === 'open' && hasClosePermission ? (
                <ActionButton
                  styleName="popup-button mb-2"
                  entityType="cases"
                  type="close"
                  variant="primary"
                  uuid={uuid}
                />
              ) : (
                <>
                  <Button style={{ flex: 1 }} disabled>
                    {action.close.button_title}
                  </Button>
                </>
              )}
            </Row>
            {caseStatusSlug === 'open' && hasCallPermission && (
              <Row className="">
                {/* <CallButton styleName="popup-button" title="Call the Experts" /> */}
              </Row>
            )}
          </>
        </Col>
      )}
    </>
  );
};

export default CommentPopup;
