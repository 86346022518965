import React from 'react';
import { Switch } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import CreateJournal from '../Journal/CreateJournal';
import JournalView from '../Journal/JournalView';
import { AuthenticatedRoute } from './AuthenticatedRoute';

export default function JournalRoutes(props) {
  const { match } = props;
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={`${match.path}/`}
        component={(props) => (
          <Dashboard
            view="journals"
            viewType="journals"
            active="journals"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/create`}
        component={(props) => (
          <CreateJournal view="journals" active="journals" {...props} />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/:journal_slug`}
        component={(props) => (
          <JournalView
            view="journal"
            active="journals"
            type="view"
            breadcrumb={true}
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/:journal_slug/edit`}
        component={(props) => (
          <JournalView
            view="journal"
            active="journals"
            type="edit"
            {...props}
          />
        )}
      />
    </Switch>
  );
}
