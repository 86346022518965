import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { strings } from '../../../assets/strings/all';
import AddCaseJournalButton from '../AddCaseJournalButton';
import permissionList from '../../../common/permissions';
import { hasCasePermission } from '../../../common/functions';

function JournalListCard(props) {
  const { journals: journalsString } = strings;
  const { data } = props;
  const { uuid, journals } = data;

  const { ATTACH_JOURNAL_TO_CASE } = permissionList;

  const canAttachJournal = hasCasePermission(data, ATTACH_JOURNAL_TO_CASE);

  return (
    <>
      {canAttachJournal && journals.length === 0 ? (
        <Card className="case-detail-side-card">
          <Card.Body>
            <div className="text-center">
              <AddCaseJournalButton
                uuid={uuid}
                journals={journals}
                variant={'outline-primary'}
              />
              <div className="muted-text muted-text-small text-center mb-2">
                You can attach a journal as a reference to the case.
              </div>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}

      {journals.length > 0 ? (
        <Card className="case-detail-side-card">
          <Card.Body>
            {canAttachJournal ? (
              <div className="text-center">
                <AddCaseJournalButton
                  uuid={uuid}
                  journals={journals}
                  variant={'outline-primary'}
                />
              </div>
            ) : (
              <></>
            )}
            <div className="case-side-user-wrapper">
              <>
                <div className="case-side-user-wrapper-title">
                  {journalsString.title}
                </div>
                <ListGroup variant="flush">
                  {journals.map((journal) => (
                    <ListGroup.Item
                      as={Link}
                      to={`/journals/${journal.slug}`}
                      className="case-user-list-item"
                    >
                      <Row>
                        <Col>
                          <div className="title">{journal.title}</div>
                          <div className="subtitle">
                            {journal.caseType ? journal.caseType.name : null}
                          </div>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}

export default JournalListCard;
