export const strings = {
  microsite_invitations: 'Microsite Invitations',
  received: 'Received',
  requested_to_join: 'Requested to Join',
  discover_microsites: 'Discover Microsites',
  my_microsites: 'My Microsites',
  accept: 'Accept',
  reject: 'Reject',
  request_to_join: 'Request to join',
  search_microsites: 'Search Microsites',
  load_more: 'Load More',
  see_all: 'See All',
  withdraw: 'Withdraw',
  no_microsite: 'No microsites available',
  members: 'members',
  member: 'member',
  title: 'Microsite',
  association_overview: 'Association Overview',
  save: 'Save',
  url: 'Url',
  view_360: '360 Degree View',
  redirection_url: 'Redirection Url',
  copy_redirection_url: 'Copy Redirection Url',
  update: 'Update',
  microsite_logo: 'Microsite Logo',
  cover_image: 'Cover Image',
  microsite_cover: 'Microsite Cover',
  upload_logo: 'Upload Logo',
  upload_cover: 'Upload Cover',
  edit_microsite_details: 'Edit Microsite Details',
  survey_name: 'Survey Name',
  survey_name_placeholder: 'Enter Survey',
  award_currency: 'Award Currency',
  award_amount: 'Award Amount',
  award_amount_placeholder: 'Enter Amount (in number)',
  start_time: 'Start Time',
  start_time_placeholder: 'Start date & time',
  expire_time: 'Expire Time',
  expire_time_placeholder: 'Expire date & time',
  about_survey: 'About Survey',
  about_survey_placeholder: 'Write here...',
  upload_questionaire: 'Upload Questionnaires',
  upload_questionaire_placeholder: 'PDF, Doc, Docx, xls or xlsx only',
  attach_file: 'uploaded',
  send_request: 'Send Request',
  association_details: 'Association Details',
  home: 'Home',
  news: 'News',
  groups: 'Groups',
  webinars: 'Webinars',
  events: 'Events',
  articles: 'Articles',
  analytics: 'Analytics',
  admin: 'Admin',
  surveys: 'Surveys',
  about: 'About',
  eLearning: 'eLearning',
  see_all_requested_to_join: 'See all requested to join',
  surveys_title: 'Surveys',
  survey_title: 'Survey',
  request_survey: 'Request Survey',
  rewards: 'Rewards',
  expires_on: 'Expires on',
  start: 'Start',
  resume: 'Resume',
  new_survey: 'New Survey',
  edit_survey: 'Edit Survey',
  edit: 'Edit',
  reward: 'Reward',
  mutual_connections: 'Mutual connections',
  no_mutual_connections: 'No Mutual Connections',
  microsite_name: 'Microsite Name',
  microsite_name_placeholder: 'Enter Microsite Name...',
  speciality: 'Speciality',
  country: 'Country',
  brief: 'Brief',
  microsite_brief_placeholder: 'Enter brief about microsite...',
  founded_in: 'Founded In',
  industry: 'Industry',
  website_link: 'Website Link',
  contact_no: 'Contact No',
  contact_no_placeholder: 'Enter contact no...',
  email: 'Email',
  email_placeholder: 'Enter email...',
  address: 'Address',
  address_placeholder: 'Enter address...',
  about_microsite: 'About Microsite',
  create_microsite: 'Create Microsite',
  no_microsite_data: 'No microsites',
  manage: 'Manage',
  errors: {
    cover_required: 'Cover is required',
    cover_uploading: 'Uploading cover',
    video_required: 'Video is required',
    title_required: 'Title is required',
    title_max_length: 'Title max length is 250 character',
    brief_required: 'Brief description is required',
    brief_min_length: 'Brief description min length is 3 characters',
    brief_max_length: 'Brief description max length is 250 characters ',
    speciality_id_required: 'Speciality Id is required',
    avatar_required: 'Avatar is required',
    country_id_required: 'Country Id is required',
    founded_in_required: 'Founded In is required',
    category_id_required: 'Category Id is required',
    industry_id_required: 'Industry Id is required',
    contact_no_required: 'Contact no is required',
    email_required: 'Email is required',
    email_validation: 'Should be valid email',
    email_limit: 'Email length should not exceed 300 characters',
    description_required: 'Description is required',
    description_min_length: 'Decription min length is 3 characters',
    address_required: 'Address is required',
    website_required: 'Website is required',
    source_url: 'It should be valid website',
    source_max_limit: 'Webside length should not exceed 300 characters',
    name_required: 'Name is required',
    currency_required: 'Please select award currency',
    amount_required: 'Please enter award amount',
    amount_validation: 'Please enter valid award amount',
    start_at_required: 'Please enter start_at',
    expire_at_required: 'Please enter expire_at',
    expire_at_validation: 'End datetime should be greater than start datetime.',
    quest_required: 'Please upload questionnaires',
  },
  leave_microsite: 'Leave Microsite',
  leave_microsite_title: 'Are you sure you want to leave the microsite?',
  leave_microsite_subtitle: 'Are you sure you want to leave the microsite?',
  view_members: 'View members',
  wait: 'Please Wait....',
  cancel: 'Cancel',
  edit_tab: 'Edit Microsite Tab',
  delete_tab: 'Delete Microsite Tab',
  delete_tab_text: 'Are you sure you want to delete this tab?',
  update_tab: 'Update Microsite Tab',
  disabled_content: 'Disabled Content...',
  enabled_content: 'Enabled Content...',
  manage_microsite: 'Manage Microsite',
  how_to_use: 'How to use',
  enable_content: 'Enable Content',
  enable_content_text:
    'Click and drag {drag_icon} to move an item from “Disabled Content” box to “Enabled Content” box to enable a content item',
  rearrange_content: 'Rearrange Content Order',
  rearrange_content_text:
    'Click and drag {drag_icon} to move an item above/below another item to rearrange the order',
  edit_content: 'Edit Content',
  edit_content_text: 'Click {edit_icon} to edit/remove a content',
  navigate_content: 'Navigate Through Folders/Page Collections',
  navigate_content_text:
    'Click {arrow_icon} or on the content item to go into selected folder/page collection',
  create_new: 'Create New',
  folder: 'Folder',
  content: 'Content',
  create_folder: 'New Folder',
  update_folder: 'Update Folder',
  create_content: 'New Content',
  create_collection: 'Create Collection',
  edit_collection: 'Edit Collection',
  folder_title: 'Folder title',
  folder_description: 'Folder description',
  file_title: 'File title',
  file_description: 'File description',
  collection_title: 'Collection title',
  collection_description: 'Collection description',
  content_title: 'Content title',
  content_description: 'Content description',
  page_content: 'Page Content',
  page_collection: 'Page Collection',
  upload_files: 'Upload File(s)',
  create_new_folder: 'Create New Folder',
  manage_news: 'Manage News',
  manage_surveys: 'Manage Surveys',
  view_analytics: 'View Analytics',
  administration: 'Administration',
  num_file_selected: '{num} File(s) Selected',
  sure_delete_tab: 'Are you sure you want to delete the Tab?',
  upload_video: 'Upload Video',
  edit_video: 'Edit Video',
  video_list: 'Videos List',
  video_title_placeholder: 'Enter the video title',
  video_duration: 'Video Duration',
  in_min: '(In Minutes)',
  video_duration_placeholder: 'Enter the video duration',
  video_description: 'Video Description',
  video_description_placeholder: 'Enter the video Description',
  submit: 'Submit',
  cover: 'Cover',
  video_title: 'Video Title',
  browser_not_support: 'Browser not supported videos please update',
  delete: 'Delete',
  video_delete_confirmation: 'Are you sure you want to delete the video?',
  delete_video: 'Delete Video',
  delete_suggestion: 'You won’t be able to see this video again',
  delete_content: 'Delete Content',
  delete_content_text: 'Are you sure you want to delete this content?',
  delete_collection: 'Delete Collection',
  delete_collection_text: 'Are you sure you want to delete this collection?',
  delete_file: 'Delete File',
  delete_file_text: 'Are you sure you want to delete this file?',
  edit_folder: 'Edit Folder',
};
