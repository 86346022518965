import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
const SignInOptions = (props) => {
  const { active } = props;
  const { auth } = strings;

  return (
    <div className="signin-option-wrapper">
      <div className="or-wrapper">
        <span>Or</span>
      </div>
      {active !== 'pwd' ? (
        <div className="btn-outer">
          <Button
            as={Link}
            to="/signin"
            variant={active === 'otl' ? 'light' : 'primary'}
          >
            {auth.sign_in_using_pwd}
          </Button>
        </div>
      ) : (
        <></>
      )}
      {/* {active !== "owa" ? (
        <div className="btn-outer">
          <Button as={"a"} href="/owa" variant="pink">
            {auth.sign_in_using_owa}
          </Button>
        </div>
      ) : (
        <></>
      )} */}
      {active !== 'otl' ? (
        <div className="btn-outer">
          <Button as={Link} to="/one-time-sign-in" variant="light">
            {auth.sign_in_using_otl}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SignInOptions;
