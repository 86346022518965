import React from 'react';
import Select from 'react-select';
import Control from './Select/Control';
import GroupHeading from './Select/GroupHeading';
import IndicatorsContainer from './Select/IndicatorsContainer';
import DropdownIndicator from './Select/DropdownIndicator';

import Menu from './Select/Menu';
import MenuList from './Select/MenuList';
import Option from './Select/Option';
import ValueContainer from './Select/ValueContainer';
import Placeholder from './Select/Placeholder';
import SingleValue from './Select/SingleValue';
import MultiValueContainer from './Select/MultiValueContainer';
import MultiValueLabel from './Select/MultiValueLabel';
import MultiValueRemove from './Select/MultiValueRemove';

const HsSelect = (props) => {
  const {
    options,
    isMulti = false,
    isClearable = false,
    isSearchable = false,
    isDisabled = false,
  } = props;

  return (
    <Select
      options={options}
      components={{
        Control,
        Option,
        IndicatorsContainer,
        DropdownIndicator,
        Menu,
        MenuList,
        GroupHeading,
        ValueContainer,
        Placeholder,
        SingleValue,
        MultiValueContainer,
        MultiValueLabel,
        MultiValueRemove,
      }}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isMulti={isMulti}
      getOptionLabel={(option) => `${option.name}`}
      getOptionValue={(option) => option.slug}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

export default HsSelect;
