import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { GET_SEARCH_CASE } from '../../components/Case/query';
import Master from '../Layout/Master';

const CaseSearch = (props) => {
  const [value, setValue] = useState('');
  const history = useHistory();
  const { loading, data } = useQuery(GET_SEARCH_CASE, {
    variables: {
      text: value,
    },
    fetchPolicy: 'network-only',
  });
  return (
    <Master {...props}>
      <>
        <Col>
          <Card>
            <Card.Body>
              <input
                type="text"
                placeholder="Search for..."
                style={{
                  width: '100%',
                  border: 'none',
                }}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              {loading && <Row>Loading ...</Row>}

              {!loading &&
                data &&
                data.searchPublicCases &&
                data.searchPublicCases.length > 0 &&
                data.searchPublicCases.map((publicCase) => (
                  <>
                    <Col
                      onClick={() => {
                        history.push('/cases/' + publicCase.slug);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                      className="my-2 py-2 searchCard"
                    >
                      <Row>{publicCase.caseTitle}</Row>
                      <Row
                        style={{
                          fontWeight: 'lighter',
                          fontSize: '14px',
                        }}
                      >
                        {publicCase.slug}
                      </Row>
                    </Col>
                    <div
                      style={{
                        height: '1px',
                        backgroundColor: 'gray',
                        width: '100%',
                      }}
                    />
                  </>
                ))}
            </Card.Body>
          </Card>
        </Col>
      </>
    </Master>
  );
};

export default CaseSearch;
