import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { GET_USER_PROFILE } from "../../containers/Auth/query";
import { FeedbackContext } from "../../containers/Context/FeedbackContext";
import ProfileForm from "../Profile/ProfileForm";
import UserDetails from "./UserDetails";

function UserFetch(props) {
  const { type, match } = props;
  const { user_slug } = match.params;
  const query = GET_USER_PROFILE;
  const variables = { username: user_slug };

  const { setFeedback } = useContext(FeedbackContext);
  const history = useHistory();

  const { loading, error, data } = useQuery(query, {
    variables,
    fetchPolicy: "network-only",
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: "danger" });
        history.push("/cases");
      }
    },
  });

  if (loading || error || isEmpty(data)) {
    return "Loading";
  }

  const { profile } = data;

  return type === "edit" ? <ProfileForm {...props} profile={profile} /> : <UserDetails {...props} user={profile} />;
}

export default UserFetch;
