import React from 'react';
import Image from 'react-bootstrap/Image';
import { PropTypes } from 'prop-types';
import { Row } from 'react-bootstrap';

const getStatusMessage = (slug) => {
  switch (slug) {
    case 'screening':
      return 'Thank you for your submission';
    case 'draft':
      return ' A Preview of the Case/Question before submission';
    case 'open':
      return 'This Case/Question is open for discussion ( Open )';
    case 'close':
      return 'This Case/Question is closed ( Closed )';
    case 'returned':
      return 'This Case/Question has been returned';
    default:
      return '-';
  }
};
const getStatusColor = (slug) => {
  switch (slug) {
    case 'screening':
      return 'darkcyan';
    case 'draft':
      return '';
    case 'open':
      return '#005587';
    case 'close':
      return 'teal';
    case 'returned':
      return 'red';
    default:
      return '-';
  }
};

function HsCaseStatusIcon(props) {
  const { caseStatus = { slug: '' }, loading } = props;
  const { iconClass } = caseStatus;

  return loading ? (
    <span className="case-status-loader shimmer-loader"></span>
  ) : (
    <Row
      className="px-2"
      style={{
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Image width={25} fluid {...props} src={iconClass} />
      <div
        style={{
          color: getStatusColor(caseStatus.slug),
          fontWeight: 'bold',
        }}
      >
        {getStatusMessage(caseStatus.slug)}
      </div>
    </Row>
  );
}
HsCaseStatusIcon.propTypes = {
  caseStatus: PropTypes.object,
};

export default HsCaseStatusIcon;
