import gql from 'graphql-tag';
import { OWNER_DETAILS } from '../Case/query';

// #FRAGMENTS

export const COMMENT_DETAILS = gql`
  fragment CommentDetails on Comment {
    id
    caseId
    uuid
    content
    commentsCount
    likesCount
    hasLiked
    createdAt
    isExpert
    attachments {
      url
      attachmentType
      fileType
      thumbnail
    }
    caseStatus {
      name
      slug
    }
    createdBy {
      ...OwnerDetails
    }
    statusLog {
      notes
    }
    permissionArray
  }
  ${OWNER_DETAILS}
`;

export const COMMENT_DETAILS_WITH_REPLY = gql`
  fragment CommentDetailsWithReply on Comment {
    ...CommentDetails
    latestReply {
      ...CommentDetails
    }
  }
  ${COMMENT_DETAILS}
`;

export const COMMENT_LIST = gql`
  fragment CommentList on CommentList {
    hasMore
    totalCount
    comments {
      ...CommentDetailsWithReply
    }
  }
  ${COMMENT_DETAILS_WITH_REPLY}
`;

export const GET_COMMENTS = gql`
  query (
    $entityUuid: String!
    $entityType: String!
    $limit: Int
    $offset: Int
  ) {
    commentList: getComments(
      entityUuid: $entityUuid
      entityType: $entityType
      limit: $limit
      offset: $offset
    ) {
      ...CommentList
    }
  }
  ${COMMENT_LIST}
`;

export const GET_COMMENTS_FOR_MODERATION = gql`
  query (
    $uuid: String!
    $filters: CommentFilterInput
    $limit: Int
    $offset: Int
  ) {
    commentList: getCommentsForModeration(
      uuid: $uuid
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      ...CommentList
    }
  }
  ${COMMENT_LIST}
`;

export const GET_COMMENTS_COUNT_FOR_MODERATION = gql`
  query ($uuid: String!, $filters: CommentFilterInput) {
    count: getCommentsCountForModeration(uuid: $uuid, filters: $filters)
  }
`;

export const CREATE_COMMENT = gql`
  mutation (
    $entityUuid: String!
    $entityType: String!
    $commentData: CommentInput
    $caseId: Int!
  ) {
    comment: createComment(
      entityUuid: $entityUuid
      entityType: $entityType
      commentData: $commentData
      caseId: $caseId
    ) {
      success
      message
      comment {
        ...CommentDetails
        case {
          uuid
          commentsCount
        }
        parentComment {
          uuid
          commentsCount
        }
      }
    }
  }
  ${COMMENT_DETAILS}
`;

export const UPDATE_COMMENT = gql`
  mutation ($uuid: String!, $commentData: CommentInput) {
    comment: updateComment(uuid: $uuid, commentData: $commentData) {
      success
      message
      comment {
        ...CommentDetails
        case {
          uuid
          commentsCount
        }
        parentComment {
          uuid
          commentsCount
        }
      }
    }
  }
  ${COMMENT_DETAILS}
`;

export const LIKE_UNLIKE_ENTITY = gql`
  mutation ($entityUuid: String!, $entityType: String!) {
    like: likeUnlikeEntity(entityUuid: $entityUuid, entityType: $entityType) {
      success
      message
      comment {
        uuid
        likesCount
        hasLiked
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation ($uuid: String!) {
    comment: deleteComment(uuid: $uuid) {
      success
      message
      parentObj {
        uuid
        commentsCount
      }
    }
  }
`;

export const HIDE_UNHIDE_COMMENT = gql`
  mutation ($uuid: String!) {
    comment: hideUnhideComment(uuid: $uuid) {
      success
      message
      comment {
        uuid
        isHidden
      }
    }
  }
`;

export const GET_APP_COMMENTS = gql`
  query ($skip: Int!, $pageSize: Int!, $isScreened: Boolean) {
    getAppComments(skip: $skip, pageSize: $pageSize, isScreened: $isScreened) {
      comments {
        id
        content
        isScreened
        originalCase {
          caseId
          slug
          title
        }
        User {
          id
          firstName
          lastName
        }
        caseCountry {
          name
          iso3
        }
      }
    }
  }
`;

export const SCREEN_COMMENT = gql`
  mutation ($commentId: Int!, $hasBeenScreened: Boolean!) {
    screenComment(commentId: $commentId, hasBeenScreened: $hasBeenScreened) {
      success
      comment {
        id
        isScreened
      }
    }
  }
`;

export const SCREEN_CASE = gql`
  mutation (
    $caseId: Int
    $reasonId: Int
    $note: String
    $shouldReturn: Boolean!
  ) {
    screenCase(
      caseId: $caseId
      reasonId: $reasonId
      note: $note
      shouldReturn: $shouldReturn
    ) {
      success
    }
  }
`;
export const REPORT_COMMENT = gql`
  mutation ($commentId: Int!, $note: String!, $country: String!) {
    reportComment(commentId: $commentId, note: $note, country: $country) {
      success
    }
  }
`;

export const DOWNLOAD_COMMENT_ANALYTICS = gql`
  mutation ($startDate: String, $endDate: String) {
    getCommentsAnalyticsDownload(startDate: $startDate, endDate: $endDate) {
      url
    }
  }
`;
export const REPORT_CASE = gql`
  mutation ($caseId: Int!, $note: String!, $country: String!) {
    reportCase(caseId: $caseId, note: $note, country: $country) {
      success
    }
  }
`;
