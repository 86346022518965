import React from 'react';
import { components } from 'react-select';

const Control = (props) => {
  let newProps = { ...props };
  return (
    <components.Control
      {...newProps}
      className={`hs-dropdown-control ${
        newProps.isFocused ? 'hs-dropdown-control-focused' : ''
      }`}
    />
  );
};

export default Control;
