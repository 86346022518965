import React from 'react';
import { Switch } from 'react-router-dom';
import CreateCase from '../Case/CreateCase';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import CaseView from '../Case/CaseView';
import Dashboard from '../Dashboard/Dashboard';

export default function ReviewRoutes(props) {
  const { match } = props;
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={`${match.path}/`}
        component={(props) => (
          <Dashboard
            view="review"
            viewType="review"
            active="review"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/create`}
        component={(props) => (
          <CreateCase view="review" active="review" {...props} />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/:case_slug`}
        component={(props) => (
          <CaseView view="case" active="review" type="view" {...props} />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/:case_slug/edit`}
        component={(props) => (
          <CaseView view="case" active="review" type="edit" {...props} />
        )}
      />
    </Switch>
  );
}
