import { useMutation } from '@apollo/client';
import React from 'react';
import { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { FOLOW_UNFOLLOW_CASE } from '../../Case/query';
import { FeedbackContext } from '../../../containers/Context/FeedbackContext';
import { GET_CASES, GET_CASES_COUNT } from '../../CaseList/query';

function FollowButton(props) {
  const { uuid, isFollowing, className, type } = props;
  const { setFeedback } = useContext(FeedbackContext);

  const [followUnfollowCase] = useMutation(FOLOW_UNFOLLOW_CASE, {
    variables: { uuid: uuid },
    onCompleted: (data) => {
      const {
        case: { message, success },
      } = data;
      setFeedback({ message, variant: success ? 'success' : 'danger' });
    },
    refetchQueries: [
      {
        query: GET_CASES_COUNT,
        variables: { filters: { filterType: 1 } },
      },
    ],
    update: (cache, { data }) => {
      const {
        case: {
          case: { isFollowing, uuid },
        },
      } = data;

      if (!isFollowing) {
        try {
          let caseListData = cache.readQuery({
            query: GET_CASES,
            variables: { filters: { filterType: 1 }, limit: 18 },
          });

          let cases = caseListData.caseList.cases;
          const newCases = cases.filter(
            (singleCase) => singleCase.uuid !== uuid,
          );

          const newCaseListData = {
            ...caseListData,
            caseList: {
              ...caseListData.caseList,
              cases: newCases,
            },
          };

          cache.writeQuery({
            query: GET_CASES,
            variables: { filters: { filterType: 1 }, limit: 18 },
            data: newCaseListData,
          });
          props.addDataAfterRemove(1, newCaseListData.caseList.cases.length);
        } catch (e) {}
      }
    },
  });

  const isSideCard = type === 'side';

  return !isSideCard ? (
    <Button
      variant="primary"
      onClick={(e) => {
        e.stopPropagation();
        followUnfollowCase();
      }}
      className={className}
    >
      {isFollowing ? 'Unfollow' : 'Follow'}
    </Button>
  ) : !isFollowing ? (
    <div className="text-center">
      <div className="muted-text muted-text-small mb-2">
        To receive updates regarding this case
      </div>
      <Button
        variant="primary"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          followUnfollowCase();
        }}
        className={className}
      >
        Follow This case
      </Button>
    </div>
  ) : (
    <div className="muted-text muted-text-small text-center">
      You are following this case.
      <div>To stop receiving updates regarding this case.</div>
      <Button
        size="sm"
        variant="text"
        className="text-primary"
        onClick={(e) => {
          e.stopPropagation();
          followUnfollowCase();
        }}
      >
        Unfollow this case
      </Button>
    </div>
  );
}

export default FollowButton;
