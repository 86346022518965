export const strings = {
  title: 'Jobs',
  view: 'Already Viewed',
  apply: 'Apply',
  recommendedJobs: 'Recommended Jobs',
  allRecommmendation: 'All Recommendations',
  my_speciality: 'My Speciality',
  speciality: 'Speciality',
  summary: 'Summary',
  edit: 'Edit',
  job: 'Job',
  url: 'Source (Optional)',
  serachJob: 'Search Job',
  no_recommendation: 'No recommendations',
  no_recommendation_message: `We couldn't find any jobs matching your profile`,
  shareJobDetails: 'Share Job Details',
  warning: "Don't show this again",
  similar: 'Similar Jobs',
  job_description: 'Job Description',
  cover_image: 'Cover Image',
  job_title:'Job Title',
  company_name:'Company Name',
  title_placeholder: 'Enter title',
  company_placeholder: 'Enter Company Name',
  url_placeholder: 'Paste URL link here',
  publish: 'Published',
  published: 'Publish',
  delete_job: 'Delete Job',
  discard_changes: 'Ok',
  save_job_confirm: 'Are you sure you want Delete?',
  save_job_confirm_para:'Record will be deleted from the list',
    errors: {
      cover_required: 'You need to upload cover image',
      title_required: 'Job Title is required',
      company_min_length: 'Company Name has to be min 3 characters',
      company_max_length: 'Company Name cannot be more than 250 characters',
      company_required: 'Company Name is required',
      url_required: 'Job url is required',
      country_required: 'Country is required',
      title_min_length: 'Job title has to be min 3 characters',
      title_max_length: 'Job title cannot be more than 250 characters',
      brief_required: 'Job Brief is required',
      brief_min_length: 'Job Brief has to be min 3 characters',
      brief_max_length: 'Job Brief cannot be more than 250 characters',
      duration_number: 'Time should always be a number',
      duration_min: 'Time should be greater than zero',
      duration_max: 'Time should not be greater than 1000',
      url: 'Source should be valid URL',
      url_max_limit: 'Source cannot be more than 300 characters',
      description_required: 'Job description is required',
      description_min_length: 'Job description has to be min 3 characters',
      description_max_length:
        'Job description cannot be more than 2500 characters',
      speciality_id_required: 'Please choose a speciality',
      country_id_required: 'Please choose a country',
      cover_uploading: 'Cover is being uploaded',
    },
};
