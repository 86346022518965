import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Button, Col, Form, FormGroup, Modal } from 'react-bootstrap';
import { GET_REASONS } from '../../Case/query';
import { SCREEN_CASE } from '../../Comment/query';

export const CaseReturnsModal = (props) => {
  const { open, close, onFinish, id } = props;
  const { data, loading } = useQuery(GET_REASONS);
  const [isLoading, setLoading] = useState(false);
  const [returnCase] = useMutation(SCREEN_CASE);
  const {} = props;
  if (loading) return <div>Loading...</div>;
  const reasons = data.getReturnReasons;
  return (
    <Modal size="md" show={open} onHide={close} centered>
      <Form
        onSubmit={async (ev) => {
          ev.preventDefault();
          setLoading(true);
          await returnCase({
            variables: {
              caseId: id,
              reasonId: parseInt(ev.target[1].value),
              note: ev.target[0].value,
              shouldReturn: true,
            },
          });
          window.location.reload();
          onFinish();
        }}
      >
        <Modal.Header>
          <h6
            style={{
              color: 'slategray',
            }}
          >
            Return case
          </h6>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Col className="mb-4">
              <Form.Control
                as="input"
                placeholder="Return message..."
              ></Form.Control>
            </Col>
            <Col>
              <Form.Control as="select">
                {reasons.map((reason, idx) => (
                  <option key={idx} value={reason.id}>
                    {reason.reason}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            type="submit"
            className="mx-4"
            variant="outline-danger"
          >
            Return Case
          </Button>
          <Button
            onClick={() => {
              onFinish();
            }}
            variant="outline-dark"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
