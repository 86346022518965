import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { strings } from '../../../assets/strings/all';
import { UPDATE_ENTITY_FEEDBACK_STATUS } from '../../Case/query';
import { FeedbackContext } from '../../../containers/Context/FeedbackContext';
import RateCase from '../../Case/components/RateCase';

function ActionModal(props) {
  const { setFeedback } = useContext(FeedbackContext);
  const { type, show, showNotes, uuid, onClose, entityType } = props;
  const { action } = strings;
  const typeString = action[type];
  const [notes, setNotes] = useState(null);
  const [openReview, setOpenReview] = useState(false);

  const statusSlug = action[type].status_slug;

  const [updateFeedback] = useMutation(UPDATE_ENTITY_FEEDBACK_STATUS, {
    onCompleted: (data) => {
      window.location.reload();
      setFeedback({ message: data.feedback.message, variant: 'success' });
      onClose();
    },
  });

  // useEffect(() => {
  //   if (type === 'unarchive') {
  //     updateFeedback({
  //       variables: {
  //         entityType,
  //         entityUuid: uuid,
  //         notes: 'case unarchived',
  //         statusSlug,
  //       },
  //     });
  //     onClose();
  //   } else if (type === 'archive') {
  //     updateFeedback({
  //       variables: {
  //         entityType,
  //         entityUuid: uuid,
  //         notes: 'case archived',
  //         statusSlug,
  //       },
  //     });
  //     onClose();
  //   }
  // }, [type]);

  const submit = () => {
    if (type === 'close') {
      setOpenReview(true);
      onClose();
    } else {
      updateFeedback({
        variables: { entityType, entityUuid: uuid, notes, statusSlug },
      });
    }
  };

  const closeReviewModal = () => {
    setOpenReview(false);
  };

  const closeCaseWithReview = ({ rating, isRecommended, improvementNote }) => {
    setOpenReview(false);

    updateFeedback({
      variables: {
        entityType,
        entityUuid: uuid,
        notes,
        statusSlug,
        rating,
        isRecommended,
        improvementNote,
      },
    });
  };

  return (
    <>
      <Modal
        // {...this.props}
        show={show}
        onHide={onClose}
        size="md"
        className="delete-draft-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>{typeString.title}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="muted-text ">{typeString.subtitle}</p>
          {showNotes ? (
            <>
              <Form.Control
                as={'textarea'}
                type="text"
                name="notes"
                {...props}
                placeholder={action.note_placeholder}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
              <div className="text-right">{action.note_helper}</div>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onClose()}>
            {action.cancel}
          </Button>
          <Button
            disabled={showNotes && !notes}
            variant="primary"
            onClick={() => {
              submit();
            }}
          >
            {typeString.action}
          </Button>
        </Modal.Footer>
      </Modal>

      <RateCase
        open={openReview}
        close={closeReviewModal}
        closeCase={closeCaseWithReview}
        notes={notes}
      />
    </>
  );
}

export default ActionModal;
