import React, { useRef, useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onClickOutside) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      const rootElem = document.querySelector('#sdb-root');

      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        rootElem.contains(event.target)
      ) {
        return onClickOutside ? onClickOutside() : null;
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function HsOutsideAlerter(props) {
  const wrapperRef = useRef(null);
  const { onClickOutside, style = {} } = props;
  useOutsideAlerter(wrapperRef, onClickOutside);

  return (
    <div style={style} ref={wrapperRef}>
      {props.children}
    </div>
  );
}
