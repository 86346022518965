import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import HomeNoAuth from '../Auth/HomeNoAuth';
import Dashboard from '../Dashboard/Dashboard';
import Initialize from '../Helper/Initialize';
import ScrollToTop from '../Layout/ScrollToTop';
import NotificationWrapper from '../Notification/NotificationWrapper';
import { AuthenticatedRoute, BeforeAuthRoute } from './AuthenticatedRoute';
import CaseRoutes from './CaseRoutes';
import JournalRoutes from './JournalRoutes';
import ProfileRoutes from './ProfileRoutes';
import ReviewRoutes from './ReviewRoutes';
import SettingRoutes from './SettingRoute';
import UserRoutes from './UserRoutes';
import AutoLogin from '../Auth/AutoLoginLink';
import VerifyEmail from '../../components/Auth/VerifyEmail';
import AnalyticsRoute from './AnalyticsRoute';
import TnCnPrivacy from '../Layout/TnCnPrivacy';

// export default function Routes(props) {
//   return (
//     <Route path='/' component={HCP}>
//       <Route path='about' component={HCP} />
//       {/* {HcpRoute} */}
//       <Route path='*' component={HCP} />
//     </Route>
//   );
// }
class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <AuthenticatedRoute path="/cases" component={CaseRoutes} />
            <AuthenticatedRoute path="/journals" component={JournalRoutes} />
            <AuthenticatedRoute path="/analytics" component={AnalyticsRoute} />
            <AuthenticatedRoute path="/review" component={ReviewRoutes} />
            <AuthenticatedRoute path="/users" component={UserRoutes} />
            <AuthenticatedRoute
              path="/terms-and-conditions-and-privacy-policy"
              component={(props) => <TnCnPrivacy {...props} />}
            />

            <Route path="/profile" component={ProfileRoutes} />
            <Route
              exact
              path="/terms-and-conditions"
              component={(props) => (
                <Dashboard view="tnc" active="tnc" {...props} />
              )}
            />

            <Route
              exact
              path="/account-deletion"
              component={(props) => (
                <Dashboard
                  view="account-deletion"
                  active="account-deletion"
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/privacy-policy"
              component={(props) => (
                <Dashboard view="privacy" active="privacy" {...props} />
              )}
            />
            <AuthenticatedRoute path="/settings" component={SettingRoutes} />
            <AuthenticatedRoute
              exact
              path="/notifications"
              component={(props) => (
                <NotificationWrapper view="signin" active="signin" {...props} />
              )}
            />

            <BeforeAuthRoute
              exact
              path="/"
              component={(props) => (
                <HomeNoAuth view="signin" active="signin" {...props} />
              )}
            />
            <BeforeAuthRoute
              exact
              path="/signin"
              component={(props) => (
                <HomeNoAuth view="signin" active="signin" {...props} />
              )}
            />

            <BeforeAuthRoute
              exact
              path="/forgot-password"
              component={(props) => (
                <HomeNoAuth
                  view="forgotPassword"
                  active="forgotPassword"
                  {...props}
                />
              )}
            />

            <BeforeAuthRoute
              exact
              path="/sign-up"
              component={(props) => (
                <HomeNoAuth view="signUp" active="signUp" {...props} />
              )}
            />

            <BeforeAuthRoute
              exact
              path="/one-time-sign-in"
              component={(props) => (
                <HomeNoAuth
                  view="oneTimeSignIn"
                  active="oneTimeSignIn"
                  {...props}
                />
              )}
            />

            <BeforeAuthRoute
              exact
              path="/reset-password"
              component={(props) => (
                <HomeNoAuth
                  view="resetPassword"
                  active="resetPassword"
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/verify-email/:token"
              component={(props) => (
                <VerifyEmail
                  view="verifyEmail"
                  viewType="verifyEmail"
                  active="verifyEmail"
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/auto-login-link/:token"
              component={(props) => (
                <AutoLogin
                  view="autoLogin"
                  viewType="login"
                  active="login"
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/setting-up"
              component={(props) => (
                <Initialize view="initialize" active="initialize" {...props} />
              )}
            />

            <AuthenticatedRoute
              exact
              path="/dashboard"
              component={(props) => (
                <Dashboard
                  view="cases"
                  active="cases"
                  viewType="cases"
                  {...props}
                />
              )}
            />
            <Route
              path="*"
              component={(props) => <div {...props}>404 not found</div>}
            />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default Routes;
