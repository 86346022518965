import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { CaseConfigContext } from '../../../containers/Context/CaseContext';
import TeamCreationModal from '../../CaseForm/components/teamCreation.modal';
import { MY_TEAMS } from '../../CaseForm/query';
import { dummyUser } from '../../Form/autocomplete';
const TeamDataView = (props) => {
  const { teams, refetch } = props;
  const [openCreate, setOpen] = useState(false);
  const [allSelected, setAll] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const [checkedTeams, setChecked] = useState(Array(0).fill(false));
  const { config, setConfig } = useContext(CaseConfigContext);
  useEffect(() => {
    if (selectedTeam) {
      setOpen(true);
    }
  }, [selectedTeam]);
  useEffect(() => {
    const newTeam = [];
    checkedTeams.map((v, i) => {
      if (v) {
        newTeam.push(teams[i].id);
      }
    });
    setConfig({
      ...config,
      teams: newTeam,
    });
  }, [checkedTeams]);
  return (
    <div>
      <Row>
        <Col style={{ fontWeight: 'lighter', fontSize: '13px' }}>
          Please be aware that the option to add or create a team for your case
          or question is only available before the submission. However, you have
          the flexibility to add any member at any stage to your case or
          question
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="outline-primary"
            style={{
              margin: '10px 0',
            }}
            onClick={() => {
              setSelectedTeam(undefined);
              setOpen(true);
            }}
          >
            Create Team
          </Button>
        </Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <input
            className="hs-check muted-text"
            type="checkbox"
            checked={allSelected}
            onChange={(e) => {
              setAll(e.target.checked);
              if (e.target.checked) {
                setChecked(Array(teams.length).fill(true));
              } else {
                setChecked([]);
              }
            }}
          />
          <label
            onClick={() => {
              if (!allSelected) {
                setChecked(Array(teams.length).fill(true));
              } else {
                setChecked([]);
              }
              setAll(!allSelected);
            }}
          >
            Select all
          </label>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          {teams.map((team, idx) => {
            return (
              <Row
                style={{
                  padding: '8px 0',
                }}
                key={idx}
              >
                <Col
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 1fr 1fr',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <input
                      className="hs-check muted-text"
                      type="checkbox"
                      checked={checkedTeams[idx]}
                      onChange={(e) => {
                        const cloned = [...checkedTeams];
                        cloned[idx] = e.target.checked;
                        setChecked(cloned);
                      }}
                    />
                    <div>{team.name}</div>
                  </div>
                  <div>#{team.users.length}</div>
                  <div>
                    <Button
                      variant="outline-dark"
                      style={{
                        fontSize: '.7rem',
                      }}
                      onClick={() => {
                        if (selectedTeam && selectedTeam.id === teams[idx].id) {
                          setOpen(true);
                        } else {
                          setSelectedTeam(teams[idx]);
                        }
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Card.Body>
      </Card>
      <TeamCreationModal
        open={openCreate}
        close={() => {
          setOpen(false);
        }}
        onFinish={() => {
          refetch();
        }}
        team={selectedTeam}
      />
    </div>
  );
};

const TeamViewCard = (props) => {
  const { error, data, refetch, teamLoading } = useQuery(MY_TEAMS);
  if (teamLoading || data === undefined) {
    return <div>Loading...</div>;
  }
  const getTeams = data['getTeams'];
  return (
    <>
      <TeamDataView teams={getTeams} refetch={refetch} />
    </>
  );
};

export default TeamViewCard;
