import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CaseTypeFilter from '../../Common/Filter/CaseTypeFilter';
import HsSort from '../../Common/Filter/HsSort';
import HsButtonToolbar from '../../Common/HsButtonToolbar';

function ReviewCaseFilter(props) {
  const caseStatuses = [
    { slug: 'in-review', name: 'Pending' },
    { slug: 'published', name: 'Approved' },
    { slug: 'rejected', name: 'Rejected' },
  ];
  return (
    <Row>
      <Col xs={12} sm={8} className="mb-2">
        <HsButtonToolbar {...props} {...{ filters: caseStatuses }} />
      </Col>
      <Col xs={6} sm={2} className="mb-2">
        <CaseTypeFilter
          onChange={(e) => props.updateFilter({ caseTypeSlug: e.slug })}
        />
      </Col>
      <Col xs={6} sm={2} className="mb-2">
        <HsSort onChange={(e) => props.updateFilter({ caseOrder: e.slug })} />
      </Col>
    </Row>
  );
}

export default ReviewCaseFilter;
