import { useApolloClient } from '@apollo/client';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import {
  clearLocalStorage,
  deleteCookie,
  getCookie,
  setCookie,
} from '../../common/functions';
import { UserContext } from './UserContext';

export const IdleTimerContext = createContext();

const IdleTimerContextProvider = (props) => {
  const history = useHistory();
  const { user, logout, setUser } = useContext(UserContext);
  const timeout = 60 * 60 * 1000; //1 hr in milliseconds
  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(timeout);
  const { auth } = strings;
  const client = useApolloClient();

  //RESET TIMER ON ACTIVE
  const handleOnActive = () => {
    reset();
  };

  //LOGOUT IF IDLE
  const handleOnIdle = () => {
    setOpen(false);
    if (!isEmpty(user)) {
      logout();
    }
  };

  const handleOnAction = () => {
    setCookie('last_activity_cookie', new Date());
  };

  const resetIdleCounter = () => {
    reset();
  };

  const { reset, getRemainingTime } = useIdleTimer({
    timeout,
    crossTab: true,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
  });
  const cleanSetup = () => {
    if (user.id) {
      clearLocalStorage();
      deleteCookie('csrf-token');
      deleteCookie('session');
      deleteCookie('session.sig');
      client.clearStore();
      setUser({});
      history.push('/');
    }
  };
  useEffect(() => {
    //CHECK IF CURRENT TIME IS MORE THAN TIMEOUT FROM LAST ACTIVE
    const last_active = getCookie('last_activity_cookie');
    const diff = moment(new Date()).diff(last_active, 'milliseconds');

    //If user is idle for more than timeout
    if (diff > timeout) {
      cleanSetup();
    }

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
  }, []);

  useEffect(() => {
    // const min5 = 5 * 60 * 1000;
    const min5 = 1 * 60 * 1000;

    if (!isEmpty(user)) {
      if (remaining < min5) {
        setOpen(true);
      }
    }
  }, [remaining]);

  const closeModal = () => {
    setOpen(false);
    cleanSetup();
  };

  const resetCounter = () => {
    setOpen(true);
    closeModal();
  };

  return (
    <IdleTimerContext.Provider
      value={{
        resetIdleCounter,
        open,
        setOpen,
        onClose: closeModal,
        title: auth.session_title,
        body: auth.session_body,
        callback: resetCounter,
      }}
    >
      {props.children}
    </IdleTimerContext.Provider>
  );
};

export default IdleTimerContextProvider;
