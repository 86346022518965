import { format } from 'date-fns';
import { isEmpty, isInteger } from 'lodash';
import React, { useContext, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { getOtherDetailFields, hasField } from '../../common/functions';
import { OrganizationContext } from '../../containers/Context/OrganizationContext';
import AttachmentInputWrapper from '../CaseForm/components/AttachmentInputWrapper';
import CaseTitle from '../CaseForm/components/CaseTitle';
import WebLinkInputWrapper from '../CaseForm/components/WebLinkInputWrapper';
import CaseTypeFilter from '../Common/Filter/CaseTypeFilter';
import VisibilityStatusSelect from '../Common/Filter/VisibilityStatusSelect';
import HsFormInput from '../Form/HsFormInput';
import HsFormLabel from '../Form/HsFormLabel';
import HsRadioGroup from '../Form/HsRadioGroup';
import CaseExtraFields from './CaseExtraFields';

function CaseTemplateForm(props) {
  const { organization } = useContext(OrganizationContext);

  const { caseFields = [] } = organization;

  const fieldArray = Object.assign(
    {},
    ...caseFields.map((c) => ({ [c.slug]: c })),
  );
  const fieldNames = Object.keys(fieldArray);
  const extraFields = getOtherDetailFields(caseFields) || [];

  const initialCaseData = { ...props.caseData };
  if (isEmpty(initialCaseData.patientGender)) {
    initialCaseData.patientGender = { name: 'Male', slug: 'male' };
  }

  const [caseData, setCaseData] = useState(initialCaseData);

  const [errors] = useState({});

  const updateCaseData = (name, value) => {
    const newCaseData = { ...caseData, [name]: value };

    if (
      newCaseData.visibilityStatus &&
      newCaseData.visibilityStatus.slug === 'private'
    ) {
      newCaseData.isModerationEnabled = false;
    }

    setCaseData(newCaseData);
  };

  const hasCaseFields = (field) => {
    return hasField(fieldNames, field);
  };
  // if (!hasEditPermission) {
  //   setFeedback({ variant: 'danger', message: 'You are not allowed' });
  //   history.push(`/cases/${caseData.slug}`);
  // }

  return (
    <Form className="form-article">
      {hasCaseFields('title') ? (
        <Row className="case-title-wrapper disabled-field">
          <Col md={12}>
            <CaseTitle
              {...{ caseFields }}
              data={caseData}
              onChange={(e) => updateCaseData('title', e.target.value)}
            />
            {errors['title'] && (
              <Form.Control.Feedback type="invalid">
                {errors['title']}
              </Form.Control.Feedback>
            )}
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className="case-detail-wrapper disabled-field">
        {hasCaseFields('visibilityStatus') ? (
          <Col md={3}>
            <Form.Group controlId="formBasicEmail">
              <VisibilityStatusSelect
                onChange={(e) => {
                  updateCaseData('visibilityStatus', e);
                }}
                value={caseData.visibilityStatus}
              />
              {errors['visibilityStatus'] && (
                <Form.Control.Feedback type="invalid">
                  {errors['visibilityStatus']}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}

        {hasCaseFields('caseType') ? (
          <Col md={3}>
            <Form.Group controlId="formBasicEmail">
              <CaseTypeFilter
                onChange={(e) => updateCaseData('caseType', e)}
                value={caseData.caseType}
                type="select"
              />
              {errors['caseType'] && (
                <Form.Control.Feedback type="invalid">
                  {errors['caseType']}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
        {hasCaseFields('caseId') ? (
          <Col md={6}>
            <Form.Group controlId="formBasicEmail">
              <HsFormLabel field={fieldArray['caseId']} />
              <HsFormInput
                name="caseId"
                {...{ field: fieldArray['caseId'] }}
                onChange={(name, value) => {
                  updateCaseData(name, value);
                }}
                value={caseData.caseId}
              />
              {errors['caseId'] && (
                <Form.Control.Feedback type="invalid">
                  {errors['caseId']}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <Row className="disabled-field">
        <Col>
          {caseData.visibilityStatus &&
          caseData.visibilityStatus.slug === 'public' ? (
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Check
                    className="hs-check muted-text"
                    type="checkbox"
                    onChange={(e) =>
                      updateCaseData('isModerationEnabled', e.target.checked)
                    }
                    id="isModerationEnabled"
                    label="Enable Moderation"
                    checked={caseData.isModerationEnabled}
                    custom={true}
                  />
                </Form.Group>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      </Row>

      <Row className="disabled-field">
        <Col xs={12}>
          <Form.Label className="formLabel">Patient’s Basic Info</Form.Label>
        </Col>
        {hasCaseFields('patientGender') ? (
          <Col md={'auto'}>
            <Form.Group controlId="formBasicEmail">
              <HsRadioGroup
                className={'case-create'}
                details={'patientGender'}
                name={'patientGender'}
                onToggle={(name, value) => {
                  updateCaseData(name, value);
                }}
                value={caseData.patientGender}
                labels={[
                  { name: 'Male', slug: 'male' },
                  { name: 'Female', slug: 'female' },
                ]}
              />
              {errors['patientGender'] && (
                <Form.Control.Feedback type="invalid">
                  {errors['patientGender']}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
        {hasCaseFields('patientAge') ? (
          <Col>
            <Form.Group controlId="formBasicEmail">
              <HsFormInput
                {...{
                  field: fieldArray['patientAge'],
                  name: 'patientAge',
                }}
                onChange={(name, value) => {
                  const intValue = parseInt(value);
                  const patientAge =
                    isInteger(intValue) && intValue > 0 && intValue < 151
                      ? intValue
                      : caseData.patientAge || '';
                  updateCaseData('patientAge', patientAge);
                }}
                value={caseData.patientAge}
              />
              {errors['patientAge'] && (
                <Form.Control.Feedback type="invalid">
                  {errors['patientAge']}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
        {hasCaseFields('patientStage') ? (
          <Col>
            <Form.Group controlId="formBasicEmail">
              <HsFormInput
                {...{ field: fieldArray['patientStage'] }}
                onChange={(name, value) =>
                  updateCaseData('patientStage', value)
                }
                value={caseData.patientStage}
              />
              {errors['patientStage'] && (
                <Form.Control.Feedback type="invalid">
                  {errors['patientStage']}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
        {hasCaseFields('patientInitalDianosisDate') ? (
          <Col>
            <Form.Group controlId="formBasicEmail">
              <HsFormInput
                {...{ field: fieldArray['patientInitalDianosisDate'] }}
                value={caseData['patientInitalDianosisDate']}
                onChange={(date) =>
                  updateCaseData(
                    'patientInitalDianosisDate',
                    format(date, 'yyyy-MM-dd'),
                  )
                }
                maxDate={new Date()}
              />
              {errors['patientInitalDianosisDate'] && (
                <Form.Control.Feedback type="invalid">
                  {errors['patientInitalDianosisDate']}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <Row className="disabled-field">
        <Col>
          <Form.Group controlId="formBasicEmail">
            <AttachmentInputWrapper
              {...{ data: caseData }}
              onChange={(value) => updateCaseData('attachments', value)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="disabled-field">
        <Col>
          <WebLinkInputWrapper
            {...{ data: caseData }}
            onChange={(value) => updateCaseData('links', value)}
          />
        </Col>
      </Row>

      <CaseExtraFields extraFields={extraFields} />
    </Form>
  );
}

export default CaseTemplateForm;
