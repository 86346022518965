import React from 'react';
import HsSelect from '../../Form/HsSelect';

const HsCountryFilter = (props) => {
  const { onChange, type } = props;

  const Countries = [
    {
      name: 'All',
      slug: undefined,
    },
    {
      name: 'Lebanon',
      slug: 118,
    },
    {
      name: 'India',
      slug: 99,
    },
    {
      name: 'Algeria',
      slug: 2,
    },
    {
      name: 'Egypt',
      slug: 63,
    },
  ];

  return (
    <>
      <HsSelect
        label="Country"
        placeholder="Country"
        options={[{ label: 'Country', options: Countries }]}
        // defaultIcon="icon-ico-hide"
        defaultValue={Countries[0]}
        onChange={onChange}
        isClearable={false}
        {...props}
        // value={caseData.visibilityStatus}
      />
    </>
  );
};

export default HsCountryFilter;
