import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import TermsAndCondition from './TermsAndCondition';
import PrivacyPolicy from './PrivacyPolicy';
import { Button, Container, Form } from 'react-bootstrap';
import HsAlert from '../../components/Common/HsAlert';
import Header from './Header';
import { ACCEPT_TNC } from '../Auth/query';
import { FeedbackContext } from '../Context/FeedbackContext';
import {
  getQueryParameter,
  getTokenFromLocalStorage,
  storeDataToLocalStorage,
} from '../../common/functions';
import { strings } from '../../assets/strings/all';

const TnCnPrivacy = (props) => {
  const maindiv = {
    display: 'flex',
    flexDirection: 'column',
  };
  const btn = { margin: '100px 20px', padding: '7px 20px' };
  const checkBox = { margin: '50px 20px' };
  const { auth: authString } = strings;

  const { setFeedback } = useContext(FeedbackContext);
  const [isTncAccepted, setIsTncAccepted] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);

  const [acceptTnc, { loading, error, data }] = useMutation(ACCEPT_TNC, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },

    onCompleted: () => {
      console.log('dataaaaaaa', data);

      //   await storeDataToLocalStorage('token', acceptTnc.token, true);
      //   setFeedback({});
      //   props.history.replace(`/cases`);
      // }, 50);
    },
  });
  let redirect_to = getQueryParameter('redirect_to');

  if (data && data.acceptTnc) {
    // console.log(' data.acceptTnc', data.acceptTnc);

    setTimeout(async () => {
      await storeDataToLocalStorage('token', data.acceptTnc.token, true);
      setFeedback({});
      props.history.replace(`/cases`);
      if (!redirect_to) {
        props.history.replace(`/cases`);
      } else {
        console.log('redirected', redirect_to);
        props.history.replace(`/cases?redirect_to=${redirect_to}`);
      }
    }, 50);
  }

  return (
    <>
      <Header active="completeProfile" />
      <Form>
        <div className="sdb-body" id="sdb-body" style={{ marginTop: '8%' }}>
          <HsAlert />
          <Container style={maindiv}>
            <div style={{ border: '0.5px solid black', padding: '10px' }}>
              <TermsAndCondition />
            </div>
            <div className="flex-display" style={checkBox}>
              <Form.Check
                className="hs-check"
                type="checkbox"
                onChange={(e) => {
                  setIsTncAccepted(!isTncAccepted);
                }}
                name="isTncAccepted"
                value={isTncAccepted}
                checked={isTncAccepted}
              />
              <>
                <span className="text-muted">I agree to {authString.tnc}</span>
              </>
            </div>
          </Container>
        </div>

        <div className="sdb-body" id="sdb-body">
          <HsAlert />
          <Container
            style={{
              ...maindiv,
            }}
          >
            <div style={{ border: '0.5px solid black', padding: '10px' }}>
              <PrivacyPolicy />
            </div>

            <div
              className="flex-display"
              style={{ justifyContent: 'space-between' }}
            >
              <div className="flex-display" style={checkBox}>
                <Form.Check
                  className="hs-check"
                  type="checkbox"
                  onChange={(e) => {
                    setIsPrivacyAccepted(!isPrivacyAccepted);
                  }}
                  name="isPrivacyAccepted"
                  value={isPrivacyAccepted}
                  checked={isPrivacyAccepted}
                />
                <>
                  <span className="text-muted">
                    I agree to {authString.privacy_policy}
                  </span>
                </>
              </div>

              <Button
                style={btn}
                disabled={!(isPrivacyAccepted && isTncAccepted)}
                onClick={async (event) => {
                  try {
                    acceptTnc({
                      variables: {
                        isTncAccepted: Date.now(),
                        token: getTokenFromLocalStorage(),
                      },
                    });
                  } catch (error) {
                    setFeedback(error.message);
                  }
                }}
              >
                I Agree
              </Button>
            </div>
          </Container>
        </div>
      </Form>
    </>
  );
};

export default TnCnPrivacy;
