import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import LikeButton from '../Common/ActionButton/LikeButton';
import ActionButton from '../Common/ActionButton/ActionButton';
import { strings } from '../../assets/strings/all';

function CommentFeedback(props) {
  const {
    commentData,
    setShowReplies,
    isReply,
    canReviewComment,
    caseStatus: { slug: caseStatusSlug } = {},
    viewType,
  } = props;
  const { hasLiked, uuid, likesCount, commentsCount } = commentData;
  const { post: postString } = strings;

  return (
    <>
      <Row className="action-button-block">
        <Col>
          {canReviewComment &&
          caseStatusSlug === 'in-review' &&
          viewType === 'reviewComments' ? (
            <Row className="row-small">
              <Col xs="auto" className="action-button-wrapper">
                <ActionButton
                  entityType="comments"
                  type="approved"
                  variant="primary"
                  uuid={uuid}
                  size="sm"
                />
              </Col>
              <Col xs="auto" className="action-button-wrapper">
                <ActionButton
                  entityType="comments"
                  type="rejected"
                  variant="outline-danger"
                  uuid={uuid}
                  size="sm"
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {/* {canReviewComment && caseStatusSlug === 'open' ? (
            'This comment was approved'
          ) : (
            <></>
          )} */}

          {caseStatusSlug === 'open' ? (
            <Row className="row-small">
              <Col xs="auto" className="action-button-wrapper">
                <LikeButton
                  entityType={'comments'}
                  entityUuid={uuid}
                  hasLiked={hasLiked}
                  likesCount={likesCount}
                />
              </Col>
              {!isReply ? (
                <Col xs="auto" className="action-button-wrapper">
                  <Button
                    variant="link"
                    onClick={() => setShowReplies(true)}
                    className="action-button"
                  >
                    <span className="icon icon-ico-message-no-alert" />
                    <span className="action-text">
                      {strings.formatString(
                        commentsCount === 1
                          ? postString.num_reply
                          : postString.num_replies,
                        {
                          num: commentsCount,
                        },
                      )}{' '}
                    </span>
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
}

export default CommentFeedback;
