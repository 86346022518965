import React, { useState } from "react";
import HsStar from "./HsStar";

const HsStarRating = (props) => {
  const [gradeIndex, setGradeIndex] = useState();
  const { handleRating, grades } = props;

  const changeGradeIndex = (index) => {
    setGradeIndex(index);
    handleRating(grades[index]);
  };

  return (
    <div className="rating-container">
      {/* <p className="rating-result">{grades[gradeIndex] ? grades[gradeIndex] : ""}</p> */}
      <div className="rating-stars">
        {grades.map((grade, index) => (
          <HsStar index={index} key={grade} changeGradeIndex={changeGradeIndex} className={gradeIndex >= index ? "rating-active-star" : ""} />
        ))}
      </div>
    </div>
  );
};

export default HsStarRating;
