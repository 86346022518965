import React, { Component, createContext } from 'react';
import { ApolloConsumer } from '@apollo/client';
import {
  clearLocalStorage,
  getUserDataFromLocalStorage,
  getUserToken,
} from '../../common/functions';
// Provider and AuthConsumer are connected through their "parent" context
const { Provider, Consumer: AuthConsumer } = createContext();

// Provider will be exported wrapped in AuthConfigProvider component.
class AuthConfigProvider extends Component {
  constructor() {
    super();
    let token = getUserToken();
    let user = getUserDataFromLocalStorage();
    this.client = null;

    this.state = {
      alert: null,
      notifications: [],
      locationChanged: false,
      isAuth: token ? true : false,
      user: user ? user : {},
      locale: localStorage.getItem('locale')
        ? localStorage.getItem('locale')
        : 'en',
      login: async () => {
        let token = await getUserToken();

        if (token) {
          await this.setState({
            isAuth: true,
            token,
          });
        } else {
          await this.setState({
            isAuth: false,
            token: null,
          });
        }
      },
      profile: (user) => {
        this.setState({
          user,
        });
      },
      setAlert: (alert) => {
        this.setState({
          alert,
        });
      },
      addNotification: (notification) => {
        let { notifications } = { ...this.state };
        notifications.unshift(notification);
        if (notifications.length > 3) {
          notifications.pop();
        }
        this.setState({
          notifications,
        });
      },
      removeNotification: (index) => {
        let { notifications } = { ...this.state };
        notifications.splice(index, 1);
        this.setState({
          notifications,
        });
      },
      logout: async () => {
        // await this.client.mutate({ mutation: SIGNOUT });
        clearLocalStorage(true);
        this.client.resetStore();
        this.setState({
          isAuth: false,
          token: null,
          user: null,
        });
      },
      setLocationChanged: () => {
        this.setState({
          locationChanged: true,
        });
      },
    };
  }

  componentDidMount = async () => {
    await this.state.login();
    await this.state.profile();
  };

  setClient = (apolloClient) => {
    this.client = apolloClient;
  };

  render() {
    const {
      login,
      logout,
      profile,
      isAuth,
      user,
      setAlert,
      alert,
      notifications,
      addNotification,
      removeNotification,
      locationChanged,
      setLocationChanged,
    } = this.state;

    return (
      <ApolloConsumer>
        {(apolloClient) => {
          if (!this.client) this.setClient(apolloClient);
          return (
            <Provider
              value={{
                login,
                logout,
                profile,
                isAuth,
                user,
                setAlert,
                alert,
                notifications,
                addNotification,
                removeNotification,
                locationChanged,
                setLocationChanged,
              }}
            >
              {this.props.children}
            </Provider>
          );
        }}
      </ApolloConsumer>
    );
  }
}

export { AuthConfigProvider };

// I make this default since it will probably be exported most often.
export default AuthConsumer;
