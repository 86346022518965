import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import { Router, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { forceRelative } from '../../../common/functions';

function CaseUserListItem(props) {
  const {
    user,
    style = {},
    border = true,
    onClick = () => {},
    newPage = false,
  } = props;
  const history = useHistory();
  const pageNav = () => {
    if (newPage) {
      const url =
        'http://' + document.location.hostname + `/users/${user.username}`;
      window.open(url);
    } else {
      history.push(`/users/${user.username}`);
    }
  };
  return (
    <ListGroup.Item
      className="case-user-list-item"
      style={{
        padding: '4px',
        border: border ? 'auto' : 'none',
        ...style,
        cursor: 'pointer',
      }}
      onClick={() => {
        onClick();
      }}
    >
      <Row className="row-small">
        <Col
          onClick={() => {
            pageNav();
          }}
          xs="auto"
        >
          <Image
            src={
              user.avatar
                ? forceRelative(user.avatar)
                : '/api/static/placeholder.svg'
            }
          />
        </Col>
        <Col>
          <div className="title">
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={pageNav}
              to={`/users/${user.username}`}
            >
              {user.fullName || user.email}
            </div>
          </div>
          <div className="subtitle">
            {user.speciality ? user.speciality.name : null}
          </div>
          <div className="subtitle">
            {user.association ? user.association : null}
          </div>
        </Col>
      </Row>
      <Row
        className="row-small"
        style={{
          padding: '4px',
          justifyContent: 'start',
        }}
      >
        <div className="mt-1 oneLineDes muted-text">
          {user.about ? user.about : null}{' '}
        </div>
      </Row>
    </ListGroup.Item>
  );
}

export default CaseUserListItem;
