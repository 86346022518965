import { useMutation } from '@apollo/client';
import { pick } from 'lodash';
import React, { useContext } from 'react';
import {
  REMOVE_LICENSE,
  UPDATE_PROFILE,
  userInput,
} from '../../components/User/query';
import ProfileForm from './ProfileForm';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';

function EditProfile(props) {
  const { user } = props;
  const { setFeedback } = useContext(FeedbackContext);

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    onCompleted: (data) => {
      const { user } = data;
      if (user.success) {
        setFeedback({ variant: 'success', message: user.message });
      } else {
        setFeedback({ variant: 'danger', message: user.message });
      }

      props.history.push('/profile');
    },
  });

  const [removeLicense] = useMutation(REMOVE_LICENSE, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    onCompleted: (data) => {
      setFeedback({ variant: 'success', message: 'Success' });
    },
  });

  const onSubmit = (userObj) => {
    const userInputData = { ...userObj };

    if (!userInputData.avatar.includes('static')) {
      setFeedback({
        message: 'Profile picture is still uploading...',
        variant: 'danger',
      });
      return;
    }
    const {
      // profession,
      userTitle,
      speciality,
    } = userInputData;

    // if (profession) {
    //   userInputData.professionSlug = profession.slug;
    // }
    if (speciality) {
      userInputData.specialitySlug = speciality.slug;
    }
    if (userTitle) {
      userInputData.userTitleSlug = userTitle.slug;
    }

    const userData = pick(userInputData, userInput);

    updateProfile({ variables: { userData } });
  };

  const onRemove = (removeObj) => {
    removeLicense({
      variables: {
        ...removeObj,
      },
    });
  };

  // if (loading && !isEmpty(errors)) {
  //   setErrors({});
  // }

  // const errorObj = parseGraphqlErrors(signInErrors);

  // if (errorObj.errors && !isEqual(errorObj.errors, errors)) {
  //   setErrors(errorObj.errors);
  // }
  // if (errorObj.message && !isEqual(errorObj.message, message)) {
  //   setMessage(errorObj.message);
  // }

  return (
    <ProfileForm
      active={props.active}
      user={user}
      onSubmit={onSubmit}
      onRemove={onRemove}
      loading={loading}
    />
  );
}

export default EditProfile;
