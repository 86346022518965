import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteLicenseModal = (props) => {
  const { show, closeModal, onRemove, licenseFile } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={closeModal}
        className="confirmation-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete License?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this license</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeModal}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              onRemove({
                url: licenseFile.url,
              });
              closeModal();
            }}
          >
            Delete License
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteLicenseModal;
