import React, { useEffect, useState } from 'react';
import {
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Spinner,
  Button,
} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CaseTypeFilter from '../../Common/Filter/CaseTypeFilter';
import HsSort from '../../Common/Filter/HsSort';
import HsButtonToolbar from '../../Common/HsButtonToolbar';
import CaseList from '../CaseList';
import CaseListWrapper from '../CaseListWrapper';
import { GET_SCREENER_CASES } from '../query';
import { strings } from '../../../assets/strings/all';
import CustomDateTime from '../../Common/CustomDateTime';
import { useMutation } from '@apollo/client';
import { DOWNLOAD_CASE_ANALYTICS } from '../../Case/query';
import { formatToCustomUTCString } from '../../../common/functions';

function ScreenerFilters(props) {
  const { users: userString, analytics } = strings;

  const [radioValue, setRadioValue] = useState('PENDING');
  const [loading, setLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [dateState, setDateState] = useState({
    startDate: null,
    endDate: null,
  });

  const onDateChange = (date, type) => {
    const customDate = date ? new Date(date) : null;

    setDateState((prevState) => ({
      ...prevState,
      [type]: customDate,
    }));
  };

  const [downloadCaseAnalytics, { loading: caseLoading }] = useMutation(
    DOWNLOAD_CASE_ANALYTICS,
    {
      onCompleted: (data) => {
        try {
          const { getCasesAnalyticsDownload } = data;
          if (getCasesAnalyticsDownload) {
            const { url } = getCasesAnalyticsDownload;
            // console.log('-------url-----', url);
            window.open(url, '_blank').focus();
          }
        } catch (error) {
          setDownloadUrl(data?.getCasesAnalyticsDownload?.url);
          // setShowModal(true);
        }
      },
    },
  );

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);
  const radios = [
    { name: 'Pending', value: 'PENDING' },
    { name: 'Screened', value: 'SCREENED' },
  ];
  return (
    <Container className="px-4">
      <div className="analytics-overview-admin mb-2">
        <div className="analytics-overview-container">
          <div className="top-container">
            <div className="custom-date-box">
              <div className="custom-button-column">
                <span className="custom-button-label">{userString.from}</span>
                <div className="custom-time-column">
                  <CustomDateTime
                    data={
                      !dateState.startDate
                        ? null
                        : new Date(dateState.startDate)
                    }
                    onChange={(e) => {
                      onDateChange(e, 'startDate');
                    }}
                    value={dateState ? dateState['startDate'] : null}
                    name={'startDate'}
                    maxDate={dateState.endDate ? dateState.endDate : new Date()}
                    placeholder={'Select Date'}
                    required={true}
                    isClearable={true}
                    dateFormat="dd-MMM-yyyy"
                  />
                </div>
              </div>

              <div className="custom-button-column">
                <span className="custom-button-label">{userString.to}</span>

                <div className="custom-time-column">
                  <CustomDateTime
                    data={
                      !dateState.endDate ? null : new Date(dateState.endDate)
                    }
                    onChange={(e) => {
                      onDateChange(e, 'endDate');
                    }}
                    value={dateState ? dateState['endDate'] : null}
                    name={'endDate'}
                    minDate={dateState.startDate ? dateState.startDate : null}
                    maxDate={new Date()}
                    placeholder={'Select Date'}
                    required={true}
                    isClearable={true}
                    dateFormat="dd-MMM-yyyy"
                  />
                </div>
              </div>
            </div>

            <div style={{ justifySelf: 'flex-end' }}>
              <Button
                className="export-button"
                variant="primary"
                disabled={
                  (dateState.startDate === null && dateState.endDate) ||
                  (dateState.startDate && dateState.endDate === null) ||
                  caseLoading
                }
                onClick={() => {
                  let variables = {};
                  if (dateState.startDate && dateState.endDate) {
                    let to = new Date(dateState.endDate);
                    to.setHours(23, 59, 59, 999);

                    // to = to.toUTCString();
                    variables = {
                      // ...dateState,
                      startDate: formatToCustomUTCString(dateState.startDate),
                      endDate: formatToCustomUTCString(to),
                    };
                  }

                  downloadCaseAnalytics({
                    variables,
                  });
                }}
              >
                {caseLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {analytics.export_cases}
                  </>
                ) : (
                  analytics.export_cases
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* <Row xs={12} sm={8} className="mb-4"> */}
      <Col className="mb-3">
        <ToggleButtonGroup type="radio" name="radio">
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={radioValue === radio.value ? 'primary' : 'outline-dark'}
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => {
                setRadioValue(e.currentTarget.value);
                setLoading(true);
              }}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Col>
      {/* </Row> */}
      <Col className="py-2">
        {!loading && (
          <CaseList
            variables={{ isScreened: radioValue === 'SCREENED' }}
            customGetter={GET_SCREENER_CASES}
          />
        )}
      </Col>
    </Container>
  );
}

export default ScreenerFilters;
