import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ActionModal from './ActionModal';
import { strings } from '../../../assets/strings/all';
import CaseResolutionModal from '../../Case/components/CaseResolutionModal';

function ActionButton(props) {
  const { action } = strings;
  const { uuid, type, variant, size = '', openReview, styleName } = props;
  const [show, setShow] = useState();

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Button
        className={styleName ? styleName : ''}
        variant={variant}
        onClick={() => toggleShow()}
        size={size}
      >
        {action[type].button_title}
      </Button>
      {show ? (
        <>
          {type === 'close' ? (
            <>
              <CaseResolutionModal
                uuid={uuid}
                show={show}
                {...props}
                onClose={() => toggleShow()}
              />
            </>
          ) : (
            <>
              <ActionModal
                type={type}
                uuid={uuid}
                showNotes={true}
                show={show}
                entityType={props.entityType}
                onClose={() => toggleShow()}
                openReview={openReview}
              />
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ActionButton;
