import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { withRouter } from 'react-router-dom';
import HsMediaModal from './HsMediaModal';

function HsMediaWrapper(props) {
  const { media, user, post: { is_media_downloadable = true } = {} } = props;
  const [openModal, setOpenModal] = useState(false);

  const doOpenModal = (open) => {
    // const { pathname } = props.location;
    // if (open) {
    //   props.history.push(`${pathname}#media`);
    // } else {
    //   props.history.push(`${pathname}`);
    // }
    setOpenModal(open);
  };

  const mediaObj = {
    image: [],
    video: [],
    file: [],
    all: [],
  };

  media.map((file) => {
    if (file && file.length > 0) {
      file.forEach((image) => {
        image.downloadable = is_media_downloadable;
        mediaObj.image.push(image);
      });
    } else {
      if (file.type === 'image') {
        mediaObj.image.push(file);
      } else if (file.type === 'video') {
        mediaObj.video.push(file);
      } else {
        mediaObj.file.push(file);
      }
    }
    return file;
  });

  mediaObj.all = [...mediaObj.image, ...mediaObj.video, ...mediaObj.file];

  return (
    <>
      <Row className="attachment-row row-small">
        {mediaObj.all.map((i) => (
          <Col md={2} onClick={() => doOpenModal(true)}>
            <div className="squareWrapper">
              <div className="squareContent">
                <Image
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                  fluid={true}
                  src={
                    i.fileType !== 'pdf' && i.fileType !== 'video'
                      ? i.url
                      : i.thumbnail
                  }
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
      {/* {mediaObj.image.length > 0 && (
        <HsMediaSlider
          files={mediaObj.image}
          type="image"
          setOpenModal={doOpenModal}
        />
      )}
      {mediaObj.video.length > 0 && (
        <HsMediaSlider
          files={mediaObj.video}
          type="video"
          setOpenModal={doOpenModal}
        />
      )}
      {mediaObj.file.length > 0 && (
        <HsMediaSlider
          files={mediaObj.file}
          type="file"
          setOpenModal={doOpenModal}
        />
      )} */}
      {openModal && (
        <HsMediaModal
          user={user}
          files={mediaObj.all}
          openModal={openModal}
          setOpenModal={doOpenModal}
          microsite={props.microsite}
        />
      )}
    </>
  );
}

export default withRouter(HsMediaWrapper);
