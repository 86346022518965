import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { hasPermission, userHasPermission } from '../../common/functions';
import permissionList from '../../common/permissions';
import { UserContext } from '../../containers/Context/UserContext';
import ActionButton from '../Common/ActionButton/ActionButton';
import CallButton from './components/CallButton';
import { strings } from '../../assets/strings/all';
import CaseDisclaimerModal from '../CaseForm/components/CaseDisclaimerModal';

const CaseDetailTopAction = (props) => {
  const [open, setOpen] = useState(false);
  const {
    data: {
      uuid,
      slug,
      caseStatus: { slug: caseStatusSlug },
      permissionArray,
    },
    publishCase,
  } = props;

  const { cases: caseString } = strings;

  const {
    PUBLISHED_CASE_REVIEW,
    EDIT_MY_CASES_BASIC_DETAILS,
    CLOSE_MY_CASE,
    RE_OPEN_MY_CASE,
    ARCHIEVE_MY_CASE,
    UNARCHIEVE_MY_CASE,
    CREATE_MEETING,
    CALL,
  } = permissionList;

  const { user } = useContext(UserContext);

  const hasReviewPermission = userHasPermission(user, PUBLISHED_CASE_REVIEW);

  const hasEditPermission = hasPermission(
    permissionArray,
    EDIT_MY_CASES_BASIC_DETAILS,
  );
  const hasClosePermission = hasPermission(permissionArray, CLOSE_MY_CASE);
  const hasReOpenPermission = hasPermission(permissionArray, RE_OPEN_MY_CASE);
  const hasArchivePermission = hasPermission(permissionArray, ARCHIEVE_MY_CASE);
  const hasUnArchivePermission = hasPermission(
    permissionArray,
    UNARCHIEVE_MY_CASE,
  );
  const hasCreateMeetingPermission = hasPermission(
    permissionArray,
    CREATE_MEETING,
  );
  const hasScheduleCallPermission = hasPermission(permissionArray, CALL);

  const closeDisclaimer = () => {
    setOpen(false);
  };

  const openDisclaimer = () => {
    setOpen(true);
  };

  return (
    <div>
      <div className="case-action-top">
        <Link
          style={{
            flex: 1,
          }}
          variant="primary"
          to={`/cases`}
          className="back-btn"
        >
          <div
            style={{
              cursor: 'pointer',
              fontSize: '15px',
            }}
            className="title"
          >
            {caseString.go_back}
          </div>
        </Link>
        <div className="case-fixed-footer-inner">
          <Row>
            <Col>
              <Row className="justify-end">
                {(caseStatusSlug === 'draft' ||
                  caseStatusSlug === 'returned' ||
                  caseStatusSlug === 'screening') &&
                hasEditPermission ? (
                  <>
                    <Col xs="auto">
                      <Button
                        as={Link}
                        to={`/cases/${slug}/edit`}
                        variant="primary"
                        uuid={uuid}
                      >
                        {caseString.edit}
                      </Button>
                    </Col>

                    <Col xs="auto">
                      <Button
                        variant="primary"
                        onClick={() => openDisclaimer()}
                      >
                        {caseString.submit}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {caseStatusSlug === 'in-review' && hasReviewPermission ? (
                  <>
                    <Col xs="auto">
                      <ActionButton
                        entityType="cases"
                        type="approved"
                        variant="primary"
                        uuid={uuid}
                      />
                    </Col>
                    <Col xs="auto">
                      <ActionButton
                        entityType="cases"
                        type="rejected"
                        variant="outline-primary"
                        uuid={uuid}
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {caseStatusSlug === 'close' ? (
                  <>
                    <Col xs="auto">
                      <ActionButton
                        entityType="cases"
                        type="archive"
                        variant="outline-danger"
                        uuid={uuid}
                      />
                    </Col>
                    {hasArchivePermission ? <></> : <></>}
                    {hasReOpenPermission ? (
                      <Col xs="auto">
                        <ActionButton
                          entityType="cases"
                          type="reopen"
                          variant="primary"
                          uuid={uuid}
                        />
                      </Col>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {caseStatusSlug === 'open' && hasScheduleCallPermission ? (
                  <Col xs="auto">{/* <CallButton /> */}</Col>
                ) : (
                  <></>
                )}

                {caseStatusSlug === 'open' && hasClosePermission ? (
                  <Col xs="auto">
                    <ActionButton
                      entityType="cases"
                      type="close"
                      variant="primary"
                      uuid={uuid}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                {caseStatusSlug === 'archive' && hasUnArchivePermission ? (
                  <Col xs="auto">
                    <ActionButton
                      entityType="cases"
                      type="unarchive"
                      variant="primary"
                      uuid={uuid}
                    />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <CaseDisclaimerModal
        open={open}
        type={'publish'}
        close={closeDisclaimer}
        saveOrPublishCase={() => {
          publishCase();
        }}
      />
    </div>
  );
};

export default CaseDetailTopAction;
