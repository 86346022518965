import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { strings } from '../../assets/strings/all';
import CommentBox from './CommentBox';
import CommentItem from './CommentItem';
import { GET_COMMENTS } from './query';
import { RoleContext } from '../../containers/Context/RoleContext';

function CommentList(props) {
  const { role } = useContext(RoleContext);
  const { common: commonString } = strings;
  const {
    entityType,
    variables,
    isReply,
    canComment,
    query = GET_COMMENTS,
    isModerationEnabled,
    setExpertComment,
    shouldPad = false,
  } = props;

  const { limit = 10 } = variables;

  const { loading, error, data, fetchMore } = useQuery(query, {
    variables,
    // fetchPolicy: 'network-only',
  });

  if (loading || error || isEmpty(data)) {
    return 'Loading';
  }

  const fetchMoreComments = (limit, offset) =>
    fetchMore({
      variables: { limit, offset },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        let commentsArray = [
          ...prev.commentList.comments,
          ...fetchMoreResult.commentList.comments,
        ];

        let commentList = {
          ...fetchMoreResult,
          commentList: {
            ...fetchMoreResult.commentList,
            comments: commentsArray,
          },
        };

        return commentList;
      },
    });

  const { commentList } = data;
  const { comments, hasMore } = commentList;

  if (comments && comments[0] && comments[0].isExpert === true) {
    if (setExpertComment) {
      setExpertComment(true);
    }
  }

  const fetchMoreCommentsAfterDelete = () => {
    fetchMoreComments(1, comments.length);
  };
  return (
    <>
      {canComment ? (
        <>
          {isModerationEnabled ? (
            <div className="muted-text muted-text-small">
              Comment will not to be visible to other until moderator approves
              it
            </div>
          ) : (
            <></>
          )}

          {role !== null ? (
            role.name !== 'Screener' && (
              <CommentBox
                {...props}
                updateData={{ query: GET_COMMENTS, variables }}
              />
            )
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      {shouldPad && (
        <div
          style={{
            height: '100px',
          }}
        />
      )}
      <div className={`comment-list-wrapper ${entityType}-replies`}>
        <div className="comment-list-inner">
          {comments.map((comment, idx) => (
            <CommentItem
              key={idx}
              {...props}
              commentData={comment}
              fetchMoreCommentsAfterDelete={fetchMoreCommentsAfterDelete}
              updateData={{ query, variables }}
            />
          ))}
        </div>
        {hasMore ? (
          <div
            onClick={() => fetchMoreComments(limit, comments.length)}
            className="view-more show-more"
          >
            {isReply
              ? commonString.view_previous_replies
              : commonString.view_more_comments}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default CommentList;
