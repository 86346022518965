import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { ACCESS_TOKEN, BASE_URL, SUBSCRIPTION_URL } from './common/constants';
import {
  clearLocalStorage,
  deleteCookie,
  getCookie,
  getCsrfToken,
  getSubdomainName,
  setCookie,
} from './common/functions';

const getHeaders = async () => {
  const token = ACCESS_TOKEN();
  let organization = getSubdomainName();
  const bearerToken = token ? `Bearer ${token}` : '';
  let csrf = await getCookie('csrf-token');
  const headers = {
    Authorization: bearerToken,
    organization,
    'csrf-token': csrf,
    'XSRF-TOKEN': csrf,
    'X-XSRF-TOKEN': csrf,
  };

  return headers;
};

const wsLink = new WebSocketLink({
  uri: SUBSCRIPTION_URL,
  options: {
    reconnect: true,
    connectionParams: async () => {
      return { headers: await getHeaders() };
    },
  },
});
const httpLink = new HttpLink({
  uri: BASE_URL,
  credentials: 'include',

  // fetchOptions: {
  //   credentials: 'include',
  // },
});

const logoutLink = onError(({ response, operation, networkError }) => {
  if (
    networkError &&
    networkError.result &&
    networkError.result.code === 'CSRF'
  ) {
    clearLocalStorage();
    deleteCookie('csrf-token');
    deleteCookie('session');
    deleteCookie('session.sig');
    window.location.replace('/');
  }

  if (
    response &&
    response.errors &&
    response.errors[0] &&
    response.errors[0].code === 'FORBIDDEN'
  ) {
    clearLocalStorage();
    deleteCookie('csrf-token');
    deleteCookie('session');
    deleteCookie('session.sig');
    window.location.replace('/');
  }
});

const authLink = setContext(async (_, { headers }) => {
  const addedHeaders = await getHeaders();
  return {
    headers: {
      ...headers,
      ...addedHeaders,
      // Cookies: document.cookie,
    },
  };
});

const completeHttplink = authLink.concat(logoutLink.concat(httpLink));

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  completeHttplink,
);

export default new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object.uuid || null,
  }),
  link,
  credentials: 'include',
});
