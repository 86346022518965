import React, { useContext } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function CountryCodeSelect(props) {
  const {
    organization: { countries },
  } = useContext(OrganizationContext);

  let selectedValue = null;
  if (countries) {
    countries.map((c) => {
      if (c.phoneCode === props.value) {
        selectedValue = c;
      }
      return true;
    });
  }

  return (
    <HsSelect
      getOptionLabel={(option) => `+${option.phoneCode} (${option.name})`}
      getOptionValue={(option) => option.phoneCode}
      placeholder="Country"
      options={countries}
      {...props}
      value={selectedValue}
    />
  );
}

export default CountryCodeSelect;
