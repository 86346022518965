import React from 'react';
import Image from 'react-bootstrap/Image';

function HsImage(props) {
  const { type } = props;
  let fallbackSrc = 'https://via.placeholder.com/150x150';
  if (type === 'user') {
    fallbackSrc = 'https://via.placeholder.com/150x150';
  }

  return (
    <Image
      fluid
      {...props}
      src={props.src ? props.src : fallbackSrc}
      onError={e => {
        const target = e.target;
        target.onError = null;
        target.src = fallbackSrc;
      }}
    />
  );
}

export default HsImage;
