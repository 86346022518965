export const strings = {
  comment: 'Comment',
  reply: 'Reply',
  share: 'Share',
  like: 'Like',
  num_liked: '{num} liked',
  num_likes: '{num} Likes',
  num_followings: '{num} Followings',
  num_comments: '{num} comments',
  num_replies: '{num} Replies',
  num_like: '{num} like',
  num_following: '{num} Following',
  num_comment: '{num} comment',
  num_reply: '{num} reply',
  say_something: 'Say something...',
  write_comment: 'Write a comment',
  save_changes: 'Save Changes',
  add_reply: 'Add a reply',
  upload_photo: 'Upload a Photo',
  upload_video: 'Upload a Video',
  attach_file: 'Attach a File',
  post: 'Post',
  posting: 'Posting',
  load_more: 'Load More',
  more: 'More',
  less: 'Less',
  post_feed: 'Post a feed',
  edit_feed: 'Edit feed',
  edit_post: 'Edit Post',
  delete_post: 'Delete Post',
  edit_comment: 'Edit Comment',
  delete_comment: 'Delete Comment',
  edit_reply: 'Edit',
  delete_reply: 'Delete',
  delete_replyy: 'Delete Reply',
  cancel: 'Cancel',
  update: 'Update',
  author: 'Author',
  num_view: '{num} view',
  num_views: '{num} views',
  write_articles: 'Write Article',
  publish_case: 'Publish Case',
  create_poll: 'Create Poll',
  microsite_post_msg: 'Anyone on this Microsite can see your posts',
  download: 'Download',
  download_media: 'Download All Media',
  fetching: 'Fetching...',
  no_preview: 'No Preview Available',
  delete_comment_confirmation: 'Are you sure you want to delete the comment?',
  share_post: 'Share Post',
  close: 'Close',
  influencer: 'Influencer',
  hide_post: 'Hide Post',
  unhide_post: 'Unhide Post',
  hide_post_string: 'Are you sure you want to hide the post',
  unhide_post_string: 'Are you sure you want to unhide the post',
  pin_post: 'Pin Post',
  unpin_post: 'Unpin Post',
  pin_post_string: 'Are you sure you want to pin the post',
  unpin_post_string: 'Are you sure you want to unpin the post',
  verifiedError:
    ' Please note you will have restricted access to the platform, till your medical licence has been verified.',
  hide_poll: 'Hide Poll',
  unhide_poll: 'Unhide Poll',
  hide_poll_string: 'Are you sure you want to hide the poll',
  unhide_poll_string: 'Are you sure you want to unhide the poll',
  pin_poll: 'Pin Poll',
  unpin_poll: 'Unpin Poll',
  pin_poll_string: 'Are you sure you want to pin the poll',
  unpin_poll_string: 'Are you sure you want to unpin the poll',
  pin_poll_tooltop: 'This poll is pinned',
  hide_poll_tooltop: 'This poll is hidden',

  pin_post_tooltop: 'This post is pinned',
  hide_post_tooltop: 'This post is hidden',

  edit: {
    post: 'Edit Post',
    poll: 'Edit Poll',
    blog: 'Edit Article',
    case: 'Edit Case',
  },
  delete: {
    post: 'Delete Post',
    poll: 'Delete Poll',
    blog: 'Delete Article',
    case: 'Delete Case',
    webinar: 'Delete Webinar',
  },
  delete_desc: {
    post: 'Are you sure you want to delete this Post?',
    poll: 'Are you sure you want to delete this Poll?',
    blog: 'Are you sure you want to delete this Article?',
    case: 'Are you sure you want to delete this Case?',
    webinar: 'Are you sure you want to delete this webinar?',
  },
  hide: {
    post: 'Hide Post',
    poll: 'Hide Poll',
    blog: 'Hide Article',
    case: 'Hide Case',
  },
  hide_desc: {
    post: 'Are you sure you want to hide this Post?',
    poll: 'Are you sure you want to hide this Poll?',
    blog: 'Are you sure you want to hide this Article?',
    case: 'Are you sure you want to hide this Case?',
  },
  unhide: {
    post: 'Unhide Post',
    poll: 'Unhide Poll',
    blog: 'Unhide Article',
    case: 'Unhide Case',
  },
  unhide_desc: {
    post: 'Are you sure you want to unhide this Post?',
    poll: 'Are you sure you want to unhide this Poll?',
    blog: 'Are you sure you want to unhide this Article?',
    case: 'Are you sure you want to unhide this Case?',
  },
  pin: {
    post: 'Pin Post',
    poll: 'Pin Poll',
    blog: 'Pin Article',
    case: 'Pin Case',
  },
  pin_desc: {
    post: 'Are you sure you want to pin this Post?',
    poll: 'Are you sure you want to pin this Poll?',
    blog: 'Are you sure you want to pin this Article?',
    case: 'Are you sure you want to pin this Case?',
  },
  unpin: {
    post: 'Unpin Post',
    poll: 'Unpin Poll',
    blog: 'Unpin Article',
    case: 'Unpin Case',
  },
  unpin_desc: {
    post: 'Are you sure you want to unpin this Post?',
    poll: 'Are you sure you want to unpin this Poll?',
    blog: 'Are you sure you want to unpin this Article?',
    case: 'Are you sure you want to unpin this Case?',
  },
  pin_tooltip: {
    post: 'This post is pinned',
    poll: 'This poll is pinned',
    blog: 'This blog is pinned',
    case: 'This case is pinned',
  },
  hide_tooltip: {
    post: 'This post is hidden',
    poll: 'This poll is hidden',
    blog: 'This blog is hidden',
    case: 'This case is hidden',
  },
  hide_comment: 'Hide Comment',
  unhide_comment: 'Unhide Comment',
  hide_comment_string: 'Are you sure you want to hide the comment',
  unhide_comment_string: 'Are you sure you want to unhide the comment',
  hide_comment_tooltop: 'This comment is hidden',
  hide_reply: 'Hide Reply',
  unhide_reply: 'Unhide Reply',
  hide_reply_string: 'Are you sure you want to hide the reply',
  unhide_reply_string: 'Are you sure you want to unhide the reply',
  hide_reply_tooltop: 'This reply is hidden',
  upload_poll: 'Upload a Poll',
  delete_poll: 'Delete Poll',
  delete_poll_confirmation: 'Are you sure you want to delete the poll?',
  delete_post_confirmation: 'Are you sure you want to delete the post?',
  edit_poll: 'Edit Poll',
  post_poll: 'Post a Poll',
  submit: 'Submit',
  no_poll: 'No Poll',
  multiselect_option: 'User can choose multiple options',
  max_options: 'Maximum Options',
  remove_poll: 'Remove Poll',
  enter_date: 'Enter expiry date',
  poll_type: 'Poll Type',
  show_results_after: 'Show Results After',
  ask_question: 'Ask a question',
  enter_choice: 'Enter Choice ',
  choice: 'Choice ',
  votes_result: ' votes • ',
  poll_create: 'Create Poll ',
  vote: 'Vote',
  no_video: 'No Video',

  post_comment: 'Post',
  post_reply: 'Post',
  reject_comment_title: 'This comment was rejected for following reason',
  edit_replyy: 'Edit Reply',
  expert: 'Expert',
  comment_disclaimer:
    'Please ensure that patient confidentiality is not being compromised.',
};
