import React, { useContext } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function SpecialitySelect(props) {
  const {
    organization: { specialities },
  } = useContext(OrganizationContext);

  return (
    <HsSelect
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.slug}
      placeholder="Start Typing for suggestions"
      options={specialities}
      {...props}
    />
  );
}

export default SpecialitySelect;
