import gql from 'graphql-tag';
import { OWNER_DETAILS } from '../Case/query';

// #FRAGMENTS
export const JOURNAL_LIST_DETAILS = gql`
  fragment JournalListDetails on Journal {
    uuid
    slug
    title
    createdAt
    caseType {
      slug
      name
    }
    caseStatus {
      slug
      name
      iconClass
    }
    createdBy {
      uuid
      fullName
    }
  }
`;

export const JOURNAL_DETAILS = gql`
  fragment JournalDetails on Journal {
    uuid
    slug
    title
    description
    attachments {
      url
    }
    links {
      url
    }
    caseType {
      slug
      name
    }
    visibilityStatus {
      slug
      name
    }
    caseStatus {
      slug
      name
      iconClass
    }
    statusLog {
      notes
    }
    createdBy {
      ...OwnerDetails
    }
    createdAt
    permissionArray
  }
  ${OWNER_DETAILS}
`;

export const JOURNAL_LIST = gql`
  fragment JournalList on JournalList {
    hasMore
    totalCount
    journals {
      ...JournalListDetails
    }
  }
  ${JOURNAL_LIST_DETAILS}
`;

export const GET_JOURNALS = gql`
  query ($offset: Int, $limit: Int, $filters: JournalFilterInput) {
    journalList: getJournals(
      offset: $offset
      limit: $limit
      filters: $filters
    ) {
      ...JournalList
    }
  }
  ${JOURNAL_LIST}
`;

export const GET_JOURNAL = gql`
  query ($uuid: String, $slug: String) {
    journalData: getJournal(slug: $slug, uuid: $uuid) {
      ...JournalDetails
    }
  }
  ${JOURNAL_DETAILS}
`;

export const GET_JOURNALS_COUNT = gql`
  query ($filters: JournalFilterInput) {
    count: getJournalsCount(filters: $filters)
  }
`;

export const CREATE_JOURNAL = gql`
  mutation ($journalData: JournalInput!) {
    journal: createJournal(journalData: $journalData) {
      journal {
        uuid
        slug
      }
      success
      message
    }
  }
`;

export const UPDATE_JOURNAL = gql`
  mutation ($journalData: JournalInput!, $uuid: String!) {
    journal: updateJournal(journalData: $journalData, uuid: $uuid) {
      journal {
        uuid
        slug
      }
      success
      message
    }
  }
`;

export const journalInput = [
  'title',
  'description',
  'visibilityStatusSlug',
  'caseTypeSlug',
  'caseStatusSlug',
  'attachments',
  'links',
  'isModerationEnabled',
];
