import gql from 'graphql-tag';

// #FRAGMENTS

export const OWNER_DETAILS = gql`
  fragment OwnerDetails on User {
    uuid
    fullName
    avatar
    username
    speciality {
      name
    }
    email
    about
    association
  }
`;

export const CASE_DETAILS = gql`
  fragment CaseDetails on Case {
    id
    uuid
    originalCaseId
    caseTitle
    race
    inquiryType
    slug
    title
    userId
    caseId
    isScreened
    isReported
    visibilityStatus {
      name
      slug
    }
    caseType {
      name
      slug
    }
    patientStage
    patientAge
    patientInitalDianosisDate
    isModerationEnabled
    commentsCount
    extraDetails {
      name
      value
      organizationCaseField {
        name
        slug
      }
    }
    caseStatus {
      name
      slug
      iconClass
    }
    caseStage {
      name
      slug
    }
    patientGender {
      name
      slug
    }
    primaryConsultants {
      ...OwnerDetails
    }
    secondaryConsultants {
      ...OwnerDetails
    }
    commentors {
      ...OwnerDetails
    }
    createdBy {
      ...OwnerDetails
    }
    journals {
      uuid
      title
      slug
      caseType {
        name
      }
    }
    attachments {
      url
      thumbnail
      fileType
      caseSubmitionType
    }
    links {
      url
    }
    permissionArray
    isFollowing
    createdAt
    statusLog {
      notes
    }
    rating
    isRecommended
  }
  ${OWNER_DETAILS}
`;

export const GET_CASE_DETAILS = gql`
  query ($slug: String) {
    caseData: getCase(slug: $slug) {
      ...CaseDetails
    }
  }
  ${CASE_DETAILS}
`;

export const CREATE_CASE = gql`
  mutation ($caseData: CaseInput!) {
    case: createCase(caseData: $caseData) {
      case {
        uuid
        slug
      }
      success
      message
    }
  }
`;

export const FOLOW_UNFOLLOW_CASE = gql`
  mutation ($uuid: String!) {
    case: followUnfollowCase(uuid: $uuid) {
      case {
        uuid
        isFollowing
      }
      success
      message
    }
  }
`;

export const UPDATE_CASE = gql`
  mutation ($caseData: CaseInput!, $uuid: String!) {
    case: updateCase(caseData: $caseData, uuid: $uuid) {
      case {
        uuid
        slug
      }
      success
      message
    }
  }
`;

export const UPDATE_ENTITY_FEEDBACK_STATUS = gql`
  mutation (
    $entityUuid: String!
    $entityType: String!
    $statusSlug: String!
    $notes: String
    $rating: Float
    $isRecommended: Boolean
    $improvementNote: String
    $teams: [Int]
    $shouldAddPanel: Boolean
  ) {
    feedback: updateEntityFeedbackStatus(
      entityUuid: $entityUuid
      entityType: $entityType
      statusSlug: $statusSlug
      notes: $notes
      rating: $rating
      isRecommended: $isRecommended
      improvementNote: $improvementNote
      teams: $teams
      shouldAddPanel: $shouldAddPanel
    ) {
      case {
        uuid
        slug
        caseStatus {
          name
          slug
          iconClass
        }
      }
      comment {
        id
        uuid
        caseId
        caseStatus {
          name
          slug
          iconClass
        }
        statusLog {
          notes
        }
      }
      journal {
        uuid
        slug
        caseStatus {
          name
          slug
          iconClass
        }
      }
      success
      message
    }
  }
`;

export const ADD_REMOVE_CASE_COMMENTOR = gql`
  mutation (
    $userUuids: [String]
    $userEmails: [String]
    $slug: String
    $uuid: String
  ) {
    commentor: addRemoveCaseCommentor(
      userUuids: $userUuids
      userEmails: $userEmails
      slug: $slug
      uuid: $uuid
    ) {
      success
      message
      case {
        uuid
        commentors {
          ...OwnerDetails
        }
      }
    }
  }
  ${OWNER_DETAILS}
`;

export const ADD_REMOVE_CASE_SEONDARY_CONSULTATNTS = gql`
  mutation (
    $userUuids: [String]
    $userEmails: [String]
    $slug: String
    $uuid: String
  ) {
    users: addRemoveCaseSecondaryConsultants(
      userUuids: $userUuids
      userEmails: $userEmails
      slug: $slug
      uuid: $uuid
    ) {
      success
      message
      case {
        uuid
        secondaryConsultants {
          ...OwnerDetails
        }
      }
    }
  }
  ${OWNER_DETAILS}
`;

export const ADD_REMOVE_CASE_JOURNALS = gql`
  mutation ($journalUuids: [String], $slug: String, $uuid: String) {
    journals: addRemoveCaseJournals(
      journalUuids: $journalUuids
      slug: $slug
      uuid: $uuid
    ) {
      success
      message
      case {
        uuid
        journals {
          uuid
          title
          slug
        }
      }
    }
  }
`;

export const UPDATE_CASE_FIELDS = gql`
  mutation ($caseFields: [CaseFieldInput]) {
    caseFields: updateCaseFields(caseFields: $caseFields) {
      uuid
      caseFields {
        uuid
        sortOrder
        name
        slug
        type
        isRequired
        fieldType {
          name
          slug
        }
      }
      sharingFields {
        uuid
        sortOrder
        name
        slug
        type
        isRequired
        fieldType {
          name
          slug
        }
      }
    }
  }
`;

export const UPDATE_CASE_VIEW = gql`
  mutation ($uuid: String) {
    updateView: updateCaseView(uuid: $uuid) {
      case {
        uuid
        rating
        isRecommended
      }
      success
      message
    }
  }
`;
export const GET_REASONS = gql`
  query {
    getReturnReasons {
      id
      reason
    }
  }
`;

export const GET_CASE_RETURN_REASONS = gql`
  query ($caseId: Int) {
    getCaseReturnReasons(caseId: $caseId) {
      note
      reason {
        reason
      }
    }
  }
`;

export const GET_SEARCH_CASE = gql`
  query ($text: String!) {
    searchPublicCases(text: $text) {
      id
      caseTitle
      title
      slug
    }
  }
`;
export const DOWNLOAD_CASE_ANALYTICS = gql`
  mutation ($startDate: String, $endDate: String) {
    getCasesAnalyticsDownload(startDate: $startDate, endDate: $endDate) {
      url
    }
  }
`;
export const caseInput = [
  'title',
  'caseTitle',
  'race',
  'inquiryType',
  'caseId',
  'visibilityStatusSlug',
  'caseTypeSlug',
  'caseStatusSlug',
  'patientGenderSlug',
  'patientAge',
  'patientInitalDianosisDate',
  'patientStage',
  'isModerationEnabled',
  'extraDetails',
  'journalUuids',
  'userUuids',
  'userEmails',
  'attachments',
  'links',
];

export const extraDetailsInput = ['name', 'value'];
