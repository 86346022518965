import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HsCaseStatusIcon from '../Common/HsCaseStatusIcon';
import HsCaseType from '../Common/HsCaseType';

function CaseCardLoader(props) {
  return (
    <Card>
      <Card.Body className="shimmer-loader-wrapper">
        <div className="mb-2">
          <HsCaseStatusIcon loading={true} />
          <HsCaseType loading={true} />
        </div>
        <div className="page-title mb-3">
          <div className="shimmer-loader" />
          <div className="shimmer-loader" />
        </div>
        <Row>
          <Col xs={4}>
            <div className="shimmer-loader"></div>
          </Col>
          <Col xs={4}>
            <div className="shimmer-loader"></div>
          </Col>
          <Col xs={4}>
            <div className="shimmer-loader"></div>
          </Col>
        </Row>

        <div className="case-card-bottom mt-4">
          <Row>
            <Col xs={6}>
              <div className="shimmer-loader"></div>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col xs={2}></Col>
            <Col xs={4}>
              <div className="shimmer-loader"></div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CaseCardLoader;
