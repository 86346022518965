import React, { useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { strings } from '../../assets/strings/all';
import AddCaseUserModal from './AddCaseUserModal';

function AddCaseUserButton(props) {
  const { cases } = strings;
  const { uuid, secondaryConsultants, canAddNewUser } = props;
  const [show, setShow] = useState();

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Button variant="outline-primary" onClick={() => toggleShow()} size="sm">
        {cases.add_members}
      </Button>

      {show ? (
        <AddCaseUserModal
          type="archive"
          uuid={uuid}
          secondaryConsultants={secondaryConsultants}
          showNotes={true}
          show={show}
          canAddNewUser={canAddNewUser}
          onClose={() => toggleShow()}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default AddCaseUserButton;
