import React from 'react';
import { components } from 'react-select';
const MultiValueContainer = (props) => {
  const newProps = {
    ...props,
    innerProps: {
      ...props.innerProps,
      className: `${props.innerProps.className} hs-multi-value-container`,
    },
  };
  return <components.MultiValueContainer {...newProps} />;
};

export default MultiValueContainer;
