import { isEmpty, isEqual } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useDropzone } from 'react-dropzone';
import { strings } from '../../assets/strings/all';
import { UPLOAD_URL } from '../../common/constants';
import { getFileTypeFromUrl } from '../CaseForm/components/functions';
import { generateUUID, getCookie } from '../../common/functions';
import ListGroup from 'react-bootstrap/ListGroup';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';

function HsDropzone(props) {
  const {
    data: { attachments = [] },
    removedFiles,
    type,
    addToRemovedFiles,
    submitionType = 'NEW',
    hideText = false,
    attachedId = '',
  } = props;
  const hsFileObj = {};

  attachments &&
    attachments.map((at) => {
      hsFileObj[at.url] = {
        state: 'uploaded',
        file: {
          preview: at.url,
          url: at.url,
        },
        percentage: 100,
        removed: 0,
      };
      return true;
    });
  const { setFeedback } = useContext(FeedbackContext);
  const [hsFiles, setHsFiles] = useState(hsFileObj);
  // useEffect(() => {
  //   const hsFileObj = {};
  //   attachments &&
  //     attachments.map((at) => {
  //       hsFileObj[at.url] = {
  //         state: 'uploaded',
  //         file: {
  //           preview: at.url,
  //           url: at.url,
  //         },
  //         percentage: 100,
  //         removed: 0,
  //       };
  //       return true;
  //     });

  //   if (!isEqual(hsFileObj, hsFiles)) {

  //     }

  //   setHsFiles(hsFileObj);
  // }, [attachments]);

  const uploadFiles = (fileArray) => {
    let files = [...fileArray];
    let filesObj = {};
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      file.uuid = generateUUID();
      const fileType = getFileTypeFromUrl(file.name);

      let fileObj = {
        state: 'queued',
        file,
        fileType,
        percentage: 0,
        removed: 0,
      };
      filesObj[file.uuid] = fileObj;
    }

    setHsFiles({ ...hsFiles, ...filesObj });
  };

  useEffect(() => {
    const { attachments = [] } = props.data;
    if (attachments.length === 0) {
      setHsFiles({});
    }
  }, [props.data]);

  const updateFileObject = useCallback(
    (file) => {
      const fileKey = Object.keys(file)[0];

      if (removedFiles.indexOf(fileKey) > -1) return;
      setHsFiles((hsFiles) => {
        return { ...hsFiles, ...file };
      });
      props.updateFileObj({ ...hsFiles, ...file });
    },
    [hsFiles, props, removedFiles],
  );

  const sendRequest = useCallback(
    async (file) => {
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.upload.addEventListener('progress', async (event) => {
          if (event.lengthComputable) {
            const copy = {
              [file.uuid]: {
                state: 'uploading',
                file,
                percentage: (event.loaded / event.total) * 100,
                // removed: copy[file.name].removed,
              },
            };
            updateFileObject(copy);
          }
        });
        req.onreadystatechange = async () => {
          if (req.readyState === XMLHttpRequest.DONE) {
            const response = JSON.parse(req.response);

            if (response.status !== 200) {
              setFeedback({ message: response.message, variant: 'danger' });
              addToRemovedFiles(file.uuid);
              reject(req.response);
            } else {
              const copy = {
                [file.uuid]: {
                  state: 'uploaded',
                  percentage: 100,
                  file,
                  url: response.path,
                  // removed: copy[file.name].removed,
                },
              };
              updateFileObject(copy);
              resolve(response);
            }
          }
        };
        req.upload.addEventListener('error', (event) => {
          const copy = { [file.uuid]: { state: 'error', percentage: 0, file } };
          updateFileObject(copy);
          reject(req.response);
        });

        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('submitionType', submitionType);
        req.open('POST', UPLOAD_URL);
        req.setRequestHeader('csrf-token', getCookie(`csrf-token`));
        req.withCredentials = true;
        // req.setRequestHeader('x-access-token', UPLOAD_TOKEN());
        req.send(formData);
      });
    },
    [updateFileObject],
  );

  const uploadFile = useCallback(
    async (uploadfileObj) => {
      const { file } = { ...uploadfileObj };
      if (uploadfileObj.state !== 'queued') {
        return;
      }
      file.preview = URL.createObjectURL(file);
      try {
        await sendRequest(file);
      } catch (error) {
        console.log('error : ', error);
      }

      return;
    },
    [sendRequest],
  );

  const onDrop = (acceptedFiles) => {
    if (props.maxFiles === 1) {
      setHsFiles({});
    }
    uploadFiles(acceptedFiles);
    // Do something with the files
  };

  useEffect(() => {
    const newHsFiles = { ...hsFiles };

    removedFiles.forEach((fileKey) => {
      delete newHsFiles[fileKey];
    });

    if (!isEqual(hsFiles, newHsFiles)) {
      setHsFiles(newHsFiles);
      props.updateFileObj(newHsFiles);
    }
  }, [removedFiles, hsFiles, props]);

  useEffect(() => {
    const processFiles = async () => {
      const keys = Object.keys(hsFiles);

      for (let i = 0; i < keys.length; i++) {
        if (hsFiles[keys[i]].state === 'queued') {
          await uploadFile(hsFiles[keys[i]]);
        }
      }
    };
    processFiles();
  }, [hsFiles, uploadFile]);

  const dropZoneConfig = {
    onDrop,
    // maxSize: 500,
    validator: (file) => {
      const isJFIF = file.name.toLowerCase().endsWith('.jfif');
      if (isJFIF) {
        return {
          code: 'file-invalid-type',
          message: 'JFIF files are not supported.',
        };
      }
      return null;
    },
  };

  if (props.maxFiles) {
    dropZoneConfig.maxFiles = props.maxFiles;
  }

  if (props.acceptedFileFormats) {
    dropZoneConfig.accept = props.acceptedFileFormats;
  }
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone(dropZoneConfig);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <ListGroup.Item key={file.path} variant="danger">
      {file.path} - {file.size} bytes
      {errors.map((e) => (
        <div className="mt-2" variant="danger" key={e.code}>
          {e.message}
        </div>
      ))}
    </ListGroup.Item>
  ));

  const { common: commonString } = strings;

  return (
    <>
      <div
        {...getRootProps()}
        className={`${
          type === 'small'
            ? 'small-dropzone-wrapper'
            : type === 'profilePicture'
            ? ''
            : 'dropzone-wrapper'
        } ${isDragActive ? 'active' : ''}`}
      >
        <input id={'attachment-comment' + attachedId} {...getInputProps()} />

        {type === 'small' ? (
          <>
            {!hideText && (
              <>
                <i className="icon-ico-upload-file" /> Add Attachment
              </>
            )}
          </>
        ) : type === 'profilePicture' ? (
          <Button>Upload</Button>
        ) : (
          <>
            <Button className="dropdzone-title-bar" variant="outline-primary">
              <Row className="row-small">
                <Col xs="auto">
                  <Image src="/api/static/upload.svg" width={14} />
                </Col>
                <Col>
                  <span>{commonString.dropzone_title}</span>
                </Col>
              </Row>
            </Button>
            <div className="dropdzone-subtitle-bar">
              <div>{commonString.dropzone_subtitle}</div>
              <div>{commonString.dropzone_subtitle_help}</div>
            </div>

            <div className="dropzone-disclaimer">
              <div> {commonString.dropzone_disclaimer} </div>
            </div>
          </>
        )}
      </div>
      {!isEmpty(fileRejectionItems) ? (
        <div className="alert">
          <ListGroup>{fileRejectionItems}</ListGroup>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default HsDropzone;
