import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useHistory } from 'react-router-dom';
import HsImage from '../../components/Common/HsImage';
import ListGroup from 'react-bootstrap/ListGroup';
import { UserContext } from '../../containers/Context/UserContext';
import { OrganizationContext } from '../../containers/Context/OrganizationContext';
import { forceRelative } from '../../common/functions';

const UserProfileDropdown = (props) => {
  const { user, logout } = useContext(UserContext);
  // const date = formattedDateInAgo(user.created_at);
  const {
    speciality,
    // profession,
    organizations,
  } = user;
  const { organization } = useContext(OrganizationContext);
  const history = useHistory();
  return (
    <>
      <div className="mb-2 user-detail-card">
        <Row
          onClick={() => {
            history.push('/settings');
          }}
          className="row-small"
        >
          <Col xs="auto">
            <div className=" user-image">
              <HsImage
                src={forceRelative(user.avatar)}
                // title={fullName}
                className="img-responsive avatarImage avatarLgImage roundedImage"
                type="user"
              />
            </div>
          </Col>
          <Col
            onClick={() => {
              history.push('/settings');
            }}
            className="home-profile-padding"
          >
            <div>
              <Link
                className="analyse profile-title-font-view"
                data-action-type="1 11"
                data-resource-type="clients"
                data-resource-id={user.uuid}
                to={`/settings`}
              >
                <span className="profile-fullname-font">{user.fullName}</span>
              </Link>
            </div>
            <div className="home-profile-font">
              {/* {profession && (
                <>
                  <span className="">{profession.name}</span>
                </>
              )} */}
              {speciality && (
                <>
                  <span className="seperator">&bull;</span>
                  <span className=" ">{speciality.name}</span>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>

      {organizations && organizations.length > 1 ? (
        <div className="switch-org-block">
          <div className="switch-org-title">{'Switch'}</div>
          <ListGroup className="header-dropdown-list">
            {organizations.map((org) => {
              // if (org.slug === organization.slug) {
              //   return '';
              // }
              return (
                <ListGroup.Item
                  className="organization-list-item"
                  as={'a'}
                  key={org.slug}
                  href={org.url}
                >
                  <Row>
                    <Col xs={2}>
                      <div className="squareWrapper">
                        <HsImage
                          src={org.icon}
                          className="img-responsive squareContent"
                        />
                      </div>
                    </Col>
                    <Col xs={10}>{org.name}</Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      ) : (
        <></>
      )}

      <ListGroup className="header-dropdown-list">
        <ListGroup.Item
          as={Link}
          id="search-icon-link"
          className="analyse article-title-font-view"
          data-action-type="1 11"
          data-resource-type="profile"
          to={`/settings`}
        >
          {'Settings'}
        </ListGroup.Item>
        <ListGroup.Item
          as={'a'}
          href="#!"
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          {'Sign Out'}
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default UserProfileDropdown;
