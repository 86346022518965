import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { UserContext } from '../Context/UserContext';
import NotificationSetting from '../../components/Profile/NotificationSetting';
import CaseTemplateManger from '../../components/Profile/CaseTemplateManger';
import ChangePassword from '../../components/Profile/ChangePassword';
import EditProfile from '../../components/Profile/EditProfile';
import ProfileLeftSide from '../../components/Profile/ProfileLeftSide';
import SetPassword from '../../components/Profile/SetPassword';
import ViewProfile from '../../components/Profile/ViewProfile';
import TermsAndService from '../../components/Profile/TermsAndService';
import PrivacyPolicy from '../../components/Profile/privacyPolicy';

function Profile(props) {
  const { user } = useContext(UserContext);
  const { viewType } = props;

  const renderChildView = (props) => {
    let childView = null;
    childView = {
      editProfile: EditProfile,
      policy: PrivacyPolicy,
      terms: TermsAndService,
      profile: ViewProfile,
      changePassword: ChangePassword,
      setPassword: SetPassword,
      notificationSettings: NotificationSetting,
      caseTemplateManger: CaseTemplateManger,
    }[props.view];

    if (!childView) {
      childView = null;
    }

    return childView;
  };

  const ChildView = renderChildView(props);
  const hideLeftSide =
    ['completeProfile', 'setPassword'].indexOf(viewType) > -1;

  return (
    <Row className="hs-main-content">
      {!hideLeftSide ? (
        <Col md={3}>
          <ProfileLeftSide />
        </Col>
      ) : (
        <></>
      )}
      <Col md={{ span: hideLeftSide ? 8 : 9, offset: hideLeftSide ? 2 : 0 }}>
        <Card className="auth-form-card">
          <Card.Body>
            <ChildView {...props} user={user} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Profile;
