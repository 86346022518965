import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useState } from 'react';
import { GET_USER_ROLE } from '../Auth/query';
import { RoleContext } from './RoleContext';

const RoleProvider = (props) => {
  const [role, setRole] = useState(null);
  const { data, loading } = useQuery(GET_USER_ROLE);
  useEffect(() => {
    if (role === null && !loading) {
      const { getRole } = data;
      setRole({
        ...getRole,
      });
    }
  }, [data, loading]);
  if (loading || role === null) return <div>Loading...</div>;

  return (
    <>
      <RoleContext.Provider value={{ role, setRole }}>
        {props.children}
      </RoleContext.Provider>
    </>
  );
};
export default RoleProvider;
