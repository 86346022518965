import { format, formatDistance, formatRelative, parseISO } from 'date-fns';

export const formatCommentDate = (date) => {
  return formatDistance(parseISO(date), new Date(), { addSuffix: true });
};

export const formatPublishedAgoDate = (date) => {
  return formatDistance(parseISO(date), new Date(), { addSuffix: true });
};

export const formatPublishDateFull = (date) => {
  return format(parseISO(date), 'dd/MM/yyyy hh:mm a');
};

export const formatLikeCalendar = (date) => {
  return formatRelative(parseISO(date), new Date());
};

export const formatDiagnosisDate = (date) => {
  return format(parseISO(date), 'dd/MM/yyyy');
};
