export const strings = {
  articles: 'Articles',
  publish: 'Publish',
  like: 'liked',
  src: 'Source',
  speciality_placeholder: 'Choose Speciality',
  all_article: 'All Articles',
  all_specialities: 'All Specialities',
  my_article: 'My Articles',
  see_all_articles: 'See all {num} Articles',
  saved_article: 'Saved Articles',
  have_not_written_article: "Haven't written any Articles yet?",
  write_article: 'Write an Article',
  new_article: 'New Article',
  save_as_draft: 'Save As Draft',
  articles_by: 'Articles By {name}',
  cover_image: 'Cover Image',
  article_title: 'Article Title',
  brief: 'Brief',
  optional: 'Optional',
  tags: 'Tags (Add tags to help us show you relevant search results)',
  add_mention: 'Add Mention',
  source: 'Source',
  search_placeholder: 'Search Articles',
  title_placeholder: 'Enter title',
  brief_placeholder: 'Write here...',
  tags_placeholder: 'Add tags',
  source_placeholder: 'Paste URL link here',
  time_taken: 'Time Taken To Read',
  time_placeholder: 'Enter time in minutes',
  speciality: 'Speciality',
  add_tag: 'Add Tag',
  mentions: 'Mentions',
  mention_people: 'Mentioned HCPs : ',
  errors: {
    cover_required: 'You need to upload article cover image',
    title_required: 'Article Title is required',
    title_min_length: 'Article title has to be min 3 characters',
    title_max_length: 'Article title cannot be more than 250 characters',
    brief_required: 'Article Brief is required',
    brief_min_length: 'Article Brief has to be min 3 characters',
    brief_max_length: 'Article Brief cannot be more than 250 characters',
    duration_number: 'Time should always be a number',
    duration_min: 'Time should be greater then zero',
    duration_max: 'Time should not be greater then 1000',
    duration_negative: 'Time should be positive integer',
    source_url: 'Source should be valid URL',
    source_max_limit: 'Source cannot be more than 300 characters',
    description_required: 'Article description is required',
    description_min_length: 'Article description has to be min 3 characters',
    description_max_length:
      'Article description cannot be more than 2500 characters',
    speciality_id_required: 'Please choose a speciality',
    cover_uploading: 'Cover is being uploaded',
    age_max: 'Age should not be greater than 125',
  },
  time_to_read: '{time} min read',
  published_ago: 'Created {date}',
  edit_article: 'Edit Article',
  edit_draft: 'Edit Draft',
  delete_draft: 'Delete Draft',
  discard_changes: 'Discard Changes',
  delete_article: 'Delete Article',
  draft: 'Drafts',
  published: 'Published',
  published_on: 'Published on {date}',
  edit: 'Edit',
  cancel: 'Cancel',
  delete_changes_confirmation: 'Are you sure you want to delete the draft?',
  saving_changes_confirmation:
    'Are you sure you want to go back without saving changes?',
  saving_changes_confirmation_desc:
    'You have unsaved changes. You will lose all recent changes and this action cannot be reversed',
  last_updated: 'Last updated on {date}',
  no_blog: 'No Blog',
};
