import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import HsTabFilterButton from './HsTabFilterButton';

function HsButtonToolbar(props) {
  const { filters, updateFilter, variables } = props;
  return (
    <ButtonToolbar aria-label="Event Type" className="event-type">
      <ButtonGroup className="mr-2" aria-label="All Events">
        {filters.map((filter) => (
          <HsTabFilterButton
            key={`tab-filter-button-${filter.slug}`}
            variant={
              variables.filters.caseStatusSlug === filter.slug
                ? 'primary'
                : 'outline-primary'
            }
            {...props}
            text={filter.name}
            variables={{
              isScreened: filter.slug !== 'pending',
              ...props.variables,
              filters: {
                ...props.variables.filters,
                caseStatusSlug: filter.slug,
              },
            }}
            onClick={() => updateFilter({ caseStatusSlug: filter.slug })}
          />
        ))}
      </ButtonGroup>
    </ButtonToolbar>
  );
}

export default HsButtonToolbar;
