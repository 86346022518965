import { useQuery } from '@apollo/client';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { GET_CASE_RETURN_REASONS } from '../query';

const CaseReturnReasons = (props) => {
  console.log('case data is', props.data);
  const { loading, data } = useQuery(GET_CASE_RETURN_REASONS, {
    variables: {
      caseId: props.data.id,
    },
  });
  if (loading)
    return (
      <Card>
        <Card.Body>
          <div>Loading...</div>
        </Card.Body>
      </Card>
    );
  return (
    <Container>
      {data.getCaseReturnReasons.map((request) => {
        return (
          request &&
          request.reason && (
            <>
              <Card>
                <Card.Body>
                  <Row>{request.note}</Row>
                  <Row
                    style={{
                      fontSize: '10px',
                      flexDirection: 'row-reverse',
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {request.reason.reason}
                  </Row>
                </Card.Body>
              </Card>
            </>
          )
        );
      })}
    </Container>
  );
};

export default CaseReturnReasons;
