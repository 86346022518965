import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_PRIVACY_TERMS,
  GET_TERMS_AND_CONDITIONS,
} from '../../containers/Auth/query';
import HtmlRenderer from '../Common/HtmlRenderer/HtmlRenderer';

const TermsAndService = () => {
  const { data, loading } = useQuery(GET_PRIVACY_TERMS);
  if (loading) return <div>Loading...</div>;
  return (
    <div
      style={{
        lineHeight: '2rem',
      }}
    >
      <HtmlRenderer content={data.get_tnc.value} />
    </div>
  );
};
export default TermsAndService;
