import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

function JournalFormBottomAction(props) {
  const {
    saveOrPublishJournal,
    journalData: { isModerationEnabled = false },
    errors,
    loading,
  } = props;

  const isValid = isEmpty(errors);
  const isDisabled = !isValid || loading;

  return (
    <div className="case-fixed-footer">
      <div className="case-fixed-footer-wrapper">
        <Button
          variant="primary"
          as={Link}
          to={`/cases`}
          className="back-btn no-br"
        >
          <span className="icon-ico-back" />
        </Button>
        <div className="case-fixed-footer-inner">
          <Row>
            <Col>
              <Row className="justify-end">
                <Col xs="auto">
                  <Button
                    variant="outline-primary"
                    disabled={isDisabled}
                    onClick={() => saveOrPublishJournal('draft')}
                  >
                    Save As Draft
                  </Button>
                </Col>
                {isModerationEnabled ? (
                  <Col xs="auto">
                    <Button
                      disabled={isDisabled}
                      variant="primary"
                      onClick={() => saveOrPublishJournal('in-review')}
                    >
                      Request to publish
                    </Button>
                  </Col>
                ) : (
                  <Col xs="auto">
                    <Button
                      disabled={isDisabled}
                      variant="primary"
                      onClick={() => saveOrPublishJournal('publish')}
                    >
                      Publish
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default JournalFormBottomAction;
