import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { strings } from '../../assets/strings/all';
import { UserContext } from '../../containers/Context/UserContext';
import CaseListWrapper from '../CaseList/CaseListWrapper';
import { GET_CASES } from '../CaseList/query';
import AddCaseButton from '../Common/ActionButton/AddCaseButton';
import AlertBar from '../Common/AlertBar';
import TabHeaderContent from '../Common/TabHeaderContent';
import ExpertPanel from './ExpertPanel';
import CaseSearchComponent from '../Form/autocomplete/caseSearch';
import { userHasPermission } from '../../common/functions';
import permissionList from '../../common/permissions';


function DashboardInner(props) {
  const {
    GET_ANALYTICS,
  } = permissionList;
  const { cases, users } = strings;
  const { user } = useContext(UserContext);
  const canGetAnalytics = userHasPermission(user, GET_ANALYTICS);

  // console.log('user', user);
  return (
    <Row>
      <Col md={12}>
        {!user.isLicenseVerified && (
          <AlertBar message={users.unverified_status} />
        )}
        <ExpertPanel />
        {
          canGetAnalytics ? <></>
            :
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title as="h6">{cases.case_title}</Card.Title>
                  </Col>
                  <Col xs="auto">
                    <AddCaseButton />
                  </Col>
                </Row>
                <Tab.Container defaultActiveKey={'myCases'}>
                  <Row className="tab-inner">
                    <Col>
                      <Nav variant="pills" className="flex-row">
                        <>
                          {/* <Nav.Item>
                            <Nav.Link eventKey="followingCases">
                              <TabHeaderContent
                                text={cases.following_cases}
                                variables={{ filters: { filterType: 1 } }}
                              />
                            </Nav.Link>
                          </Nav.Item> */}
                          <Nav.Item>
                            <Nav.Link eventKey="myCases">
                              <TabHeaderContent
                                text={cases.my_cases}
                                variables={{ filters: { filterType: 2 } }}
                              />
                            </Nav.Link>
                          </Nav.Item>
                        </>
                        <Nav.Item>
                          <Nav.Link eventKey="allCases">
                            <TabHeaderContent text={cases.all_case} />
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <Tab.Content>
                    <Tab.Pane
                      eventKey="allCases"
                      unmountOnExit={true}
                      mountOnEnter={true}
                    >
                      <CaseListWrapper
                        type="allCases"
                        query={GET_CASES}
                        variables={{ filters: { caseStatusSlug: 'open' } }}
                      />
                    </Tab.Pane>
                    {
                      <>
                        {/* <Tab.Pane
                        eventKey="followingCases"
                        unmountOnExit={true}
                        mountOnEnter={true}
                      >
                        
                        <CaseListWrapper
                          type="followingCases"
                          query={GET_CASES}
                          variables={{ filters: { filterType: 1 } }}
                        />
                      </Tab.Pane> */}
                        <Tab.Pane
                          eventKey="myCases"
                          unmountOnExit={true}
                          mountOnEnter={true}
                        >
                          <CaseListWrapper
                            type="myCases"
                            showButton={'edit'}
                            query={GET_CASES}
                            variables={{
                              filters: {
                                filterType: 2,
                                caseStatusSlug: 'published',
                              },
                            }}
                          />
                        </Tab.Pane>
                      </>
                    }
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
        }
      </Col>
    </Row>
  );
}

export default DashboardInner;
