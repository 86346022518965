import React from 'react';
import { Switch } from 'react-router-dom';
import ProfileWrapper from '../Profile/ProfileWrapper';
import {
  AuthenticatedRoute,
  PasswordResetRoute,
  ProfileCompletionRoute,
} from './AuthenticatedRoute';

export default function ProfileRoutes(props) {
  const { match } = props;
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={`${match.path}/`}
        component={(props) => (
          <ProfileWrapper
            view="profile"
            viewType="profile"
            active="profile"
            {...props}
          />
        )}
      />

      <AuthenticatedRoute
        exact
        path={`${match.path}/case-template-manager`}
        component={(props) => (
          <ProfileWrapper
            view="profile"
            viewType="caseTemplate"
            active="profile"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/terms`}
        component={(props) => (
          <ProfileWrapper
            view="terms"
            viewType="terms"
            active="terms"
            {...props}
          />
        )}
      />
      
      <AuthenticatedRoute
        exact
        path={`${match.path}/policy`}
        component={(props) => (
          <ProfileWrapper
            view="policy"
            viewType="policy"
            active="policy"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/change-password`}
        component={(props) => (
          <ProfileWrapper
            view="changePassword"
            viewType="changePassword"
            active="changePassword"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/notifications`}
        component={(props) => (
          <ProfileWrapper
            view="profile"
            viewType="profile"
            active="profile"
            {...props}
          />
        )}
      />

      <AuthenticatedRoute
        exact
        path={`${match.path}/edit-profile`}
        component={(props) => (
          <ProfileWrapper
            view="editProfile"
            viewType="editProfile"
            active="editProfile"
            {...props}
          />
        )}
      />

      <ProfileCompletionRoute
        exact
        path={`${match.path}/:user_slug/complete-profile`}
        component={(props) => (
          <ProfileWrapper
            breadcrumb={true}
            view="editProfile"
            viewType="completeProfile"
            active="completeProfile"
            {...props}
          />
        )}
      />
      <PasswordResetRoute
        exact
        path={`${match.path}/:user_slug/set-password`}
        component={(props) => (
          <ProfileWrapper
            breadcrumb={true}
            view="setPassword"
            viewType="setPassword"
            active="profile"
            {...props}
          />
        )}
      />
    </Switch>
  );
}
