export const strings = {
  password: 'Password',
  current_password: 'Current password',
  new_password: 'New password',
  confirm_password: 'Confirm password',
  save: 'Save',
  update_password: 'Update Password',
  cancel: 'Cancel',

  no_profile: 'No Users Found',
  no_cases: 'No Cases/Questions Found',
  my_profile: 'My Profile',
  my_account: 'My Account',
  profile: 'Profile',
  settings: 'Settings',
  case_template_setup: 'Case Template Setup',
  notifications: 'Notifications',
  email_address: 'Email Address',
  title: 'Title',
  full_name: 'Full Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  phone_code: 'Phone Code',
  phone_number: 'Phone Number',
  license_id: 'License Id',
  license_id_optional: 'License Id (Optional)',
  license_status: 'License Status',
  speciality: 'Specialty',
  profession: 'Profession',

  notification_settings: 'Notification Settings',
  sms_alerts: 'SMS Alerts',
  email_alerts: 'Email Alerts',
  push_notifications: 'Push notifications (In app)',
  reminder_alerts: 'Reminder Alerts',
  profile_setup_page_title: 'Profile Setup',
  profile_setup_title: 'My Profile',
  profile_setup_subtitle: 'Almost there. Complete your profile.',
  designation: 'Designation',
  enter_designation: 'Enter Designation',
  about_me: 'About Me',
  enter_about_me: 'Write something about you',
  choose_area_interest: 'Choose your area of interests',
  your_interests: 'Your Interests',
  retype_password: 'Retype Password',
  enter_current_password: 'Enter Current Password',
  enter_new_password: 'Enter New Password',
  enter_retype_password: 'Enter Retype Password',
  change_password_title: 'Change Password',
  change_password: 'Change Password',
  change_password_description:
    'Changing password will log you out form all devices and you will have to relogin',
  set_password: 'Set Password',
  set_password_title: 'Set Password',
  set_password_description:
    'You are seeing this screen since you are logging in for the first time.',
  errors: {
    current_password_required: 'Enter your current password',
    new_password_required: 'Enter password you want to set now',
    retype_password_required: 'Retype password you want to set',
    retype_password_mismatch: 'New password and confirm password should match',
    password_length: 'Password should have more than 8 characters',
    new_password_same: 'New password should not be same as current password',
    same_phone_number:
      'New Phone Number should not be same as current Phone Number',
    no_phone_number: 'Phone Number cannot be empty',
    no_phone_code: 'Phone Code cannot be empty',
    invalid_phone_number: 'Enter a valid phone number',
  },
  about: 'About',
  about_optional: 'About (Optional)',
  contact_details: 'Contact Details',
  enter_otp: 'Enter OTP',
  change_number_title: 'Change Phone Number',
  submit: 'Submit',
  otp_sent_to_email: 'An Email is sent to your email address. Enter the OTP',
  phone_verification_code: 'Phone Verification Code',
  send_verification_code: 'Send Verification Code',
  verification_helper_text:
    'Verification code sent to your phone number and it will be valid for 15 minutes only!',
  email_verification_helper_text:
    'Verification link has been sent to your email. Click on the link to verify your email',
  verify: 'Verify',
  edit: 'Edit',
  otp_countdown: 'Resend Code ({num})',
  send_verification_link: 'Send Verification Link',
  association: 'Affiliation',
  association_optional: 'Affiliation (Optional)',
  license_file: 'License File',
  country: 'Country',
};
