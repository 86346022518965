import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

class HsLoader extends Component {
  render() {
    return (
      <div
        className={`loading ${
          this.props.type === 'initialize' ? 'initialize-loading' : ''
        }`}
      >
        <Spinner animation='grow' />
      </div>
    );
  }
}

export default HsLoader;
