import { isEmpty, findIndex } from 'lodash';

export const verifyCaseInputData = (caseData, fieldArray) => {
  const errors = {};
  if (caseData.inquiryType === undefined || caseData.inquiryType === '') {
    errors['inquiryType'] = 'Type is Required';
  }
  if (
    caseData.caseTitle === undefined ||
    (caseData.caseTitle && caseData.caseTitle.length < 8)
  ) {
    errors['caseTitle'] = 'Case title has to be 8 characters or longer';
  }
  console.log('from function errors ,', errors);
  Object.keys(fieldArray).forEach((field) => {
    const fieldData = fieldArray[field];
    const { type } = fieldData;

    let caseInputData = caseData[field];

    if (type === 'extra-details') {
      const caseInputDataIndex = findIndex(caseData.extraDetails, [
        'name',
        field,
      ]);

      if (caseInputDataIndex > -1)
        caseInputData = caseData['extraDetails'][caseInputDataIndex].value;
    }

    let isValidInput = !isEmpty(caseInputData);

    if (typeof caseInputData === 'string') {
      caseInputData = caseInputData.trim();
    } else if (typeof caseInputData === 'number') {
      isValidInput = caseInputData > 0;
    }

    delete errors[field];
    if (fieldData.isRequired && !isValidInput) {
      const error = `${fieldData.name} is Required`;
      errors[field] = error;
    }
  });
  if (caseData.inquiryType.name === 'Case') {
    if (!caseData.race) {
      errors['race'] = 'Race is required';
    }
    if (
      caseData.patientAge === null ||
      caseData.patientAge === undefined ||
      caseData.patientAge === ''
    ) {
      errors['patientAge'] = 'Age is required';
    }
  }
  return errors;
};
