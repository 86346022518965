import React from 'react';
import { components } from 'react-select';

const IndicatorsContainer = (props) => {
  return (
    components.IndicatorsContainer && (
      <components.IndicatorsContainer {...props} className="indicator-wrapper">
        {components.DropdownIndicator && (
          <components.DropdownIndicator {...props} className="search-icon">
            {/* <span className="icon-ico-dropdown" /> */}
            <img
              width={18}
              decoding="async"
              loading="lazy"
              src="/api/static/dropdown.svg"
            />
          </components.DropdownIndicator>
        )}
      </components.IndicatorsContainer>
    )
  );
};

export default IndicatorsContainer;
