import React from 'react';
import { strings } from '../../../assets/strings/all';
import HsCaseSection from '../../Common/HsCaseSection';

function WebLinkWrapper(props) {
  const { data } = props;
  const { links } = data;
  const { common } = strings;
  return links.length > 0 ? (
    <HsCaseSection title={common.web_links}>
      <ul className="web-link-list">
        {links.map((link) => (
          <li className="web-link-list-item">
            <a target="_BLANK" href={link.url} rel="noreferrer">
              {link.url}
            </a>
          </li>
        ))}
      </ul>
    </HsCaseSection>
  ) : (
    <></>
  );
}

export default WebLinkWrapper;
