import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import HsLoader from '../../components/Common/HsLoader';
import { strings } from '../../assets/strings/all';

function ChangePasswordBottom(props) {
  const { errors, loading } = props;
  const { profile: profileString, common: commonString } = strings;
  const [isDisabled, setIsDisabled] = useState(!isEmpty(errors) || loading);

  useEffect(() => {
    setIsDisabled(!isEmpty(errors) || loading);
  }, [errors, loading]);

  return (
    <div className="case-fixed-footer">
      <div className="case-fixed-footer-wrapper">
        <div className="case-fixed-footer-inner">
          <Row>
            <Col>
              <Row className="justify-end">
                <Col xs="auto">
                  <Button variant="primary" type="submit" disabled={isDisabled}>
                    {!loading ? (
                      profileString.change_password
                    ) : (
                      <>
                        <HsLoader />
                        {commonString.updating}
                      </>
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordBottom;
