import gql from 'graphql-tag';
import { OWNER_DETAILS } from '../Case/query';

// #FRAGMENTS
export const CASE_LIST_DETAILS = gql`
  fragment CaseListDetails on Case {
    id
    uuid
    caseTitle
    originalCaseId
    inquiryType
    slug
    title
    caseId
    userId
    visibilityStatus {
      name
      slug
    }
    caseType {
      name
      slug
    }
    caseStage {
      name
      slug
    }
    isFollowing
    patientStage
    patientAge
    patientInitalDianosisDate
    isModerationEnabled
    commentsCount
    followingsCount
    viewsCount
    extraDetails {
      name
      value
    }
    caseStatus {
      slug
      iconClass
    }
    primaryConsultants {
      ...OwnerDetails
    }
    secondaryConsultants {
      ...OwnerDetails
    }
    createdBy {
      ...OwnerDetails
    }
    permissions {
      name
      slug
    }
    createdAt
  }
  ${OWNER_DETAILS}
`;
export const CASE_LIST_RESPONSE = gql`
  fragment CaseListResponse on CaseList {
    hasMore
    totalCount
    cases {
      ...CaseListDetails
    }
  }
  ${CASE_LIST_DETAILS}
`;

export const GET_CASES = gql`
  query ($limit: Int, $offset: Int, $page: Int, $filters: CaseFilterInput) {
    caseList: getCases(
      limit: $limit
      page: $page
      offset: $offset
      filters: $filters
    ) {
      ...CaseListResponse
    }
  }
  ${CASE_LIST_RESPONSE}
`;

export const GET_CASES_COUNT = gql`
  query ($filters: CaseFilterInput) {
    count: getCasesCount(filters: $filters)
  }
`;

export const GET_SCREENER_CASE_COUNT = gql`
  query ($isScreened: Boolean) {
    count: getScreenedCasesCount(isScreened: $isScreened)
  }
`;

export const GET_SCREENER_CASES = gql`
  query ($limit: Int, $offset: Int, $isScreened: Boolean) {
    caseList: getScreenedCases(
      limit: $limit
      offset: $offset
      isScreened: $isScreened
    ) {
      hasMore
      totalCount
      cases {
        uuid
        slug
        title
        caseId
        caseTitle
        inquiryType
        visibilityStatus {
          name
          slug
        }
        caseType {
          name
          slug
        }
        caseStage {
          name
          slug
        }
        isFollowing
        patientStage
        patientAge
        patientInitalDianosisDate
        isModerationEnabled
        commentsCount
        followingsCount
        viewsCount
        extraDetails {
          name
          value
        }
        caseStatus {
          slug
          iconClass
        }
        primaryConsultants {
          uuid
          fullName
          avatar
          username
          speciality {
            name
          }
          email
          about
          association
        }
        secondaryConsultants {
          uuid
          fullName
          avatar
          username
          speciality {
            name
          }
          email
          about
          association
        }
        createdBy {
          uuid
          fullName
          avatar
          username
          speciality {
            name
          }
          email
          about
          association
        }
        permissions {
          name
          slug
        }
        createdAt
      }
    }
  }
`;
