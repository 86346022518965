import { useMutation } from '@apollo/client';
import { isEmpty, isEqual } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { strings } from '../../assets/strings/all';
import { parseGraphqlErrors, validatePassword } from '../../common/functions';
import { UPDATE_USER_PASSWORD } from '../../components/User/query';
import ChangePasswordBottom from './ChangePasswordBottom';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { UserContext } from '../../containers/Context/UserContext';

function ChangePassword(props) {
  const { setFeedback } = useContext(FeedbackContext);
  const { logout } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [numClick, setNumClick] = useState(0);
  const [userData, setUserData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [message, setMessage] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return function cleanup() {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  });

  // const { user } = props;
  // setUserObj(user);

  const { profile: profileString } = strings;

  // if (isEmpty(userObj) && user) {
  //   setUserObj(user);
  // }

  function handleShowCurrentPassword(value) {
    setShowCurrentPassword(value);
  }

  function handleShowNewPassword(value) {
    setShowNewPassword(value);
  }

  function handleShowRetypePassword(value) {
    setShowRetypePassword(value);
  }

  async function validateForm(formData) {
    const errorsObj = {};
    const { errors: errorsString } = profileString;
    //eslint-disable-next-line
    // let epression = /^(?:(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z](?:[A-Za-z]*[A-Za-z])?)|([a-zA-Z0-9\.\-#&]{4,}))$/;
    // var regex = new RegExp(epression);
    const { current_password, new_password, retype_password } = formData;

    if (!current_password) {
      errorsObj.current_password = errorsString.current_password_required;
    } else if (!new_password) {
      errorsObj.new_password = errorsString.new_password_required;
    } else if (!retype_password) {
      errorsObj.retype_password = errorsString.retype_password_required;
    } else if (new_password === current_password) {
      errorsObj.new_password = errorsString.new_password_same;
    } else if (new_password !== retype_password) {
      errorsObj.retype_password = errorsString.retype_password_mismatch;
    } else {
      const error = validatePassword(new_password);
      if (error) {
        errorsObj.new_password = error;
      }
    }
    await setErrors(errorsObj);
    return Object.keys(errorsObj).length === 0;
  }

  const updateUserData = async (value, key) => {
    const user = { ...userData };
    if (value) {
      user[key] = value;
    } else {
      delete user[key];
    }

    if (numClick === 1) {
      await validateForm(user);
    }
    setSubmitted(false);
    return await setUserData(user);
  };

  const onSubmit = async (updatePassword) => {
    console.log('SUBMIT');
    setErrors({});
    setSubmitted(true);
    setNumClick(1);
    if (await validateForm(userData)) {
      updatePassword({ variables: userData });
    }
  };

  const [updatePassword, { loading, error: updatePasswordErrors }] =
    useMutation(UPDATE_USER_PASSWORD, {
      onCompleted: (data) => {
        setFeedback({
          message: 'Password Updated Successfully',
          variant: 'success',
        });
        setTimeout(() => {
          logout();
          // context.logout();
          // props.history.push('/signin');
        }, 1000);
      },
    });

  // if (data && !alertShown) {
  // context.setAlert({
  //   message: 'Password Updated Successfully',
  //   variant: 'success',
  // });
  // setAlertShown(true);
  // setTimeout(() => {
  //   context.logout();
  //   props.history.push('/signin');
  // }, 1000);
  // }

  if (loading && !isEmpty(errors)) {
    setErrors({});
  }

  const errorObj = parseGraphqlErrors(updatePasswordErrors);

  if (!isEqual(errorObj.message, message) && submitted) {
    setMessage(errorObj.message);
  }

  return (
    <>
      <Form
        className="edit-form-wrapper"
        noValidate
        onSubmit={async (event) => {
          event.preventDefault();
          onSubmit(updatePassword);
        }}
      >
        <div className="profile-basic-details">
          {windowWidth > 768 ? (
            <Row className="mb-3 mt-2">
              <Col>
                <div className="auth-form-top-block text-left">
                  <h5 className="auth-form-title">
                    {profileString.change_password_title}
                  </h5>
                  <div className="auth-form-subtitle m-0">
                    {profileString.change_password_description}
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="formLabel">
                  {profileString.current_password}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    autoComplete="off"
                    type={showCurrentPassword ? 'text' : 'password'}
                    placeholder={profileString.enter_current_password}
                    value={userData.current_password}
                    onChange={(e) => {
                      updateUserData(e.target.value, 'current_password');
                    }}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      id="basic-addon1"
                      onClick={() => {
                        handleShowCurrentPassword(!showCurrentPassword);
                      }}
                    >
                      {showCurrentPassword ? (
                        <span className="icon-ico-view" />
                      ) : (
                        <span className="icon-ico-hide" />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors && errors.current_password
                    ? errors.current_password
                    : ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="formLabel">
                  {profileString.new_password}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    autoComplete="off"
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder={profileString.enter_new_password}
                    value={userData.new_password}
                    onChange={(e) => {
                      updateUserData(e.target.value, 'new_password');
                    }}
                    disabled={!userData.current_password}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      id="basic-addon1"
                      onClick={() => {
                        handleShowNewPassword(!showNewPassword);
                      }}
                    >
                      {showNewPassword ? (
                        <span className="icon-ico-view" />
                      ) : (
                        <span className="icon-ico-hide" />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors && errors.new_password ? errors.new_password : ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="formLabel">
                  {profileString.confirm_password}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    autoComplete="off"
                    type={showRetypePassword ? 'text' : 'password'}
                    placeholder={profileString.confirm_password}
                    value={userData.retype_password}
                    onChange={(e) => {
                      updateUserData(e.target.value, 'retype_password');
                    }}
                    disabled={
                      !userData.current_password || !userData.new_password
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      id="basic-addon1"
                      onClick={() => {
                        handleShowRetypePassword(!showRetypePassword);
                      }}
                    >
                      {showRetypePassword ? (
                        <span className="icon-ico-view" />
                      ) : (
                        <span className="icon-ico-hide" />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors && errors.retype_password
                    ? errors.retype_password
                    : ''}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors && errors.all ? errors.all : message ? message : ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div>
            <ChangePasswordBottom errors={errors} loading={loading} />
          </div>
        </div>
      </Form>
    </>
  );
}

export default ChangePassword;
