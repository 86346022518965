import { useMutation } from '@apollo/react-hooks';
import { isEmpty, isEqual } from 'lodash';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import {
  getCsrfToken,
  getQueryParameter,
  parseGraphqlErrors,
  setTitle,
  storeDataToLocalStorage,
} from '../../common/functions';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { SIGNIN } from '../../containers/Auth/query';
import SignInOptions from '../../containers/Auth/SignInOptions';
import { SEND_EMAIL_VERIFICATION_LINK } from '../User/query';
import EmailVerificationErrorComponent from './EmailVerificationErrorComponent';
import Footer from '../../containers/Layout/Footer';

function SignIn(props) {
  const { auth: authString, common: commonString } = strings;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [numClick, setNumClick] = useState(0);
  const [showPassword, setShowPassword] = useState();
  const { setFeedback } = useContext(FeedbackContext);
  const [showEmailUnverified, setShowEmailUnverified] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  function scriptLoaded() {
    console.log('LOADED');
  }

  //SIGNIN MUTATION
  const [signin, { error: signInErrors, loading, data }] = useMutation(SIGNIN, {
    onCompleted: () => {
      setDisableButton(false);
    },
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];
      if (
        networkError &&
        networkError.result &&
        networkError.result.code === 'CSRF'
      ) {
        setFeedback({
          message: networkError.result.message,
          variant: 'danger',
        });
      }

      if (error) {
        if (error.statusCode && error.statusCode === 401) {
          setShowEmailUnverified(true);
        } else {
          if (error.statusCode && error.statusCode === 400) {
            setDisableButton(true);
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js';
            script.async = 'defer';
            script.onload = () => scriptLoaded();
            document.body.appendChild(script);
          }
          setFeedback({ message: error.message, variant: 'danger' });
        }
      }
    },
  });

  //SEND EMAIL VERIFICATION LINK
  const [sendEmailVerificationLink, { data: emailLinkData }] = useMutation(
    SEND_EMAIL_VERIFICATION_LINK,
    {
      onError: ({ nerworkError, graphQLErrors }) => {
        const error = graphQLErrors && graphQLErrors[0];

        if (error) {
          setFeedback({ message: error.message, variant: 'danger' });
        }
      },
    },
  );

  if (loading && !isEmpty(errors)) {
    setErrors({});
  }

  const errorObj = parseGraphqlErrors(signInErrors);

  if (errorObj.errors && !isEqual(errorObj.errors, errors)) {
    setErrors(errorObj.errors);
  }
  if (errorObj.message && !isEqual(errorObj.message, message)) {
    setMessage(errorObj.message);
  }

  setTitle(authString.signin_page_title);

  const email_param = getQueryParameter('email');
  const password_param = getQueryParameter('password');
  if (!email && email_param && email_param !== email) {
    setEmail(email_param);
  }
  if (!password && password_param && password_param !== password) {
    setPassword(password_param);
  }

  const onSubmit = async (signin, token, type) => {
    setErrors({});
    setMessage(null);
    setNumClick(1);
    if (await validateForm()) {
      try {
        const response = await getCsrfToken();
        if (response) {
          signin({
            variables: {
              email: email,
              password: password,
              token: token,
              captcha_type: type,
            },
          });
        }
      } catch (error) {
        setFeedback(error.message);
      }
    }
  };

  function handleShowPassword(value) {
    setShowPassword(value);
  }

  async function validateForm() {
    const errorsObj = {};
    const { errors: errorString } = authString;
    //eslint-disable-next-line
    let epression =
      /^(?:(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z](?:[A-Za-z]*[A-Za-z])?)|([a-zA-Z0-9\.\-#&]{4,}))$/;
    var regex = new RegExp(epression);

    if (!email) {
      errorsObj.email = errorString.email_required;
    } else if (!email.match(regex)) {
      errorsObj.email = errorString.incorrect_user;
    }

    if (!password) {
      errorsObj.password = errorString.password_required;
    }

    await setErrors(errorsObj);

    return Object.keys(errorsObj).length === 0;
  }

  let redirect_to = getQueryParameter('redirect_to');

  if (data && data.signin) {
    console.log(' data.signin', data.signin);

    setTimeout(async () => {
      await storeDataToLocalStorage('token', data.signin.token, true);
      setFeedback({});
      props.history.replace(`/cases`);
      if (!redirect_to) {
        props.history.replace(`/cases`);
      } else {
        console.log('redirected', redirect_to);
        props.history.replace(`/cases?redirect_to=${redirect_to}`);
      }
    }, 50);
  }

  window.handleV2Callback = (response) => {
    signin({
      variables: {
        email: email,
        password: password,
        token: response,
        captcha_type: 'V2',
      },
    });
  };

  return (
    <>
      <Form
        noValidate
        onSubmit={async (event) => {
          event.preventDefault();
          // TODO enable captcha
          onSubmit(signin, undefined, undefined);
          // window.grecaptcha.ready(function () {
          //   window.grecaptcha
          //     .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          //       action: 'submit',
          //     })
          //     .then(async function (token) {
          //       onSubmit(signin, token, 'V3');
          //     });
          // });
        }}
      >
        <div className="auth-form-title">Sign In</div>
        <div className="auth-form-discalimer">
          <Row>
            <Col xs={1} md={1} lg={1} xl={1}>
              <span className="icon-ico-info icon" />
            </Col>
            <Col>
              <div className="text">{commonString.signin_disclaimer}</div>
            </Col>
          </Row>
        </div>
        <div className="auth-form-card auth-form-fw">
          <Form.Group controlId="formBasicEmail">
            <Form.Label>{authString.email}</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder={authString.enter_email}
              value={email}
              onChange={async (e) => {
                await setEmail(e.target.value);
                if (numClick > 0) {
                  validateForm();
                }
              }}
              // {...email}
              autoFocus
            />
            {errors && errors.email ? (
              <Form.Control.Feedback type="invalid">
                {errors && errors.email ? errors.email : ''}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>{authString.password}</Form.Label>
            <InputGroup>
              <Form.Control
                // type="text"
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                placeholder={authString.enter_password}
                {...password}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                aria-describedby="basic-addon1"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  id="basic-addon1"
                  onClick={() => {
                    handleShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <span className="icon-ico-view" />
                  ) : (
                    <span className="icon-ico-hide" />
                  )}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {errors && errors.password ? (
              <Form.Control.Feedback type="invalid">
                {errors && errors.password ? errors.password : ''}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group controlId="formBasicChecbox">
            {/* <Form.Check type="checkbox" label="Remember me" /> */}
            <Row>
              <Col className="text-right">
                <Link className="auth-form-link" to="/forgot-password">
                  {authString.forgot_password_link}
                </Link>
              </Col>
            </Row>
          </Form.Group>
          <div
            className="g-recaptcha"
            data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
            data-callback="handleV2Callback"
          />
          <div className="text-right auth-form-action-button">
            {errors && message ? (
              <Form.Control.Feedback type="invalid">
                {message}
              </Form.Control.Feedback>
            ) : null}

            <Button
              variant="primary"
              type="submit"
              disabled={loading || !email || !password || disableButton}
            >
              {!loading ? (
                authString.signin
              ) : (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {authString.signingin}
                </>
              )}
            </Button>
          </div>
        </div>
        {showEmailUnverified ? (
          <EmailVerificationErrorComponent
            email={email}
            sendEmailVerificationLink={sendEmailVerificationLink}
            data={emailLinkData}
          />
        ) : null}
      </Form>
      <SignInOptions active="pwd" />
    </>
  );
}

export default SignIn;
