import { useMutation, useQuery } from '@apollo/client';
import { findIndex } from 'lodash';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getOtherDetailFields } from '../../common/functions';
import { OrganizationContext } from '../../containers/Context/OrganizationContext';
import HsFormInput from '../Form/HsFormInput';
import HsFormLabel from '../Form/HsFormLabel';
import AttachmentInputWrapper from './components/AttachmentInputWrapper';
import WebLinkInputWrapper from './components/WebLinkInputWrapper';
import { SHARE_CASE_FIELDS, SHARE_CASE_REQUEST } from './query';

export const ShareView = (props) => {
  return <ShareForm {...props} />;
};

const ShareForm = (props) => {
  const { match } = props;
  const { organization } = useContext(OrganizationContext);
  const { id } = match.params;
  const [caseData, setCaseData] = useState({});
  const [errors, setErrors] = useState({});
  const [clicked, setClicked] = useState(false);
  const history = useHistory();
  const [shareCase] = useMutation(SHARE_CASE_REQUEST);

  const { sharingFields = [] } = organization;
  const fieldArray = Object.assign(
    {},
    ...sharingFields.map((c) => ({ [c.slug]: c })),
  );
  const extraFields = getOtherDetailFields(sharingFields) || [];
  const updateCaseData = (name, value) => {
    const newCaseData = { ...caseData, [name]: value };
    setCaseData(newCaseData);
  };

  const updateDynamicFields = (name, value) => {
    const newCaseData = { ...caseData };
    let extraFields = newCaseData.extraDetails
      ? [...newCaseData.extraDetails]
      : [];
    const newExtraField = {
      name,
      value,
    };

    const extraFieldIndex = findIndex(extraFields, ['name', name]);

    if (extraFieldIndex > -1) extraFields[extraFieldIndex] = newExtraField;
    else extraFields.push(newExtraField);
    updateCaseData('extraDetails', extraFields);
  };
  return (
    <Container>
      <Row className="my-3 mx-2">
        <div
          style={{
            fontWeight: 'bold',
          }}
        >
          Share case <strong>{id}</strong> with CAVI community
        </div>
      </Row>
      <Form
        onSubmit={async (ev) => {
          ev.preventDefault();
          const { extraDetails, links = [], attachments = [] } = caseData;
          setClicked(true);
          if (extraDetails && extraDetails.length > 0) {
            const resp = await shareCase({
              variables: {
                fields: extraDetails,
                attachments: [
                  ...attachments.map((att) => {
                    const { attachmentType, url } = att;
                    return { attachmentType, url };
                  }),
                  ...links.map((link) => {
                    const { attachmentType, url } = link;
                    return { attachmentType, url };
                  }),
                ],
                caseId: id,
              },
            });
            history.push('/cases/' + resp.data.shareCase.case.slug);
          } else {
            alert('Writing why the case is being shared is mandatory');
          }
          setClicked(false);
        }}
      >
        <Card>
          <Card.Body>
            {extraFields.map((ef, index) => {
              const field = null;
              return (
                <Row key={`${ef}-${index}-extra-details`}>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <HsFormLabel field={fieldArray[ef.slug]} />
                      <HsFormInput
                        {...{ field: fieldArray[ef.slug] }}
                        name={ef.slug}
                        onChange={(name, value) =>
                          updateDynamicFields(name, value)
                        }
                        value={field ? field.value : ''}
                      />
                      {errors[ef.slug] && (
                        <Form.Control.Feedback type="invalid">
                          {errors[ef.slug]}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <AttachmentInputWrapper
                    submitionType="SHARING"
                    {...{ data: caseData }}
                    onChange={(value) => updateCaseData('attachments', value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <WebLinkInputWrapper
                  {...{ data: caseData }}
                  onChange={(value) => updateCaseData('links', value)}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row
          className="mx-1 my-2"
          style={{
            gap: '8px',
            justifyContent: 'center',
          }}
        >
          <Button type="sumbit" disabled={clicked}>
            Submit
          </Button>
          <Button
            onClick={() => {
              history.goBack();
            }}
            variant="outline-danger"
          >
            Cancel
          </Button>
        </Row>
        <div
          style={{
            height: '100px',
          }}
        />
      </Form>
    </Container>
  );
};

export default ShareForm;
