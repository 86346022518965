import React from 'react';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import { strings } from '../../../assets/strings/all';

const CaseDisclaimerModal = (props) => {
  const { open, close, type, saveOrPublishCase } = props;
  const { cases: caseString } = strings;

  return (
    <Modal size="md" show={open} onHide={close} centered>
      <Modal.Header closeButton>
        <h6>{caseString.disclaimer}</h6>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-description muted-text">
          {caseString.case_disclaimer_body_3}
        </div>
        <div className="modal-description modal-warning muted-text">
          {caseString.case_disclaimer_body_4}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Row className="row-small">
          <Col>
            <Button variant="light" onClick={close}>
              {caseString.cancel}
            </Button>
          </Col>
          <Button
            variant="primary"
            onClick={() => {
              close();
              saveOrPublishCase(type);
            }}
          >
            {type === 'in-review'
              ? caseString.request_to_publish
              : type === 'publish'
              ? caseString.submit
              : null}
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default CaseDisclaimerModal;
