import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CaseCardLoader from './CaseCardLoader';
import { useQuery } from '@apollo/client';
import { GET_CASES } from './query';
import { isEmpty } from 'lodash';
import CaseCard from './CaseCard';
import { useScroll } from '../../containers/Helper/useScroll';
import { useRef } from 'react';
import { useState } from 'react';
import NoData from '../Common/NoData';
import { strings } from '../../assets/strings/all';
import { Button } from 'react-bootstrap';

function CaseList(props) {
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(1);
  const { showButton, customGetter } = props;
  const limit = 18;
  const variables = { ...props.variables, limit };
  const { profile } = strings;

  const { loading, data, fetchMore } = useQuery(
    customGetter ? customGetter : GET_CASES,
    {
      variables,
      fetchPolicy: 'network-only',
    },
  );

  const fetchMoreCases = async (limit, offset) => {
    await fetchMore({
      variables: { limit: 18, page },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        let caseList = {
          ...fetchMoreResult,
          caseList: {
            ...fetchMoreResult.caseList,
            cases: [...prev.caseList.cases, ...fetchMoreResult.caseList.cases],
          },
        };
        setLoadingData(false);
        return caseList;
      },
    });
  };
  useEffect(() => {
    if (data && !data.caseList.hasMore && loadingData) {
      setLoadingData(false);
    }
  }, [loading]);
  useEffect(() => {
    setLoadingData(true, () => {
      fetchMoreCases();
    });
  }, [page]);

  const listRef = useRef();

  const scrollHeight = useScroll();
  const viewportHeight =
    listRef && listRef.current
      ? listRef.current.getBoundingClientRect().height +
        listRef.current.getBoundingClientRect().top -
        scrollHeight.scrollY
      : 0;
  useEffect(() => {}, [viewportHeight]);
  if (loading && isEmpty(data)) {
    return (
      <Row>
        <Col>
          <CaseCardLoader />
        </Col>
        <Col>
          <CaseCardLoader />
        </Col>
        <Col>
          <CaseCardLoader />
        </Col>
      </Row>
    );
  }

  if (!data) {
    return <NoData type="cases" message={profile.no_cases} />;
  }

  const {
    caseList: { cases = [], hasMore = false, totalCount = 0 },
  } = data;

  if (totalCount === 0) {
    return <NoData type="cases" message={profile.no_cases} />;
  }
  if (viewportHeight < 200 && viewportHeight > 0 && !loadingData && hasMore) {
    setPage(page + 1);
  }

  const addDataAfterRemove = (limit, offset) => {
    setPage(page + 1);
  };

  return (
    <Row ref={listRef}>
      {cases.map((singleCase) => (
        <Col md={4} sm={6} xs={12} key={`case-card-${singleCase.uuid}`}>
          <CaseCard
            showButton={showButton}
            caseData={singleCase}
            addDataAfterRemove={addDataAfterRemove}
          />
        </Col>
      ))}
      {hasMore && !loadingData && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
          }}
        >
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              addDataAfterRemove(0, 0);
            }}
          >
            Load More
          </Button>
        </div>
      )}
    </Row>
  );
}

export default CaseList;
