import { useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import DashboardInner from '../../components/Dashboard/DashboardInner';
import ScreenerDashboard from '../../components/Dashboard/ScreenerDashboard';
import HomeNoAuth from '../Auth/HomeNoAuth';
import { RoleContext } from '../Context/RoleContext';
import RoleProvider from '../Context/RoleProvider';
import Footer from '../Layout/Footer';
import Master from '../Layout/Master';

const DashboardContent = (props) => {
  const { role } = useContext(RoleContext);
  const { view } = props;
  return (
    <>
      <Master {...props}>
        {role && role.name === 'Screener' ? (
          <ScreenerDashboard {...props} />
        ) : (
          <DashboardInner {...props} />
        )}
      </Master>
      {/* {view === 'cases' ? <Footer /> : <></>} */}
    </>
  );
};

function Dashboard(props) {
  const { view } = props;
  if (view === 'tnc') {
    return <HomeNoAuth view="tnc" active="tnc" {...props} />;
  } else if (view === 'privacy') {
    return <HomeNoAuth view="privacy" active="privacy" {...props} />;
  } else if (view === 'account-deletion') {
    return (
      <HomeNoAuth
        view="account-deletion"
        active="account-deletion"
        {...props}
      />
    );
  } else {
    return (
      <RoleProvider>
        <DashboardContent {...props} />
      </RoleProvider>
    );
  }
}

export default Dashboard;
