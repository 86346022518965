import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import CaseUserListItem from "../components/CaseUserListItem";
import { strings } from "../../../assets/strings/all";

const PanelMemberCard = (props) => {
  const { data } = props;
  const { commentors } = data;
  const { cases: caseString } = strings;

  return (
    <>
      <Card className="case-detail-side-card text-center">
        <Card.Body>
          <div className="case-side-user-wrapper">
            <div className="case-side-user-wrapper-title">{caseString.panel_members}</div>
            <ListGroup variant="flush">
              {commentors.map((user, index) => (
                <CaseUserListItem key={index} {...{ user }} type="secondaryConsultant" />
              ))}
            </ListGroup>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default PanelMemberCard;
