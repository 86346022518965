import React from 'react';
import HsCaseSection from '../../Common/HsCaseSection';
import HsGallery from '../../Common/HsGallery';
import { strings } from '../../../assets/strings/all';

function AttachmentWrapper(props) {
  const { data } = props;
  const { attachments } = data;
  const { common } = strings;
  return attachments.length > 0 ? (
    <HsCaseSection title={common.attachments}>
      <HsGallery attachments={attachments} />
    </HsCaseSection>
  ) : (
    <></>
  );
}

export default AttachmentWrapper;
