import React, { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import { userHasPermission } from '../../common/functions';
import permissionList from '../../common/permissions';
import { UserContext } from '../Context/UserContext';
import CaseSearchComponent from '../../components/Form/autocomplete/caseSearch';

export default function HeaderMenu(props) {
  const { active } = props;
  const { header } = strings;

  const { user } = useContext(UserContext);

  const {
    VIEW_OTHERS_JOURNALS_IN_ORGANIZATION,
    VIEW_MY_JOURNALS,
    VIEW_PUBLIC_CASES,
    VIEW_MY_DRAFT_CASES,
    REVIEW_COMMENTS,
    REVIEW_JOURNALS,
    PUBLISHED_CASE_REVIEW,
    GET_ANALYTICS,
  } = permissionList;

  const canReiewCases = userHasPermission(user, PUBLISHED_CASE_REVIEW);
  const canReiewJournals = userHasPermission(user, REVIEW_JOURNALS);
  const canReviewComments = userHasPermission(user, REVIEW_COMMENTS);

  const canViewJournals = userHasPermission(
    user,
    VIEW_OTHERS_JOURNALS_IN_ORGANIZATION,
  );
  const canViewMyJournals = userHasPermission(user, VIEW_MY_JOURNALS);

  const canViewCases = userHasPermission(user, VIEW_PUBLIC_CASES);
  const canViewMyCases = userHasPermission(user, VIEW_MY_DRAFT_CASES);

  const canGetAnalytics = userHasPermission(user, GET_ANALYTICS);

  const main_routes = [];

  if (canReiewJournals || canReiewCases || canReviewComments) {
    main_routes.push('review');
  }
  if (canViewCases || canViewMyCases) {
    main_routes.push('cases');
  }
  if (canViewJournals || canViewMyJournals) {
    main_routes.push('journals');
  }
  if (canGetAnalytics) {
    main_routes.push('analytics');
  }

  let routes = {
    cases: {
      url: '/cases',
      title: header.cases,
      icon: 'icon-ico-home',
      active_icon: 'icon-ico-home---solid',
      page_type: 2,
    },
    journals: {
      url: '/journals',
      title: header.journals,
      icon: 'icon-ico-people-networrk',
      active_icon: 'icon-ico-people-networrk---solid',
      page_type: 3,
    },
    review: {
      url: '/review',
      title: header.review,
      page_type: 29,
    },
    analytics: {
      url: '/analytics',
      title: header.analytics,
    },
  };

  return (
    <Nav className={`header-menu`}>
      {main_routes.map((route, index) => {
        return (
          <Nav.Item className={active === route ? 'active' : ''} key={route}>
            <Nav.Link as={Link} to={routes[route].url}>
              <span className="header-menu-title">{routes[route].title}</span>
            </Nav.Link>
          </Nav.Item>
        );
      })}
      <Nav.Item>
        <Nav.Link
          as={Link}
          className="mx-2"
          to={'#'}
          onClick={(e) => {
            window.open('mailto:cavi@iqvia.com');
            // window.location.href = 'mailto:cavi@iqvia.com';
            e.preventDefault();
          }}
        >
          <span className="header-menu-title">{header.contact_us}</span>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}
