import React, { useState } from 'react';
import { useContext } from 'react';
import { userHasPermission } from '../../common/functions';
import permissionList from '../../common/permissions';
import AdminDashboard from '../../components/User/AdminDashboard';
import { UserContext } from '../Context/UserContext';
import Master from '../Layout/Master';
import { Redirect, Switch } from 'react-router-dom';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import UserProfileWrapper from '../Profile/UserProfileWrapper';

const AnalyticsRoute = (props) => {
  const { match } = props;
  const [activeTab, setActiveTab] = useState('overview');

  const { user } = useContext(UserContext);
  const { GET_ANALYTICS } = permissionList;

  const canGetAnalytics = userHasPermission(user, GET_ANALYTICS);

  let component;

  if (canGetAnalytics) {
    component = (
      <AdminDashboard
        {...props}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    );
  } else {
    component = <div>You are not authorized to view this page</div>;
  }

  // else if (canGetUserAnalytics && user.isExpert) {
  //   component = (
  //     // <ExpertDashboard
  //     //   {...props}
  //     //   activeTab={activeTab}
  //     //   setActiveTab={setActiveTab}
  //     // />
  //     <></>
  //   );
  // } else if (canGetUserAnalytics && !user.isExpert) {
  //   component = (
  //     <UserDashboard
  //       {...props}
  //       activeTab={activeTab}
  //       setActiveTab={setActiveTab}
  //     />
  //   );
  // } else {
  //   component = <div>Error</div>;
  // }

  return (
    <Master {...props}>
      <Switch>
        <AuthenticatedRoute
          exact
          path={`${match.path}`}
          component={() => component}
        />

        <AuthenticatedRoute
          exact
          path={`${match.path}/user/`}
          component={(props) => (
            <>
              <Redirect to="/analytics" />
            </>
          )}
        />

        <AuthenticatedRoute
          exact
          path={`${match.path}/user/:user_slug`}
          component={(props) => (
            <UserProfileWrapper
              breadcrumb={true}
              view="analyticsProfile"
              viewType="analyticsProfile"
              active="analyticsProfile"
              {...props}
            />
          )}
        />
      </Switch>
    </Master>
  );
};

export default AnalyticsRoute;
