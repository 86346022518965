import React from 'react';
import { components } from 'react-select';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props} className="search-icon">
      {/* <span className="icon-ico-dropdown" /> */}
      <img
        width={18}
        decoding="async"
        loading="lazy"
        src="/api/static/dropdown.svg"
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
