import gql from 'graphql-tag';
import { USER_PROFILE_FRAGMENT } from '../../containers/Auth/query';

// #FRAGMENTS
export const USER_LIST_DETAILS = gql`
  fragment UserListDetails on User {
    uuid
    fullName
    email
    username
    avatar
    speciality {
      name
    }
    about
    association
    licenseFile {
      url
      attachmentType
      fileName
      thumbnail
      fileType
    }
  }
`;

export const GENERAL_RESPONSE_KEYS = gql`
  fragment GeneralResponse on GeneralResponse {
    code
    success
    message
  }
`;

export const USER_LIST = gql`
  fragment UserList on UserList {
    hasMore
    totalCount
    users {
      ...UserListDetails
    }
  }
  ${USER_LIST_DETAILS}
`;

export const GET_USERS = gql`
  query (
    $offset: Int
    $limit: Int
    $queryString: String
    $exclude: [String]
    $uuid: String
    $country: String
  ) {
    userList: getCaseCoownerSuggestions(
      offset: $offset
      limit: $limit
      queryString: $queryString
      uuid: $uuid
      exclude: $exclude
      country: $country
    ) {
      ...UserList
    }
  }
  ${USER_LIST}
`;

export const GET_USERS_FOR_MENTION = gql`
  query (
    $offset: Int
    $limit: Int
    $queryString: String
    $exclude: [String]
    $uuid: String
  ) {
    userList: getCaseMentionSuggestions(
      offset: $offset
      limit: $limit
      queryString: $queryString
      uuid: $uuid
      exclude: $exclude
    ) {
      ...UserList
    }
  }
  ${USER_LIST}
`;

export const GET_COMMENTORS = gql`
  query (
    $offset: Int
    $limit: Int
    $queryString: String
    $exclude: [String]
    $uuid: String
  ) {
    userList: getCaseCoownerSuggestions(
      offset: $offset
      limit: $limit
      queryString: $queryString
      uuid: $uuid
      exclude: $exclude
    ) {
      ...UserList
    }
  }
  ${USER_LIST}
`;

export const UPDATE_PROFILE = gql`
  mutation ($userData: UserInput) {
    user: updateProfile(userData: $userData) {
      message
      user {
        ...UserListDetails
      }
      success
    }
  }
  ${USER_LIST_DETAILS}
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation ($new_password: String!, $current_password: String!) {
    updatePassword(
      current_password: $current_password
      new_password: $new_password
    ) {
      ...GeneralResponse
    }
  }
  ${GENERAL_RESPONSE_KEYS}
`;

export const SET_USER_PASSWORD = gql`
  mutation ($password: String!) {
    setPassword(password: $password) {
      ...GeneralResponse
    }
  }
  ${GENERAL_RESPONSE_KEYS}
`;

export const SEND_EMAIL_VERIFICATION_LINK = gql`
  mutation ($email: String) {
    sendEmailVerificationLink(email: $email) {
      ...GeneralResponse
    }
  }
  ${GENERAL_RESPONSE_KEYS}
`;

export const SEND_OTP = gql`
  mutation ($phoneCode: String!, $phoneNumber: String!) {
    send_otp: sendPhoneNumberVerificationCode(
      phoneCode: $phoneCode
      phoneNumber: $phoneNumber
    ) {
      ...GeneralResponse
    }
  }
  ${GENERAL_RESPONSE_KEYS}
`;

export const VERIFY_OTP_FOR_PHONE_NUMBER = gql`
  mutation (
    $otp: String
    $uuid: String
    $phoneCode: String
    $phoneNumber: String
  ) {
    verify_otp: verifyOTPforPhoneNumber(
      otp: $otp
      uuid: $uuid
      phoneCode: $phoneCode
      phoneNumber: $phoneNumber
    ) {
      ...UserProfileResponse
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export const userInput = [
  'firstName',
  'lastName',
  'avatar',
  'userTitleSlug',
  'specialitySlug',
  'professionSlug',
  'licenseId',
  'about',
  'association',
  'licenseFile',
];

export const notificationSettingInput = [
  'smsEnabled',
  'emailEnabled',
  'pnEnabled',
];

export const REMOVE_LICENSE = gql`
  mutation ($url: String!) {
    removeLicense(url: $url) {
      ...UserProfileResponse
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export const ANALYTICS_RESPONSE = gql`
  fragment AnalyticsDetails on AnalyticsResponse {
    totalHcp
    activeHcp
    newCases
    openCases
    resolvedCases
    avgTimeToClose
    avgRating
    pendingCases
    returnedCases
    newQuestions
    openQuestions
    pendingQuestions
    returnedQuestions
    closedQuestions
    commentAna {
      name
      count
    }
    caseAna {
      name
      count
    }
    userCaseViewAna {
      name
      count
    }
    mostActiveCases {
      caseId
      count
    }
    transitionData
  }
`;

export const GET_ADMIN_ANALYTICS = gql`
  query ($startDate: String, $endDate: String) {
    getAdminAnalytics(startDate: $startDate, endDate: $endDate) {
      ...AnalyticsDetails
    }
  }

  ${ANALYTICS_RESPONSE}
`;

export const EXPERT_ANALYTICS_USER_DETAILS = gql`
  fragment AnalyticsUser on AnalyticsUserDetails {
    totalComments
    totalReminders
    lastLogin
    isEmailVerified
    isLicenseVerified
    firstName
    lastName
    phoneCode
    phoneNumber
    email
    isPhoneVerified
    speciality {
      name
    }
    userTitle {
      name
    }
    licenseId
    avatar
    about
    association
    licenseFile {
      url
      attachmentType
      fileName
      thumbnail
      fileType
    }
    createdAt
  }
`;

export const GET_EXPERT_ANALYTICS = gql`
  query ($startDate: String, $endDate: String) {
    getExpertAnalytics(startDate: $startDate, endDate: $endDate) {
      totalComments
      totalReminders
      lastLogin
      openCases
      resolvedCases
      avgTimeToClose
      totalCases
    }
  }
`;

export const ANALYTICS_USER_DETAILS = gql`
  fragment AnalyticsUserDetails on User {
    uuid
    fullName
    email
    username
    avatar
    speciality {
      slug
      name
    }
    userTitle {
      name
      slug
    }
    about
    association
    isLicenseVerified
    licenseId
    licenseFile {
      url
      attachmentType
      fileName
      thumbnail
      fileType
    }
    createdAt
    isExpert
  }
`;

export const GET_ANALYTICS_USER_LIST = gql`
  query (
    $startDate: String
    $endDate: String
    $limit: Int
    $offset: Int
    $filterString: String
    $userType: String
    $countryId: Int
  ) {
    getAnalyticsUserList(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
      filterString: $filterString
      userType: $userType
      countryId: $countryId
    ) {
      total
      hasMore
      totalCount
      analyticsUsers {
        ...AnalyticsUserDetails
      }
    }
  }

  ${ANALYTICS_USER_DETAILS}
`;

export const GET_USER_ANALYTICS = gql`
  query ($startDate: String, $endDate: String, $username: String) {
    getUserAnalytics(
      startDate: $startDate
      endDate: $endDate
      username: $username
    ) {
      totalCases
      resolvedCases
      openCases
      avgTimeToClose
      totalComments
      totalReminders
      lastLogin
      user {
        ...AnalyticsUserDetails
      }
    }
  }

  ${ANALYTICS_USER_DETAILS}
`;

export const UPDATE_LICENSE_STATUS = gql`
  mutation ($type: String!, $uuid: String!) {
    updateLicenseStatus(type: $type, uuid: $uuid) {
      success
      message
      user {
        ...AnalyticsUserDetails
      }
    }
  }
  ${ANALYTICS_USER_DETAILS}
`;

export const DOWNLOAD_CASE_ANALYTICS = gql`
  mutation ($startDate: String, $endDate: String) {
    getCaseAnalyticsDownload(startDate: $startDate, endDate: $endDate) {
      url
    }
  }
`;

export const DOWNLOAD_EXPERT_ANALYTICS = gql`
  mutation ($startDate: String, $endDate: String) {
    getExpertAnalyticsDownload(startDate: $startDate, endDate: $endDate) {
      url
    }
  }
`;
