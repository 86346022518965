import React from 'react';
import { components } from 'react-select';
const ValueContainer = (props) => {
  const { children } = props;
  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
};

export default ValueContainer;
