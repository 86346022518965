import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { strings } from '../../assets/strings/all';
import HsImage from '../Common/HsImage';
import HsPDFViewer from './HsPDFViewer';

function HsFileBox(props) {
  const { file, type } = props;
  if (file.file && file.file.file) console.log({ file: file.file.file });
  const filename =
    file.file && file.file.file && file.file.file.name
      ? file.file.file.name.split('/').pop()
      : file.url.split('/').pop();

  const extension = filename.split('.').pop();
  const { post } = strings;
  const image_array = ['jpg', 'png', 'jpeg', 'png', 'bmp', 'dcm'];

  const isImage = image_array.indexOf(extension.toLowerCase()) > -1;
  console.log({
    isImage,
    type,
    url: file.url,
    extension,
  });
  return type === 'thumbnail' ? (
    isImage ? (
      <HsImage
        className="img-responsive"
        src={file.url ? file.url : file.file.preview}
      />
    ) : extension === 'pdf' ? (
      <HsImage
        className="img-responsive"
        src={
          'https://www.associationservicesgroup.net/wp-content/uploads/2017/07/PDF-Placeholder-e1500896019213.png'
        }
      />
    ) : (
      <div
        style={{
          color: 'black',
          textAlign: 'center',
          fontSize: '1.2rem',
        }}
        className="extension-wrapper"
      >
        {extension}
      </div>
    )
  ) : (
    <div className={`uploadDiv ${type === 'all' ? 'full-download-file' : ''}`}>
      {type === 'all' ? (
        <Row>
          <Col xs={12} className="p-0">
            {isImage ? (
              <HsImage
                className="img-responsive"
                src={file.url ? file.url : file.file.preview}
              />
            ) : (
              <div className="extension-wrapper">{extension}</div>
            )}
          </Col>
          <Col xs={12}>
            <div className="fileInfo p-0">
              <span className="fileName">
                {file.name ? file.name : filename}
              </span>
            </div>
            <div className="no-preview">{post.no_preview}</div>
          </Col>
          <Col xs={12} className="p-0 centerFlex">
            <a
              className="btn btn-light"
              target="_BLANK"
              rel="noopener noreferrer"
              href={file.url}
            >
              {post.download}
            </a>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={2} sm={1} md={2} className="p-0">
            {isImage ? (
              <HsImage
                className="img-responsive"
                src={file.url ? file.url : file.file.preview}
              />
            ) : (
              <div className="extension-wrapper">{extension}</div>
            )}
          </Col>
          <Col xs={9} sm={10} md={9}>
            <Row className="fileInfo">
              <Col>
                <div className="fileName">
                  {file.name ? file.name : filename}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={1} className="p-0 centerFlex">
            <a target="_BLANK" rel="noopener noreferrer" href={file.url}>
              <span className="icon-ico-download" />
            </a>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default HsFileBox;
