import React from 'react';
import { components } from 'react-select';
const MultiValueLabel = (props) => {
  const newProps = {
    ...props,
    innerProps: {
      ...props.innerProps,
      className: `${props.innerProps.className} hs-multi-value-label`,
    },
  };

  return <components.MultiValueLabel {...newProps} />;
};

export default MultiValueLabel;
