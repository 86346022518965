import React from 'react';
import Badge from 'react-bootstrap/Badge';

function HsPatientDataBadge(props) {
  const { text } = props;

  return (
    <Badge className='badge-bg badge-patient-data' pill variant='light'>
      {text}
    </Badge>
  );
}
export default HsPatientDataBadge;
