import { useMutation } from "@apollo/client";
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { strings } from "../../assets/strings/all";
import { getQueryParameter, setTitle } from "../../common/functions";
import { FORGOT_PASSWORD } from "../../containers/Auth/query";
import { FeedbackContext } from "../../containers/Context/FeedbackContext";

const ForgotPassword = (props) => {
  const { auth: authString } = strings;
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const { setFeedback } = useContext(FeedbackContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [numClick, setNumClick] = useState(0);
  setTitle(authString.signin_page_title);

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: "danger" });
      }
    },
    onCompleted: (data) => {
      const { success } = data.forgotPassword;
      if (success) {
        setShowSuccess(true);
      }
    },
  });

  if (loading && !isEmpty(errors)) {
    setErrors({});
  }

  const email_param = getQueryParameter("email");
  if (!email && email_param && email_param !== email) {
    setEmail(email_param);
  }

  async function validateForm() {
    const errorsObj = {};
    const { errors: errorString } = authString;
    //eslint-disable-next-line
    let epression = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var regex = new RegExp(epression);

    if (!email) {
      errorsObj.email = errorString.email_required;
    } else if (!email.match(regex)) {
      errorsObj.email = errorString.incorrect_user;
    }

    await setErrors(errorsObj);

    return Object.keys(errorsObj).length === 0;
  }

  const onSubmit = async (forgotPassword) => {
    setErrors({});
    setNumClick(1);
    if (await validateForm()) {
      forgotPassword({ variables: { email: email } });
    }
  };

  return showSuccess ? (
    <div>
      <Row className="auth-form-parent">
        <Col>
          <Form noValidate>
            <div className="auth-form-title">
              {authString.forgot_password_link}
              <Form.Label className="mt-3">
                {strings.formatString(authString.have_sent_recovery, {
                  email: email,
                })}
              </Form.Label>
            </div>

            <div className="text-right auth-form-action-button">
              <Link to="/signin" className="btn btn-light mr-3">
                {authString.back_to_signin}
              </Link>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Row className="auth-form-parent">
        <Col>
          <Form
            noValidate
            onSubmit={async (event) => {
              event.preventDefault();
              onSubmit(forgotPassword);
            }}
          >
            <div className="auth-form-title">
              {authString.forgot_password_link}
              <br />
              <Form.Label className="mt-3">{authString.will_send_recovery}</Form.Label>
            </div>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>{authString.email_address}</Form.Label>
              <Form.Control
                type="text"
                placeholder={authString.email_placeholder}
                value={email}
                onChange={async (e) => {
                  await setEmail(e.target.value);
                  if (numClick > 0) {
                    validateForm();
                  }
                }}
                // {...email}
                autoFocus
              />
              <Form.Control.Feedback type="invalid">{errors && errors.email ? errors.email : ""}</Form.Control.Feedback>
            </Form.Group>

            <div className="text-right auth-form-action-button">
              <Link to="/signin" className="btn btn-light mr-3">
                {authString.back_to_signin}
              </Link>

              <Button variant="primary" type="submit" disabled={loading || isEmpty(email)}>
                {!loading ? (
                  authString.send_link
                ) : (
                  <>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    {authString.sending_link}
                  </>
                )}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
