import React, { useState, createContext, useEffect } from 'react';

export const FeedbackContext = createContext();

const FeedbackContextProvider = (props) => {
  const [feedback, setFeedback] = useState({});

  const getCSRF = async () => {};

  useEffect(() => {
    getCSRF();
  });

  return (
    <FeedbackContext.Provider value={{ feedback, setFeedback }}>
      {props.children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackContextProvider;
