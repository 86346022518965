export const strings = {
  discover: "Discover",
  discover_desc: "Explore Medical and Pharma events in our global hub",
  discover_event: "Discover Events",
  mutual_connections: "{number} mutual connection",
  all_invitations: "All Invitations",
  see_all: "See All",
  upcoming_events: "Upcoming Events",
  past_events: "Past Events",
  free: "Free",
  paid: "Paid",
  events: "Events",
  edit_event: "Edit Event",
  recommendations: "Recommended Events",
  interested: "Interested",
  recommended: "Recommended",
  search_events: "Search Events",
  all_events: "All Events",
  viewed: "Viewed",
  free_event: "Free Event",
  paid_event: "Paid Event",
  load_more: "Load More",
  undisclose: "Undisclose",
  week_to_go: "week to go",
  days_to_go: "days to go",
  registered: "Registered",
  registrations: "Registrations",
  registration: "Registration",
  deregister: "Deregister",
  preregister: "Register",
  event_details: "Event Details",
  show_more: "Show More",
  show_less: "Show Less",
  no_event: "No Event Availiable",
  no_recommended_event: "No Recommended Event",
  type_0: "Private Event",
  type_1: "Public Event",
  ongoing: "Ongoing",
  new_event: "New Event",
  cover_image: "Cover Image",
  event_title: "Event Title",
  country: "Country",
  event_type: "Event Type",
  location: "Event Location",
  address: "Enter Address",
  start_time: "Start Time",
  end_time: "End Time",
  source: "Source",
  optional: "Optional",
  source_placeholder: "Paste URL link here",
  description: "About Event",
  desc_placeholder: "Write here...",
  payment_method: "Payment Method",
  time_placeholder: "Select date & time",
  online: "Online",
  at_venue: "At Venue",
  update: "Update",
  create: "Create Event",
  server_error: "Something went wrong!!",
  microsite_events: "Microsite Events",
  errors: {
    cover_required: "Event Cover Image is required",
    title_required: "Event Title is required",
    title_min_length: "Event title has to be min 3 characters",
    title_max_length: "Event title cannot be more than 250 characters",
    source_url: "Source should be valid URL",
    source_max_limit: "Source cannot be more than 300 characters",
    start_at_today: "You can not select today's or previous date",
    start_at_smaller_end: "start date should not be greater than end date",
    start_at_required: "You must enter Start Date",
    end_at_required: "You must enter End Date",
    description_required: "Event description is required",
    description_min_length: "Event description has to be min 3 characters",
    description_max_length:
      "Event description cannot be more than 2500 characters",
    speciality_id_required: "Please choose a speciality",
    location_required: "Event location is required",
    sourceUrlOfVirtualEvent: "Source url is required for Virtual Events.",
    registration_ends_on_required: "You must enter Registration Ends On Date",
    registration_ends_on_date_limit:
      "Registration Ends On date should be lesser than the end date",
    registration_ends_on_date_min_limit:
    "Registration Ends On date cannot be lesser than current time",
  },
  delete_event: "Delete Event",
  create_event: "Create an Event",
  cancel: "Cancel",
  pay_at_venue: "Pay at Venue",
  pay_online: "Pay Online",
  join: "Join",
  registration_ends_on: "Registration Ends On",
  virtual_event: "Virtual Event",
  view_more_details: "View more details",
  event_label: "Event"
};
