import { isEmpty } from 'lodash';
import { strings } from '../../assets/strings/all';

export const verifyUserInputData = (profileData, fieldArray, contactData) => {
  const errors = {};
  const { errors: errorString } = strings.profile;

  delete errors['email'];
  if (
    isEmpty(profileData.email) ||
    (profileData.email && profileData.email.trim() === '')
  ) {
    const error = `${`Email`} is Required`;
    errors['email'] = error;
  }

  delete errors['userTitle'];
  if (isEmpty(profileData.userTitle)) {
    const error = `${`User Title`} is Required`;
    errors['userTitle'] = error;
  }

  delete errors['avatar'];
  if (
    isEmpty(profileData.avatar) ||
    (profileData.avatar && profileData.avatar.trim() === '')
  ) {
    const error = `${`Profile Picture`} is Required`;
    errors['avatar'] = error;
  }

  delete errors['firstName'];
  if (
    isEmpty(profileData.firstName) ||
    (profileData.firstName && profileData.firstName.trim() === '')
  ) {
    const error = `${`First Name`} is Required`;
    errors['firstName'] = error;
  }

  delete errors['lastName'];
  if (
    isEmpty(profileData.lastName) ||
    (profileData.lastName && profileData.lastName.trim() === '')
  ) {
    const error = `${`Last Name`} is Required`;
    errors['lastName'] = error;
  }

  // delete errors["phoneCode"];
  // if (isEmpty(profileData.phoneCode)) {
  //   const error = `${`Phone Code`} is Required`;
  //   errors["phoneCode"] = error;
  // }

  // delete errors["phoneNumber"];
  // if (isEmpty(profileData.phoneNumber)) {
  //   const error = `${`Phone Number`} is Required`;
  //   errors["phoneNumber"] = error;
  // }

  // delete errors['licenseId'];
  // if (
  //   isEmpty(profileData.licenseId) ||
  //   (profileData.association && profileData.association.trim() === '')
  // ) {
  //   const error = `${`License Id`} is Required`;
  //   errors['licenseId'] = error;
  // }

  delete errors['speciality'];
  if (isEmpty(profileData.speciality)) {
    const error = `${`Speciality`} is Required`;
    errors['speciality'] = error;
  }

  // delete errors['profession'];
  // if (isEmpty(profileData.profession)) {
  //   const error = `${`Profession`} is Required`;
  //   errors['profession'] = error;
  // }

  // delete errors['association'];
  // if (
  //   isEmpty(profileData.association) ||
  //   (profileData.association && profileData.association.trim() === '')
  // ) {
  //   const error = `${`Association`} is Required`;
  //   errors['association'] = error;
  // }

  delete errors['phoneCode'];
  if (!contactData.phoneCode) {
    errors['phoneCode'] = errorString.no_phone_code;
  }

  delete errors['phoneNumber'];
  if (!contactData.phoneNumber) {
    errors['phoneNumber'] = errorString.no_phone_number;
  } else {
    if (isEmpty(contactData.phoneNumber)) {
      errors['phoneNumber'] = errorString.no_phone_number;
    }
    // if (contactData.phoneNumber === profileData.phoneNumber) {
    //   errors['phoneNumber'] = errorString.same_phone_number;
    // }
  }

  // delete errors['about'];
  // if (
  //   isEmpty(profileData.about) ||
  //   (profileData.about && profileData.about.trim() === '')
  // ) {
  //   const error = `${`About`} is Required`;
  //   errors['about'] = error;
  // }
  return errors;
};
