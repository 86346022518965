export const strings = {
  title: 'Conference Proceedings',
  see_all: 'See All',
  no_conference_proceeding: 'No Conference Proceeding',
  no_conference_proceeding_message: 'No Conference Proceeding Found',
  conference_title: 'Conference Proceeding Title',
  title_placeholder: 'Enter conference proceeding title',
  submit: 'Submit',
  errors: {
    title_required: 'Title is required',
    url_required: 'URL is required',
    title_min_length: 'Title has to be min 3 characters',
    title_max_length: 'Title cannot be more than 250 characters',
    brief_required: 'Conference Proceeding Brief is required',
    brief_min_length: 'Conference Proceeding Brief has to be min 3 characters',
    brief_max_length:
      'Conference Proceeding Brief cannot be more than 250 characters',
    url: 'Source should be valid URL',
    url_max_limit: 'Source cannot be more than 300 characters',
    description_required: 'Conference Proceeding description is required',
    description_min_length:
      'Conference Proceeding description has to be min 3 characters',
    description_max_length:
      'Conference Proceeding description cannot be more than 2500 characters',
    speciality_id_required: 'Please choose a speciality',
  },
  delete: 'Delete conference proceeding',
  delete_confirmation_heading:
    'Are you sure you want to delete the this proceeding ?',
  delete_confirmation_body:
    "Once deleted you won't able to access this conference proceeding.",
  cancel: 'Cancel',
  search_proceeding: 'Search Conference Proceedings',
};
