import React from 'react';
import { Switch } from 'react-router-dom';
import ProfileWrapper from '../Profile/ProfileWrapper';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import Footer from '../Layout/Footer';

export default function SettingRoutes(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <AuthenticatedRoute
          exact
          path={`${match.path}/`}
          component={(props) => (
            <ProfileWrapper
              view="profile"
              viewType="profile"
              active="profile"
              {...props}
            />
          )}
        />

        <AuthenticatedRoute
          exact
          path={`${match.path}/terms`}
          component={(props) => (
            <ProfileWrapper
              view="terms"
              viewType="terms"
              active="terms"
              {...props}
            />
          )}
        />

        <AuthenticatedRoute
          exact
          path={`${match.path}/policy`}
          component={(props) => (
            <ProfileWrapper
              view="policy"
              viewType="policy"
              active="policy"
              {...props}
            />
          )}
        />
        <AuthenticatedRoute
          exact
          path={`${match.path}/case-template-manager`}
          component={(props) => (
            <ProfileWrapper
              view="caseTemplateManger"
              viewType="caseTemplateManger"
              active="profile"
              {...props}
            />
          )}
        />
        <AuthenticatedRoute
          exact
          path={`${match.path}/change-password`}
          component={(props) => (
            <ProfileWrapper
              view="changePassword"
              viewType="changePassword"
              active="changePassword"
              {...props}
            />
          )}
        />
        <AuthenticatedRoute
          exact
          path={`${match.path}/notifications`}
          component={(props) => (
            <ProfileWrapper
              view="notificationSettings"
              viewType="notificationSettings"
              active="notificationSettings"
              {...props}
            />
          )}
        />
      </Switch>
      {/* <Footer /> */}
    </>
  );
}
