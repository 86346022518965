import { difference, keyBy } from 'lodash';
import React from 'react';
import { useContext } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsCaseSection from '../../Common/HsCaseSection';
import HsHtmlRenderer from '../../Common/HsHtmlRenderer';

function CaseFieldWrapper(props) {
  const { data } = props;
  const { extraDetails } = data;

  const { organization } = useContext(OrganizationContext);
  const { caseFields = [], sharingFields = [] } = organization;
  const extraFields = caseFields.filter((cf) => cf.type === 'extra-details');

  const caseFieldSlugs = extraFields.map((cf) => cf.slug);
  const eOrgFieldSlugs = extraDetails.map((ed) => ed.name);
  const removeFields = difference(caseFieldSlugs, eOrgFieldSlugs);

  const eSharingFields = sharingFields.map((sf) => sf.slug);

  const eOrgFieldWithkey = keyBy(extraDetails, 'name');
  return (
    <>
      {caseFieldSlugs.map((cfs, index) => {
        const field = eOrgFieldWithkey[cfs];

        if (!field) {
          return <></>;
        }
        return (
          <HsCaseSection
            key={index}
            title={
              field.organizationCaseField
                ? field.organizationCaseField.name
                : ''
            }
          >
            <HsHtmlRenderer content={field.value} />
          </HsCaseSection>
        );
      })}
      {removeFields.map((cfs, index) => {
        const field = eOrgFieldWithkey[cfs];
        if (!field) {
          return <></>;
        }
        return (
          <HsCaseSection
            key={index}
            title={
              field.organizationCaseField
                ? field.organizationCaseField.name
                : ''
            }
          >
            <HsHtmlRenderer content={field.value} />
          </HsCaseSection>
        );
      })}
      {/* {eSharingFields.map((cfs, index) => {
        const field = eOrgFieldWithkey[cfs];
        if (!field) {
          return <></>;
        }
        return (
          <HsCaseSection
            key={index}
            title={
              field.organizationCaseField
                ? field.organizationCaseField.name
                : ''
            }
          >
            <HsHtmlRenderer content={field.value} />
          </HsCaseSection>
        );
      })} */}
    </>
  );
}

export default CaseFieldWrapper;
