import HsMediaWrapper from '../Gallery/HsMediaWrapper';

function HsGallery(props) {
  const { attachments } = props;

  // const renderPdf = (item) => {
  //   return (
  //     <div>
  //       <iframe
  //         title={item.original}
  //         class="image-gallery-image"
  //         src={item.original}
  //       />
  //     </div>
  //   );
  // };

  const media = attachments.map((a) => {
    return {
      ...a,
      type: 'image',
    };
  });

  return <HsMediaWrapper media={media} />;

  // return attachments.map((attachment) => {
  //   return (
  //     <Row className="attachment-row">
  //       {images.map((i) => (
  //         <Col md={3}>
  //           <div className="attachment-col-wrapper squareWrapper">
  //             <div className="squareContent">
  //               <Image fluid={true} src={i.thumbnail} />
  //             </div>
  //           </div>
  //         </Col>
  //       ))}
  //     </Row>
  //   );
  // });

  // if (attachments.length === 0) {
  //   return <></>;
  // }
  // return (
  //   <ImageGallery
  //     items={images}
  //     thumbnailPosition={'right'}
  //     useBrowserFullscreen={false}
  //   />
  // );
}
export default HsGallery;
