import React from "react";

const SetPassword = (props) => {
  return (
    <div>
      <div>Hello This is the Set Password Page</div>
    </div>
  );
};

export default SetPassword;
