import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { getLongTime } from '../../common/functions';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import {
  DOWNLOAD_CASE_ANALYTICS,
  DOWNLOAD_EXPERT_ANALYTICS,
  GET_ADMIN_ANALYTICS,
} from './query';
import CustomDateTime from '../Common/CustomDateTime';
import { strings } from '../../assets/strings/all';
import HsTooltip from '../Common/HsTooltip';
import ChartAnalytics from './ChartAnalytics';
import MostActiveBarChart from '../Common/MostActiveBarChart';
import TransitionBar from '../Common/TransitionBar';
const StatisticCard = (props) => {
  const { type, title, data } = props;
  return (
    <Col md={4}>
      <Card>
        <Card.Body className="analytics-inner-card-body">
          <Row as="div">
            <Col className="analytics-card-hsimage-size-padding ">
              <Row>
                <Card.Text as="div" className="analytics-inner-card-header">
                  {title}
                </Card.Text>

                <div style={{ flex: 1 }} />
                <HsTooltip type={type} />
              </Row>
            </Col>
          </Row>
          <Row>
            <Card.Text className="analytics-inner-card-members">
              {data}
            </Card.Text>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
const AdminAnalytics = (props) => {
  const { setFeedback } = useContext(FeedbackContext);
  const [variables, setVariables] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [dateState, setDateState] = useState({
    startDate: null,
    endDate: null,
  });

  const [downloadCaseAnalytics, { loading: caseLoading }] = useMutation(
    DOWNLOAD_CASE_ANALYTICS,
    {
      onCompleted: (data) => {
        try {
          const { getCaseAnalyticsDownload } = data;
          if (getCaseAnalyticsDownload) {
            const { url } = getCaseAnalyticsDownload;
            window.open(url, '_blank').focus();
          }
        } catch (error) {
          setDownloadUrl(data?.getCaseAnalyticsDownload?.url);
          setShowModal(true);
        }
      },
    },
  );

  const [downloadExpertAnalytics, { loading: expertLoading }] = useMutation(
    DOWNLOAD_EXPERT_ANALYTICS,
    {
      onCompleted: (data) => {
        try {
          const { getExpertAnalyticsDownload } = data;
          if (getExpertAnalyticsDownload) {
            const { url } = getExpertAnalyticsDownload;
            window.open(url, '_blank').focus();
          }
        } catch (error) {
          setDownloadUrl(data?.getExpertAnalyticsDownload?.url);
          setShowModal(true);
        }
      },
    },
  );

  const { loading, error, data } = useQuery(GET_ADMIN_ANALYTICS, {
    variables,
    fetchPolicy: 'network-only',
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
  });

  if (loading || error || isEmpty(data)) {
    return 'Loading';
  }
  const { users: userString, analytics } = strings;

  const { getAdminAnalytics: dataObj } = data;

  const onDateChange = (date, type) => {
    const customDate = new Date(date);

    setDateState({
      ...dateState,
      [type]: customDate,
    });
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {dataObj ? (
        <div className="analytics-overview-container">
          <div className="top-container">
            <div className="custom-date-box">
              <div className="custom-button-column">
                <span className="custom-button-label">{userString.from}</span>
                <div className="custom-time-column">
                  <CustomDateTime
                    data={
                      !dateState.startDate
                        ? null
                        : new Date(dateState.startDate)
                    }
                    onChange={(e) => {
                      onDateChange(e, 'startDate');
                    }}
                    value={dateState ? dateState['startDate'] : null}
                    name={'startDate'}
                    maxDate={dateState.endDate ? dateState.endDate : new Date()}
                    placeholder={'Select Date'}
                    required={true}
                    dateFormat="dd-MMM-yyyy"
                  />
                </div>
              </div>

              <div className="custom-button-column">
                <span className="custom-button-label">{userString.to}</span>

                <div className="custom-time-column">
                  <CustomDateTime
                    data={
                      !dateState.endDate ? null : new Date(dateState.endDate)
                    }
                    onChange={(e) => {
                      onDateChange(e, 'endDate');
                    }}
                    value={dateState ? dateState['endDate'] : null}
                    name={'endDate'}
                    minDate={dateState.startDate ? dateState.startDate : null}
                    maxDate={new Date()}
                    placeholder={'Select Date'}
                    required={true}
                    dateFormat="dd-MMM-yyyy"
                  />
                </div>
              </div>

              <Button
                variant="primary"
                disabled={!(dateState.startDate && dateState.endDate)}
                onClick={() => {
                  setVariables({
                    startDate: dateState.startDate,
                    endDate: dateState.endDate,
                  });
                }}
              >
                {userString.select}
              </Button>
            </div>

            <div style={{ justifySelf: 'flex-end' }}>
              <Button
                className="export-button"
                variant="primary"
                disabled={
                  (dateState.startDate === null && dateState.endDate) ||
                  (dateState.startDate && dateState.endDate === null) ||
                  caseLoading
                }
                onClick={() => {
                  let variables = {};
                  if (dateState.startDate && dateState.endDate) {
                    variables = {
                      ...dateState,
                    };
                  }

                  downloadCaseAnalytics({
                    variables,
                  });
                }}
              >
                {caseLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {analytics.export_case}
                  </>
                ) : (
                  analytics.export_case
                )}
              </Button>

              <Button
                className="export-button"
                variant="primary"
                disabled={
                  (dateState.startDate === null && dateState.endDate) ||
                  (dateState.startDate && dateState.endDate === null) ||
                  expertLoading
                }
                onClick={() => {
                  let variables = {};
                  if (dateState.startDate && dateState.endDate) {
                    variables = {
                      ...dateState,
                    };
                  }

                  downloadExpertAnalytics({
                    variables,
                  });
                }}
              >
                {expertLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {analytics.export_panel}
                  </>
                ) : (
                  analytics.export_panel
                )}
              </Button>
            </div>
          </div>

          <div style={{ width: '100%' }} className="my-2">
            <ChartAnalytics
              commentAna={dataObj.commentAna}
              caseAna={dataObj.caseAna}
              userCaseViewAna={dataObj.userCaseViewAna}
            />
          </div>
          {dataObj.transitionData && (
            <div style={{ width: '1250px', height: '950px' }} className="my-2">
              <TransitionBar data={JSON.parse(dataObj.transitionData)} />
            </div>
          )}
          <div style={{ width: '900px', height: '900px' }} className="my-2">
            <MostActiveBarChart mostActiveCases={dataObj.mostActiveCases} />
          </div>
          <Row>
            {/* TOTAL HCP REGISTERED */}
            <Col md={4} className="analytics-card-list-padding">
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          {analytics.total_hcp}
                        </Card.Text>
                        <div style={{ flex: 1 }} />
                        <HsTooltip type={'total_hcp'} />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {strings.formatString(analytics.num_total_hcp, {
                        num: dataObj.totalHcp,
                      })}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            {/* ACTIVE HCP LIST */}
            <Col md={4}>
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          {analytics.active_hcp}
                        </Card.Text>
                        <div style={{ flex: 1 }} />
                        <HsTooltip type={'active_hcp'} />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {strings.formatString(analytics.num_active_hcp, {
                        num: dataObj.activeHcp,
                      })}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            {/* TOTAL CASES */}
            <Col md={4}>
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          New Cases
                        </Card.Text>

                        <div style={{ flex: 1 }} />
                        <HsTooltip type={'new_case'} />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {dataObj.newCases}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          Pending Cases
                        </Card.Text>

                        <div style={{ flex: 1 }} />
                        <HsTooltip type={'pending'} />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {dataObj.pendingCases}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          Returned Cases
                        </Card.Text>

                        <div style={{ flex: 1 }} />
                        <HsTooltip type={'returned'} />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {dataObj.returnedCases}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            {/* OPEN CASES LIST */}
            <Col md={4}>
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          Open Cases
                        </Card.Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {dataObj.openCases}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            {/* CLOSED CASES LIST */}
            <Col md={4}>
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          Resolved Cases
                        </Card.Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {dataObj.resolvedCases}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            {[
              {
                title: 'New Questions',
                type: 'new_case',
                data: dataObj.newQuestions,
              },
              {
                title: 'Pending Questions',
                type: 'pending',
                data: dataObj.pendingQuestions,
              },
              {
                title: 'Returned Questions',
                type: 'returned',
                data: dataObj.returnedQuestions,
              },
              {
                title: 'Open Questions',
                type: 'open',
                data: dataObj.openQuestions,
              },
              {
                title: 'Resolved Questions',
                type: 'closed',
                data: dataObj.closedQuestions,
              },
            ].map((e) => (
              <StatisticCard type={e.type} title={e.title} data={e.data} />
            ))}
            {/* AVG TIME TO CLOSE CASES */}
            <Col md={4}>
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          Average Time To Close Case
                        </Card.Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {getLongTime(dataObj.avgTimeToClose)}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            {/* AVG RATINGS */}
            <Col md={4}>
              <Card>
                <Card.Body className="analytics-inner-card-body">
                  <Row as="div">
                    <Col className="analytics-card-hsimage-size-padding ">
                      <Row>
                        <Card.Text
                          as="div"
                          className="analytics-inner-card-header"
                        >
                          {analytics.avg_rating}
                        </Card.Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Card.Text className="analytics-inner-card-members">
                      {strings.formatString(analytics.num_avg_rating, {
                        num: dataObj.avgRating,
                      })}
                    </Card.Text>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : null}

      {showModal && (
        <Modal
          show={showModal}
          onHide={onClose}
          size="md"
          className="delete-draft-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h6>{analytics.popup_blocker}</h6>
            <p className="modal-description muted-text">
              {analytics.popup_blocker_description}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <a
              href={downloadUrl}
              target="_blank"
              rel="noreferrer"
              className="back-btn bo-br"
              onClick={() => {
                setDownloadUrl('');
                setShowModal(false);
              }}
            >
              Download
            </a>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AdminAnalytics;
