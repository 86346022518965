import React from 'react';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { emailIsValid } from '../../common/functions';
import HsAsyncSelect from '../Form/HsAsyncSelect';
import { GET_USERS } from './query';

function SelectUser(props) {
  const { canAddNewUser, country } = props;

  // console.log({ canAddNewUser });

  const getResultData = (data) => {
    console.log('fetched result data: ', data);
    return data.userList.users;
  };

  const formattedLabel = (option) => {
    const { avatar, fullName, email, speciality, about, username } = option;
    return (
      <Row className="row-small">
        {avatar ? (
          <Col
            onClick={() => {
              const url =
                'http://' + document.location.hostname + `/users/${username}`;
              window.open(url);
            }}
            style={{
              cursor: 'pointer',
            }}
            xs="auto"
            className="user-image"
          >
            <Image src={avatar} />
          </Col>
        ) : (
          <></>
        )}
        <Col>
          <div> {fullName || email}</div>
          <div className="muted-text muted-text-small">
            {speciality ? speciality.name : ''}
          </div>
          <div className="muted-text muted-text-small oneLineDes">
            {about ? about : ''}
          </div>
        </Col>
      </Row>
    );
  };

  const addOptions = {};

  if (canAddNewUser) {
    addOptions.isValidNewOption = (val) => {
      return emailIsValid(val);
    };
  }

  return (
    <HsAsyncSelect
      query={GET_USERS}
      getResultData={getResultData}
      getOptionLabel={(option) => formattedLabel(option)}
      getOptionValue={(option) => option.email}
      placeholder="Start Typing for suggestion"
      {...addOptions}
      {...props}
    />
  );
}

export default SelectUser;
