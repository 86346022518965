import React from 'react';
import { Container } from 'react-bootstrap';
import { strings } from '../../assets/strings/all';
import { setTitle } from '../../common/functions';
import HsAlert from '../../components/Common/HsAlert';
import AuthMaster from '../Layout/AuthMaster';
import Header from '../Layout/Header';
import SignIn from '../../components/Auth/SignIn';
import OneTimeSignIn from '../../components/Auth/OneTimeSignIn';
import ResetPassword from '../../components/Auth/ResetPassword';
import SetPassword from '../../components/Auth/SetPassword';
import ForgotPassword from '../../components/Auth/ForgotPassword';
import SignUp from '../../components/Auth/SignUp';
import TermsAndCondition from '../Layout/TermsAndCondition';
import PrivacyPolicy from '../Layout/PrivacyPolicy';
import Footer from '../Layout/Footer';
import AccountDeletion from '../Layout/accountDeletion';

function HomeNoAuth(props) {
  const { auth: authString } = strings;

  setTitle(authString.signin_page_title);

  const RenderChildView = (props) => {
    let childView = null;

    childView = {
      signin: <SignIn {...props} />,
      forgotPassword: <ForgotPassword {...props} />,
      oneTimeSignIn: <OneTimeSignIn {...props} />,
      resetPassword: <ResetPassword {...props} />,
      setPassword: <SetPassword {...props} />,
      signUp: <SignUp {...props} />,
      tnc: <TermsAndCondition {...props} />,
      privacy: <PrivacyPolicy {...props} />,
      'account-deletion': <AccountDeletion {...props} />,
    }[props.view];

    if (!childView) {
      childView = null;
    }

    return childView;
  };

  return (
    <>
      <AuthMaster {...props}>
        {(context) => {
          return (
            <>
              <Header {...props} />

              <div className="sdb-body" id="sdb-body">
                <HsAlert />
                <Container>
                  <RenderChildView {...props} />
                </Container>
              </div>
            </>
          );
        }}
      </AuthMaster>
      <Footer />
    </>
  );
}

export default HomeNoAuth;
