export const strings = {
  filters: 'Filters',
  verify_user: 'Verify License',
  all_microsite: 'All Microsite',
  license: 'License',
  prev: 'Prev',
  hide: 'Hide',
  advance_search: 'Advance Search',
  next: 'Next',
  license_no: 'Number',
  verify: 'Verify',
  unverify: 'Unverify',
  name: 'Name',
  email: 'Email',
  joining_date: 'Register On',
  email_activated: 'Email Activated',
  license_verified: 'License Verified',
  start_date: 'Start Date',
  end_date: 'End Date',
  name_email: 'Name or Email',
  user_verified: 'User Verified',
  reset_password: 'Reset Password',
  license_name: 'Name',
  user_details: 'User Details',
  license_issue_org: 'Issue Organization',
  license_issue_date: 'Issue Date',
  license_end_date: 'Expiry Date',
  license_id: 'License ID',
  files: 'License Files',
  no_files: 'No License Files',
  home: 'Home',
  users: 'Users',
  search: 'Search',
  download: 'Download',
  send_report: 'Send Report',
  overview: 'Overview',
  admin_dashboard: 'Admin Dashboard',
  send_email_reminders: 'Send Email Reminders',
  thirty_days: 'Last 30 days',
  sixty_days: 'Last 60 days',
  ninety_days: 'Last 90 days',
  user_onboarding: 'User Onboarding',
  reports: 'Reports',
  eConsultation: 'eConsultation',
  all_users: 'All Users',
  active_users: 'Active Users',
  inactive_users: 'Inactive Users',
  country_placeholder: 'All Countries',
  speciality_placeholder: 'All Specialties',
  eq_placeholder: 'Highest EQ',
  all_activities: 'All Activities',
  feeds: 'Feeds',
  articles: 'Articles',
  cases: 'Cases',
  registered_users: 'Registered Users',
  custom: 'Custom',
  from: 'From',
  to: 'To',
  submit: 'Submit',
  cancel: 'Cancel',
  email_analytics: 'Email Analytics',
  onboarding_analytics: 'Onboarding Analytics',
  engagement_analytics: 'Engagement Analytics',
  email_reminder: 'Sending Email Reminder...',
  email_reminder_desc:
    'A reminder email for selected activity type will be sent to below HCP users',
  activity_type: 'Email Activity Type',
  onboarding: 'Onboarding',
  engagement: 'Engagement',
  select_template: 'Select Template',
  select_date: 'Select Date',
  select: 'Select',
  write_here: 'Write Here',
  comments: 'Comments (Optional)',
  search_users: 'Search HCP Users',
  show_all_users: 'Show All New Users',
  email_not_verified: 'Email Not Verified Only',
  license_not_verified: 'License Not Verified Only',
  active_email: 'Email Activated',
  name_or_email: 'Name or Email',
  date_placeholder: 'Enter date',
  load_more: 'Load More',
  view_user: 'View User Profile',
  engagement_mail: 'Send Engagement Email',
  manage_settings: 'Manage Settings',
  deactivate_account: 'Deactivate Account',
  account_delete_confirmation:
    'Are you sure you want to deactivate this account?',
  delete_suggestion:
    'Deactivating this profile will disable the account and reacivated when user login again',
  make_email_verified: 'Make Email As Verified',
  email_verified_badge: 'Email Not Verified',
  licence_verified_badge: 'License Not Verified',
  activate_account: 'Activate Account',
  edit_profile: 'Edit Profile',
  view_profile: 'View User Profile',
  content_engagements: 'Content Engagements',
  engagement_summary: 'User Engagement Summary',
  post_engagement_overview: 'Posts Engagement Overview',
  likes: 'Likes',
  shares: 'Shares',
  connections: 'Connections',
  groups: 'Groups',
  microsites: 'Microsites',
  profile_completion: 'Profile Completion',
  top_five_users: 'Top 5 Most Active Users',
  engagement_eq: 'Engagement Quotient(EQ)',
  no_profile_views: 'No. of Profile Views',
  no_times_login: 'No. of Times Logged In',
  avg_time_spent: 'Avg. Time Spent',
  no_feed_post: 'No. of Feeds Posted',
  no_survey_participated: 'No. of Surveys Participated',
  no_webinar_attended: 'No. of Webinars Attended',
  feeds_engagement: 'Feeds Engagement',
  average_daily_active_users: 'Avg. no. of daily active users',
  total_active_users_daily: 'Total No. of Daily Active Users',
  search_name_or_email: 'Search by email or name',
  email_batches: 'Email Batches',
  email_batch_status: 'Email Batch Status',
  all: 'All',
  posted: 'Posted',
  liked: 'Liked',
  commented: 'Commented',
  shared: 'Shared',
  expert_panel: 'Experts Panel',
  last_login: 'Last Login',
  export_case: 'Download Case Analytics',
  export_expert: 'Download Panel Analytics',
  license_status: 'License Status',
  unverified_status:
    'Your account is being authenticated, you will be able to add new cases momentarily',
};
