import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import CaseTemplateEditModal from './CaseTemplateEditModal';

function CaseTemplateEditButton(props) {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Button
        variant="link"
        onClick={() => {
          toggleShow();
        }}
      >
        Edit
      </Button>
      {show ? (
        <CaseTemplateEditModal
          show={show}
          {...props}
          onClose={() => {
            toggleShow();
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default CaseTemplateEditButton;
