import React, { useState } from 'react';

export default function HsCheckboxToggle(props) {
  const [selected, setSelected] = useState(props.checked);

  const updateSelection = (newValue) => {
    setSelected(newValue);
    props.onToggle(newValue);
  };

  return (
    <div
      className={`toggle-wrapper ${selected ? 'selected' : 'unselected'}`}
      for="c3"
      onClick={() => updateSelection(!selected)}
    >
      <div
        className={`toggle-switch ${selected ? 'selected' : 'unselected'}`}
      ></div>
      <div className={`toggle-inner ${selected ? 'selected' : 'unselected'}`}>
        <div
          className={`helper-text-wrapper ${
            selected ? 'selected' : 'unselected'
          }`}
        >
          <span
            className={`helper-text ${selected ? 'selected' : 'unselected'}`}
          >
            {selected ? 'Enabled' : 'Disabled'}
          </span>
        </div>
      </div>
    </div>
  );
}
