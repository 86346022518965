import { useContext } from 'react';
import HsCaseSection from '../../Common/HsCaseSection';
import HsHtmlRenderer from '../../Common/HsHtmlRenderer';
import { keyBy } from 'lodash';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';

const SharedWrapper = (props) => {
  const { data } = props;
  const { extraDetails } = data;
  const { organization } = useContext(OrganizationContext);
  const { sharingFields = [] } = organization;
  const eSharingFields = sharingFields.map((sf) => sf.slug);
  const eOrgFieldWithkey = keyBy(extraDetails, 'name');
  return (
    <>
      {eSharingFields.map((cfs, index) => {
        const field = eOrgFieldWithkey[cfs];
        if (!field) {
          return <></>;
        }
        return (
          <HsCaseSection
            key={index}
            title={
              field.organizationCaseField
                ? field.organizationCaseField.name
                : ''
            }
          >
            <HsHtmlRenderer content={field.value} />
          </HsCaseSection>
        );
      })}
    </>
  );
};
export default SharedWrapper;
