const permissionList = {
  VIEW_SELF_PROFILE: 'view-self-profile',
  VIEW_OTHER_PROFILE: 'view-other-profile',
  UPDATE_SELF_PROFILE: 'update-self-profile',
  UPDATE_OTHERS_PROFILE: 'update-others-profile',
  VIEW_PUBLIC_CASES: 'view-public-cases',
  VIEW_MY_DRAFT_CASES: 'view-my-draft-cases',
  CREATE_CASES: 'create-cases',
  EDIT_MY_CASES_BASIC_DETAILS: 'edit-my-cases-basic-details',
  EDIT_MY_CASES_PATIENT_INFO: 'edit-my-cases-patient-info',
  EDIT_MY_CASES_OTHER_DETAILS: 'edit-my-cases-other-details',
  ASSIGN_OWNERSHIP: 'assign-ownership',
  MY_CASE_PUBLISH_REQUEST: 'my-case-publish-request',
  PUBLISHED_CASE_REVIEW: 'published-case-review',
  INVITE_CASE_CO_OWNER: 'invite-case-co-owner',
  COMMENT_ON_CASE: 'comment-on-case',
  EDIT_SELF_COMMENTS: 'edit-self-comments',
  DELETE_SELF_COMMENTS: 'delete-self-comments',
  DELETE_OTHERS_COMMENTS: 'delete-others-comments',
  REVIEW_COMMENTS: 'review-comments',
  CREATE_MEETING: 'create-meeting',
  EDIT_MY_MEETING: 'edit-my-meeting',
  VIEW_MEETING: 'view-meeting',
  CANCEL_MY_MEETING: 'cancel-my-meeting',
  CLOSE_MY_CASE: 'close-my-case',
  RE_OPEN_MY_CASE: 're-open-my-case',
  ARCHIEVE_MY_CASE: 'archieve-my-case',
  UNARCHIEVE_MY_CASE: 'unarchieve-my-case',
  VIEW_MY_JOURNALS: 'view-my-journals',
  VIEW_OTHERS_JOURNALS_IN_ORGANIZATION: 'view-others-journals-in-organization',
  VIEW_MY_DRAFTS_JOURNALS: 'view-my-drafts-journals',
  CREATE_JOURNALS: 'create-journals',
  EDIT_MY_JOURNALS: 'edit-my-journals',
  SAVE_AS_MY_DRAFT: 'save-as-my-draft',
  REVIEW_JOURNALS: 'review-journals',
  PUBLISH_JOURNAL_REQUEST: 'publish-journal-request',
  ARCHIVE_MY_JOURNALS: 'archive-my-journals',
  VIEW_USERS: 'view-users',
  ADD_USERS: 'add-users',
  EDIT_USERS: 'edit-users',
  DELETE_USERS: 'delete-users',
  CREATE_ROLE: 'create-role',
  ASSIGN_USER_ROLES: 'assign-user-roles',
  ASSIGN_PERMISSIONS_TO_ROLE: 'assign-permissions-to-role',
  VIEW_JOURNALS: 'view-journals',
  ATTACH_JOURNAL_TO_CASE: 'attach-journal-to-case',
  VIEW_COMMENTS: 'view-comments',
  INVITE_CASE_COMMENTOR: 'invite-case-commentor',
  UNARCHIEVE_MY_JOURNALS: 'unarchive-my-journals',
  UPDATE_CASE_TEMPLATE: 'update-case-template',
  ADD_NEW_USER: 'add-new-user',
  CALL: 'call',
  RATE_CASE: 'rate-case',
  GET_ANALYTICS: 'get-analytics',
};

export default permissionList;
