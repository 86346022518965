import { parseISO, format } from 'date-fns';
import React from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HsImage from '../Common/HsImage';
import { strings } from '../../assets/strings/all';

const AnalyticsUserListItem = (props) => {
  const {
    fullName,
    avatar,
    isLicenseVerified,
    speciality,
    createdAt,
    username,
    association,
    isExpert,
  } = props;

  const { users: userString, post } = strings;

  return (
    <>
      <div className="user-profile-box-analytics">
        <div className="user-profile-container">
          <HsImage
            className="avatarImage mr-3"
            alt="user peofile image"
            src={avatar}
          />

          <div className="user-profile-content">
            <div className="user-content">
              <Link
                className="active-users-full-name"
                to={`/analytics/user/${username}`}
              >
                {fullName ? `${fullName}` : ''}
              </Link>
              {isExpert && (
                <Badge className="analytics-expert-badge">{post.expert} </Badge>
              )}
              {!isLicenseVerified ? (
                <Badge variant="warning" className="licence-not-varified-badge">
                  {userString.licence_verified_badge}
                </Badge>
              ) : (
                ''
              )}
              <h5>{speciality && speciality.name}</h5>
              <h5>{association}</h5>
            </div>

            <div className="user-onboarding-profile-rating">
              <p>{`Registered On ${
                createdAt ? format(parseISO(createdAt), 'dd-MM-yyyy') : ''
                // createdAt ? createdAt : ''
              }`}</p>
            </div>
          </div>
        </div>
        {
          <Modal
            show={false}
            // onHide={handleDeleteClose}
            // size="md"
            className="delete-video-modal"
            centered={true}
          >
            <Modal.Header className="delete-video-header">
              Account Delete Confirmation
            </Modal.Header>
            <Modal.Title className="delete-video-content">
              Account Delete Suggestion
            </Modal.Title>
            <Modal.Footer>
              <Button
                variant="light"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="delete-cancel-button"
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </div>
    </>
  );
};

export default AnalyticsUserListItem;
