import React from 'react';
import HsSelect from '../../Form/HsSelect';

function HsSort(props) {
  const sortOptions = [
    { slug: 'recent', name: 'Most Recent' },
    // { slug: 'followed', name: 'Most Followed' },
    { slug: 'commented', name: 'Most Comments' },
  ];

  return (
    <>
      <HsSelect
        label="Sort By"
        placeholder="Sort By"
        options={[{ label: 'Sort By', options: sortOptions }]}
        // defaultIcon="icon-ico-hide"
        defaultValue={sortOptions[0]}
        onChange={props.onChange}
        isClearable={false}
        // value={caseData.visibilityStatus}
      />
    </>
  );
}

export default HsSort;
