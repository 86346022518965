import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { RoleContext } from '../../containers/Context/RoleContext';
import RoleProvider from '../../containers/Context/RoleProvider';
import { SCREEN_CASE } from '../Comment/query';
import { CaseReturnsModal } from './components/CaseReturnsModal';
import CaseReportModal from './components/CaseReportModal';
const ScreenerActionCard = (props) => {
  const { role } = useContext(RoleContext);
  const [open, setOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [screenCase] = useMutation(SCREEN_CASE);
  const { data } = props;

  return (
    <>
      {role !== null && role.name === 'Screener' && (
        <>
          <Row
            style={{ gap: '8px', flexFlow: 'row-reverse' }}
            className="my-4 px-3"
          >
            {data.caseStatus.slug !== 'returned' && (
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                variant="outline-danger"
              >
                Return
              </Button>
            )}
            {(data.caseStatus.slug === 'screening' ||
              data.caseStatus.slug === 'returned') && (
              <>
                <Button
                  variant="outline-primary"
                  onClick={async () => {
                    const resp = await screenCase({
                      variables: {
                        caseId: data.id,
                        shouldReturn: false,
                        reasonId: -1,
                      },
                    });
                    console.log(resp);
                    if (resp.data.screenCase.success) {
                      window.location.reload(false);
                    }
                  }}
                >
                  Approve
                </Button>
                {/* {!data.isReported && (
                  <Button
                    variant="danger"
                    onClick={(v) => {
                      setReportOpen(true);
                    }}
                  >
                    Report
                  </Button>
                )} */}
              </>
            )}
          </Row>

          {
            // (data.caseStatus.slug === 'screening' ||
            //   data.caseStatus.slug === 'returned') && (
            // !data.isReported && (
            <Row
              style={{ gap: '8px', flexFlow: 'row-reverse' }}
              className="my-4 px-3"
            >
              <Button
                variant="danger"
                onClick={(v) => {
                  setReportOpen(true);
                }}
                disabled={data.isReported}
              >
                {/* Report */}
                {data.isReported ? 'Reported' : 'Report'}
              </Button>
            </Row>
            // )
            // )
          }

          <CaseReturnsModal
            id={data.id}
            open={open}
            onFinish={() => {
              setOpen(false);
            }}
          />

          <CaseReportModal
            open={reportOpen}
            id={data.id}
            onFinish={(v) => {
              // if (v === true) {
              //   setActive(true);
              // }
              setReportOpen(false);
            }}
          />

          <div className="my-4">
            <span>This case is in the </span>
            <strong
              style={{
                color: 'slategray',
              }}
            >
              {data.caseStatus.name}
            </strong>{' '}
            <span>stage</span>
          </div>
        </>
      )}
    </>
  );
};
export default ScreenerActionCard;
