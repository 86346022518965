import React from 'react';
import { components } from 'react-select';
const Placeholder = (props) => {
  const { children, selectProps } = props;

  return (
    <components.Placeholder {...props}>
      {selectProps.defaultIcon ? (
        <i className={selectProps.defaultIcon} />
      ) : (
        <></>
      )}
      {children}
    </components.Placeholder>
  );
};

export default Placeholder;
