import React, { useContext, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import { userHasPermission } from '../../common/functions';
import permissionList from '../../common/permissions';
import { UserContext } from '../../containers/Context/UserContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function ProfileLeftSide(props) {
  const { user } = useContext(UserContext);
  const { profile: profileString } = strings;
  const location = useLocation();
  const {
    my_account,
    profile,
    settings,
    password,
    case_template_setup,
    notifications,
  } = profileString;

  const { UPDATE_CASE_TEMPLATE } = permissionList;

  const canUpdateCaseTemplate = userHasPermission(user, UPDATE_CASE_TEMPLATE);
  const checkLoc = (string) => {
    return location.pathname.includes(string);
  };
  return (
    <Card className="hs-side-nav-wrapper" id="hs-nav-bar">
      <Card.Body className="p-0">
        <Nav
          variant="pills"
          className="hs-side-nav hs-side-nav-bottom"
          defaultActiveKey={'2'}
        >
          <Nav.Item style={{ cursor: 'default' }}>
            <div className="nav-title-category">{my_account}</div>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to={`/profile`} eventKey="profile">
              <span
                style={
                  checkLoc('profile')
                    ? { color: 'black', fontWeight: 'bold' }
                    : {}
                }
                className="linker-trans"
              >
                {profile}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to={`/settings/terms`} eventKey="profile">
              <span
                style={
                  checkLoc('terms')
                    ? { color: 'black', fontWeight: 'bold' }
                    : {}
                }
                className="linker-trans"
              >
                {'Terms and conditions'}
              </span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to={`/settings/policy`} eventKey="profile">
              <span
                style={
                  checkLoc('policy')
                    ? { color: 'black', fontWeight: 'bold' }
                    : {}
                }
                className="linker-trans"
              >
                {'Privacy policy'}
              </span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav
          variant="pills"
          className="hs-side-nav hs-side-nav-bottom"
          defaultActiveKey={'2'}
        >
          <Nav.Item style={{ cursor: 'default' }}>
            <div className="nav-title-category">{settings}</div>
          </Nav.Item>
          {canUpdateCaseTemplate ? (
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={`/settings/case-template-manager`}
                eventKey="caseTemplate"
              >
                <span
                  style={
                    checkLoc('template')
                      ? { color: 'black', fontWeight: 'bold' }
                      : {}
                  }
                  className="linker-trans"
                >
                  {case_template_setup}
                </span>
              </Nav.Link>
            </Nav.Item>
          ) : (
            <></>
          )}
          <Nav.Item>
            <Nav.Link
              as={Link}
              to={`/settings/change-password`}
              eventKey="changePassword"
            >
              <span
                style={
                  checkLoc('change-password')
                    ? { color: 'black', fontWeight: 'bold' }
                    : {}
                }
                className="linker-trans"
              >
                {password}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to={`/settings/notifications`}
              eventKey="changePassword"
            >
              <span
                style={
                  checkLoc('notifications')
                    ? { color: 'black', fontWeight: 'bold' }
                    : {}
                }
                className="linker-trans"
              >
                {notifications}
              </span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Body>
    </Card>
  );
}

export default ProfileLeftSide;
